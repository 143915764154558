import Archiwum from "./Archiwum";
import ONas from "./ONas";
import FAQ from "./FAQ";
import Ranking from "./Ranking";
import Prywatnosc from "./Prywatnosc";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import DMCA from "./DMCA";
import Kontakt from "./Kontakt";
import Zaloguj from "./Zaloguj";
import Rejestracja from "./Rejestracja";
import Dodaj from "./Dodaj";
import Home from "./Home";
import Result from "./Result";
import Identify from "./Identify";
import Error404 from "./Error404";
import Search from "./Search";
import Profil from "./Profil";
import Logout from "./Logout";
import Article from "./Article";
import ArticleEdit from "./ArticleEdit";
import Panel from "./Panel";
import Blog from "./Blog";

export default function PageContent() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/archiwum/:page" element={<Archiwum />} />
            <Route path="/archiwum" element={<Archiwum />} />

            <Route path="/blog/:page" element={<Blog />} />
            <Route path="/blog" element={<Blog />} />

            <Route path="/onas" element={<ONas />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/ranking/:page" element={<Ranking />} />
            <Route path="/ranking" element={<Ranking />} />

            <Route path="/prywatnosc" element={<Prywatnosc />} />
            <Route path="/dmca" element={<DMCA />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Zaloguj />} />
            <Route path="/rejestracja" element={<Rejestracja />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/profil/:username" element={<Profil />} />
            <Route path="/dodaj" element={<Dodaj />} />
            <Route path="/panel" element={<Panel />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/:text/:second_arg" element={<Identify />} />
            <Route path="/:text" element={<Identify />} />
            <Route path="/artykul/:url" element={<Article />} />
            <Route path="/artykul_edit/:url" element={<ArticleEdit />} />
            <Route path="/artykul_edit" element={<ArticleEdit />} />
            <Route path="/szukaj/:term/:page_num" element={<Search />} />
            <Route path="/szukaj/:term" element={<Search />} />

            {/* <Route path="/result" element ={<Result />} /> */}
        </Routes>
    );
}
