import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import FakeA from "./fakeA.jsx";
import { beautifyText } from "./Misc";
import AlewersyAPI from "../services/AlewersyAPI";
import { Parser } from "react-tiny-bbcode";

import Ad from "./Ad.jsx";

const alewersyAPI = AlewersyAPI.getInstance();

let spanColors = [
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
    "#f0b400",
    "#1ac6ff",
    "#00cc00",
];

function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}

export default function Home() {
    let [recentAnnotations, setRecentAnnotations] = useState([]);
    let [annotationToday, setAnnotationToday] = useState(null);
    let [annotationWeek, setAnnotationWeek] = useState([]);
    let [annotationEver, setAnnotationEver] = useState([]);
    let [annotation24h, setAnnotation24h] = useState([]);
    let [lastWeekSorted, setLastWeekSorted] = useState([]);
    let [recentSongs, setRecentSongs] = useState([]);
    let [recentUserAddedSongs, setRecentUserAddedSongs] = useState([]);
    let [lastArticles, setLastArticles] = useState([]);
    let [recentVisible, setRecentVisible] = useState(false);
    const [rerender, setRerender] = useState(); // or any state
    const [afterRender, setAfterRender] = useState(); // internal state
    let [lastSorted, setSorted] = useState([]);
    let [ranking, setRanking] = useState({ byNewest: [], sortedRanking: [], activeUsers: [] });

    useEffect(() => {
        if (!afterRender) return;
        // here DOM is loaded and you can query DOM elements
        // then reset

        window.$("#da-slider").cslider();
        // console.log(document.readyState);

        setAfterRender(false);
    }, [afterRender]);

    useEffect(() => {
        setAfterRender(true); // (1) will be called after DOM rendered
    }, [rerender]); // or don't set any if you want to listen to all re-render events

    useEffect(() => {
        downloadRecentAnnotations();
        downloadRecentSongs();

        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = false;

        script.onload = () => {
            // console.log('script loaded');

            setTimeout(() => {
                var TestiSlide = window.$(".bxslider");
                //console.log(TestiSlide);

                TestiSlide.bxSlider({
                    auto: true,
                    pager: false,
                    controls: false,
                    useCSS: false,
                    speed: 2000,
                    easing: "easeOutElastic",
                    mode: "horizontal",
                    controlDirections: true,
                });

                setRecentVisible(true);
            }, 500);
        };

        document.body.appendChild(script);

        (async () => {
            let ranking = await alewersyAPI.getRanking();
            setRanking(ranking);
        })();

        (async () => {
            let articles = await alewersyAPI.getRecentArticles();
            setLastArticles(articles.lastArticles);
        })();
    }, []);

    async function downloadRecentSongs() {
        let songs = await alewersyAPI.getRecentSongs();
        setRecentSongs(songs.songs);

        songs = await alewersyAPI.getRecentUserAddedSongs();
        setRecentUserAddedSongs(songs.songs);
    }

    async function downloadRecentAnnotations() {
        let annotations = await alewersyAPI.getRecentAnnotations();
        //console.log(annotations);
        setRecentAnnotations(annotations);
    }

    useEffect(() => {
        //console.log('recent annotations was changed! ');
        if (recentAnnotations.lastWeek === undefined) return;

        var d = new Date();
        let timestamp = parseInt(d.getTime() / 1000);
        //console.log(`currentTimestamp: ${timestamp}`);

        d.setHours(0, 0, 0, 0);
        let timestampMidnight = parseInt(d.getTime() / 1000);
        //console.log(`timestampMidnight: ${timestampMidnight}`);

        let annot_today = recentAnnotations.lastWeek.filter((elem) => {
            if (elem.annotation_timestamp >= timestampMidnight) {
                return true;
            } else return false;
        });

        annot_today = annot_today.sort((a, b) => {
            if (a.annotation_points > b.annotation_points) return -1;
            if (a.annotation_points < b.annotation_points) return 1;
            return 0;
        });

        //console.log("sorted: ");
        //console.log(annot_today);

        let annot_24h = recentAnnotations.lastWeek.filter((elem) => {
            if (elem.annotation_timestamp >= timestamp - 86400) {
                return true;
            } else return false;
        });

        // console.log(`annot_today length: ${annot_today.length}`);

        annot_today = getUniqueAnnotations(annot_today).slice(0, 20);

        // console.log("unix")

        let everUnique = getUniqueAnnotations(recentAnnotations.ever).slice(0, 20);
        setAnnotationEver(everUnique);

        if (annot_today.length > 0 && annot_today.length < 20) {
            // dodajemy z annotations ever
            annot_today = annot_today.concat(everUnique);
            annot_today = getUniqueAnnotations(annot_today).slice(0, 20);
        }

        setAnnotationToday(annot_today);

        let annotLastWeekUnique = recentAnnotations.lastWeek;
        annotLastWeekUnique = getUniqueAnnotations(annotLastWeekUnique).slice(0, 20);

        if (annotLastWeekUnique.length < 20) {
            // dodajemy z annotations ever
            annotLastWeekUnique = annotLastWeekUnique.concat(everUnique);
            annotLastWeekUnique = getUniqueAnnotations(annotLastWeekUnique).slice(0, 20);
        }

        if (annot_24h.length == 0) {
            // dodajemy z annotations ever
            annot_24h = annot_24h.concat(everUnique);
            annot_24h = getUniqueAnnotations(annot_24h).slice(0, 20);
        }

        setAnnotationWeek(annotLastWeekUnique);
        setAnnotation24h(annot_24h);

        //console.log(annot_24h);

        let lastWeekS = JSON.parse(JSON.stringify(recentAnnotations.ever));
        lastWeekS.sort((a, b) => {
            if (a.annotation_timestamp > b.annotation_timestamp) return -1;
            if (b.annotation_timestamp > a.annotation_timestamp) return 1;
            return 0;
        });

        // lastWeekS = lastWeekS.slice(0, 8);

        //console.log('last week sorted: ');
        // console.log(lastWeekS);

        let lastWeekSunique = getUniqueAnnotations(lastWeekS);
        lastWeekSunique = lastWeekSunique.slice(0, 8);
        setLastWeekSorted(lastWeekSunique);

        //console.log("recentAnnotations=")
        //console.log(recentAnnotations);

        let lastUnique = getUniqueAnnotations(recentAnnotations.last);
        setSorted(lastUnique.slice(0, 8));
    }, [recentAnnotations]);

    return (
        <>
            <Helmet>
                <title>Śmieszne Interpretacje Tekstów Piosenek | tekst-piosenki.pl</title>
                <meta
                    name="description"
                    content="Znajdź swoją ulubioną piosenkę i dodaj humorystyczną interpretację jej wersów. Dodawaj własne teksty piosenek, słuchaj remixów, pisz komentarze i korzystaj ze wszystkich bezpłatnych narzędzi bez rejestracji."
                />
            </Helmet>

            {/* section featured */}
            <section id="featured">
                {/* start slider */}
                <div id="da-slider" className="da-slider">
                    <div className="da-slide">
                        <h2>
                            <font color="#f0b400">NAJLEPSZE</font>{" "}
                            <span style={{ backgroundColor: "#f0b400" }}>
                                &nbsp; DZISIAJ &nbsp;
                            </span>
                        </h2>
                        <p>
                            <b>
                                {/* {
                            annotationToday!= null && annotationToday.map( 
                               (annot, idx) => 
                                    <span key={annot.annotation_id} style={{fontWeight: 'bold',  marginRight: '5px', display: 'inline'}}> 
                                        <span style={{  whiteSpace: 'nowrap', backgroundColor: spanColors[idx], marginRight: '5px' }}>&nbsp; {idx+1} &nbsp;
                                        </span> 
                                        <FakeA href={'/'+recentAnnotations.songs[annot.annotation_song_id].song_urlname}><font color="white">{recentAnnotations.songs[annot.annotation_song_id].song_search }</font></FakeA> 
                                    </span> 
                            )
                        }		 */}

                                {annotationToday != null &&
                                    annotationToday.map((annot, idx) => (
                                        <span
                                            key={annot.annotation_id}
                                            style={{
                                                fontWeight: "bold",
                                                marginRight: "5px",
                                                display: "inline",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "white",
                                                    whiteSpace: "nowrap",
                                                    backgroundColor: spanColors[idx],
                                                    marginRight: "5px",
                                                }}
                                            >
                                                &nbsp; {idx + 1} &nbsp;
                                            </span>
                                            <FakeA
                                                href={
                                                    "/" +
                                                    recentAnnotations.songs[
                                                        annot.annotation_song_id
                                                    ].song_urlname
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                            >
                                                <font color={spanColors[idx]}>
                                                    {beautifyText(
                                                        recentAnnotations.songs[
                                                            annot.annotation_song_id
                                                        ].song_search,
                                                    )}
                                                </font>
                                            </FakeA>
                                        </span>
                                    ))}

                                {annotationToday != null &&
                                    annotationToday.length == 0 &&
                                    annotationWeek != null &&
                                    annotationWeek.map((annot, idx) => (
                                        <span
                                            key={annot.annotation_id}
                                            style={{
                                                fontWeight: "bold",
                                                marginRight: "5px",
                                                display: "inline",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "white",
                                                    whiteSpace: "nowrap",
                                                    backgroundColor: spanColors[idx],
                                                    marginRight: "5px",
                                                }}
                                            >
                                                &nbsp; {idx + 1} &nbsp;
                                            </span>
                                            <FakeA
                                                href={
                                                    "/" +
                                                    recentAnnotations.songs[
                                                        annot.annotation_song_id
                                                    ].song_urlname
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                            >
                                                <font color={spanColors[idx]}>
                                                    {" "}
                                                    {
                                                        recentAnnotations.songs[
                                                            annot.annotation_song_id
                                                        ].song_search
                                                    }
                                                </font>
                                            </FakeA>
                                        </span>
                                    ))}
                            </b>
                        </p>
                    </div>
                    <div className="da-slide">
                        <h2>
                            <font color="#1ac6ff">NAJLEPSZE</font>{" "}
                            <span style={{ backgroundColor: "#1ac6ff" }}>
                                &nbsp; W TYGODNIU &nbsp;
                            </span>
                        </h2>
                        <p>
                            <b>
                                {annotationWeek != null &&
                                    annotationWeek.map((annot, idx) => (
                                        <span
                                            key={annot.annotation_id}
                                            style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: "5px",
                                                display: "inline",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    backgroundColor: spanColors[idx],
                                                    marginRight: "5px",
                                                }}
                                            >
                                                &nbsp; {idx + 1} &nbsp;
                                            </span>
                                            <FakeA
                                                href={
                                                    "/" +
                                                    recentAnnotations.songs[
                                                        annot.annotation_song_id
                                                    ].song_urlname
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                            >
                                                <font color={spanColors[idx]}>
                                                    {
                                                        recentAnnotations.songs[
                                                            annot.annotation_song_id
                                                        ].song_search
                                                    }
                                                </font>
                                            </FakeA>
                                        </span>
                                    ))}{" "}
                            </b>
                        </p>
                    </div>
                    <div className="da-slide">
                        <h2>
                            <font color="#00cc00">NAJLEPSZE</font>{" "}
                            <span style={{ backgroundColor: "#00cc00" }}>
                                &nbsp; KIEDYKOLWIEK &nbsp;
                            </span>
                        </h2>
                        <p>
                            <b>
                                {annotationEver != null &&
                                    annotationEver.map((annot, idx) => (
                                        <span
                                            key={annot.annotation_id}
                                            style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: "5px",
                                                display: "inline",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    backgroundColor: spanColors[idx],
                                                    marginRight: "5px",
                                                }}
                                            >
                                                &nbsp; {idx + 1} &nbsp;
                                            </span>
                                            <FakeA
                                                href={
                                                    "/" +
                                                    recentAnnotations.songs[
                                                        annot.annotation_song_id
                                                    ].song_urlname
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                            >
                                                <font color={spanColors[idx]}>
                                                    {
                                                        recentAnnotations.songs[
                                                            annot.annotation_song_id
                                                        ].song_search
                                                    }
                                                </font>
                                            </FakeA>
                                        </span>
                                    ))}{" "}
                            </b>
                        </p>
                    </div>
                    <nav className="da-arrows">
                        <span className="da-arrows-prev" />
                        <span className="da-arrows-next" />
                    </nav>
                </div>
                {/* end slider */}
            </section>
            {/* /section featured */}
            <section id="content" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <br />

                    <div className="row team" style={{ marginBottom: "10px" }}>
                        <div className="span12">
                            <h4 className="title">
                                Najnowsze <strong>Artykuły</strong>
                            </h4>
                        </div>

                        {recentAnnotations &&
                            lastArticles &&
                            lastArticles.slice(0, 4).map((elem, idx) => {
                                if (elem.article_url === undefined) {
                                    elem.article_url = "";
                                }

                                let thumb = null;
                                //create regexp for first <img src>
                                let match = elem.article_text.match(/\<img src="(.+?)"/);
                                // let match = elem.article_text.match(/\[img\](.+)\[\/img\]/);
                                // console.log(match);
                                if (match) thumb = match[1];

                                // console.log(elem.article_text);

                                let shortName = elem.article_title;
                                if (shortName.length > 64)
                                    shortName = shortName.substr(0, 63) + "...";

                                let s = {};
                                if (idx <= 3) s = { marginBottom: "0px" };

                                return (
                                    <div className="span3" key={elem.annotation_id} style={s}>
                                        <div
                                            style={{ backgroundColor: "#fff2cc" }}
                                            className="team-box"
                                        >
                                            <FakeA
                                                href={
                                                    "/artykul/" +
                                                    elem.article_url
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                                style={{ backgroundColor: "#f0b400" }}
                                                className="thumbnail"
                                            >
                                                {!thumb && (
                                                    <img
                                                        style={{
                                                            height: "123px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        src="/img/placeholder.jpg"
                                                        alt=""
                                                    />
                                                )}

                                                {thumb && (
                                                    <img
                                                        style={{
                                                            height: "123px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        src={thumb}
                                                        alt=""
                                                    />
                                                )}
                                            </FakeA>

                                            <div className="roles aligncenter">
                                                <FakeA
                                                    href={
                                                        "/artykul/" +
                                                        elem.article_url
                                                            .replace(new RegExp("\\/", "g"), "%2F")
                                                            .replace(new RegExp("\\?", "g"), "%3F")
                                                            .replace(new RegExp("\\#", "g"), "%23")
                                                    }
                                                >
                                                    <p
                                                        className="lead"
                                                        style={{ fontSize: "1.2em" }}
                                                    >
                                                        <strong>{beautifyText(shortName)} </strong>
                                                    </p>
                                                </FakeA>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        <p style={{ clear: "both" }}></p>

                        {/* {recentAnnotations &&
                            lastArticles &&
                            lastArticles.slice(4).map((elem, idx) => {
                                if (elem.article_url === undefined) {
                                    elem.article_url = "";
                                }

                                let thumb = null;
                                //create regexp for first <img src>
                                let match = elem.article_text.match(/\<img src="(.+?)"/);
                                // let match = elem.article_text.match(/\[img\](.+)\[\/img\]/);
                                // console.log(match);
                                if (match) thumb = match[1];

                                // console.log(elem.article_text);

                                let shortName = elem.article_title;
                                if (shortName.length > 64)
                                    shortName = shortName.substr(0, 63) + "...";

                                let s = {};
                                if (idx <= 3) s = { marginBottom: "0px" };

                                return (
                                    <div className="span3" key={elem.annotation_id} style={s}>
                                        <div
                                            style={{ backgroundColor: "#fff2cc" }}
                                            className="team-box"
                                        >
                                            <FakeA
                                                href={
                                                    "/artykul/" +
                                                    elem.article_url
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                                style={{ backgroundColor: "#f0b400" }}
                                                className="thumbnail"
                                            >
                                                {!thumb && (
                                                    <img
                                                        style={{
                                                            height: "123px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        src="/img/placeholder.jpg"
                                                        alt=""
                                                    />
                                                )}

                                                {thumb && (
                                                    <img
                                                        style={{
                                                            height: "123px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        src={thumb}
                                                        alt=""
                                                    />
                                                )}
                                            </FakeA>

                                            <div className="roles aligncenter">
                                                <FakeA
                                                    href={
                                                        "/artykul/" +
                                                        elem.article_url
                                                            .replace(new RegExp("\\/", "g"), "%2F")
                                                            .replace(new RegExp("\\?", "g"), "%3F")
                                                            .replace(new RegExp("\\#", "g"), "%23")
                                                    }
                                                >
                                                    <p
                                                        className="lead"
                                                        style={{ fontSize: "1.2em" }}
                                                    >
                                                        <strong>{beautifyText(shortName)} </strong>
                                                    </p>
                                                </FakeA>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })} */}
                    </div>

                    <div className="row">
                        <Ad adtype="1" />
                        <br />
                        <div className="span12">
                            <div className="row">
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#1ac6ff" }}
                                                className="ico icon-circled icon-bgdark icon-pencil icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Dodawaj <strong>Adnotacje</strong>
                                            </h4>
                                            <p>
                                                Wyjaśnij znaczenie wersów dowolnej piosenki używając
                                                sarkazmu, dowcipu oraz bogatej wyobraźni.
                                            </p>
                                            <FakeA href="/onas">Czytaj Więcej</FakeA>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#00cc00" }}
                                                className="ico icon-circled icon-bgdark icon-smile icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Parodia <strong>Geniusa</strong>
                                            </h4>
                                            <p>
                                                Zamiast prawdziwego znaczenia tekstów znajdziesz tu
                                                pokręcone, humorystyczne interpretacje.
                                            </p>
                                            <FakeA href="/faq">Jak to Działa</FakeA>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#f0b400" }}
                                                className="ico icon-circled icon-bgdark icon-edit-sign icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Zacznij <strong>Przygodę</strong>
                                            </h4>
                                            <p>
                                                Pisz komentarze, sprawdzaj tłumaczenia, odkrywaj
                                                remixy, covery i podkłady muzyczne.
                                            </p>
                                            <FakeA href="prywatnosc">Polityka</FakeA>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#f0b400" }}
                                                className="ico icon-circled icon-bgprimary icon-archive icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Baza <strong>Wiedzy</strong>
                                            </h4>
                                            <p>
                                                Odkrywaj powiązane albumy, znajdź wszystkie utwory
                                                wybranego wykonawcy i oceniaj twórczość artystów.
                                            </p>
                                            <FakeA href="dmca">Zasady Użytkowania</FakeA>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#1ac6ff" }}
                                                className="ico icon-circled icon-bgdanger icon-cogs icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Używaj <strong>Narzędzi</strong>
                                            </h4>
                                            <p>
                                                Zapętlaj dowolny utwór muzyczny, analizuj wersy,
                                                sprawdź gdzie go zakupić i zobacz oficjalny klip.
                                            </p>
                                            <FakeA href="/archiwum">Spis Autorów</FakeA>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#00cc00" }}
                                                className="ico icon-circled icon-bgsuccess icon-signal icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Obserwuj <strong>Ranking</strong>
                                            </h4>
                                            <p>
                                                Obserwuj ranking użytkowników, których zabawne
                                                interpretacje zdobyły największą ilość głosów.
                                            </p>
                                            <FakeA href="kontakt">Napisz do Nas</FakeA>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row team">
                        <div className="span12">
                            <h4 className="title">
                                Ostatnio Dodane <strong>Interpretacje</strong>
                            </h4>
                        </div>

                        {lastSorted.map((elem, idx) => {
                            if (recentAnnotations.songs[elem.annotation_song_id] === undefined) {
                                console.log("error: no song id - " + elem.annotation_song_id);
                                return <></>;
                            }

                            let shortName =
                                recentAnnotations.songs[elem.annotation_song_id].song_name;
                            if (shortName.length > 16) shortName = shortName.substr(0, 15) + "...";

                            let s = {};
                            if (idx <= 3) s = { marginBottom: "30px" };

                            return (
                                <div className="span3" key={elem.annotation_id} style={s}>
                                    <div
                                        style={{ backgroundColor: "#fff2cc" }}
                                        className="team-box"
                                    >
                                        <FakeA
                                            href={
                                                "/" +
                                                recentAnnotations.songs[
                                                    elem.annotation_song_id
                                                ].song_urlname
                                                    .replace(new RegExp("\\/", "g"), "%2F")
                                                    .replace(new RegExp("\\?", "g"), "%3F")
                                                    .replace(new RegExp("\\#", "g"), "%23")
                                            }
                                            style={{ backgroundColor: "#f0b400" }}
                                            className="thumbnail"
                                        >
                                            {recentAnnotations.songs[elem.annotation_song_id]
                                                .song_youtube.id === undefined && (
                                                <img
                                                    src="/img/placeholder2.png"
                                                    alt={
                                                        recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_search + " tekst"
                                                    }
                                                />
                                            )}
                                            {recentAnnotations.songs[elem.annotation_song_id]
                                                .song_youtube.id !== undefined && (
                                                <img
                                                    src={recentAnnotations.songs[
                                                        elem.annotation_song_id
                                                    ].song_youtube.thumbnails.medium.url.replace(
                                                        /https:\/\//g,
                                                        "/api/ytproxy/",
                                                    )}
                                                    alt={
                                                        recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_search + " tekst"
                                                    }
                                                />
                                            )}
                                        </FakeA>

                                        <div
                                            className="roles aligncenter"
                                            style={{
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <FakeA
                                                href={
                                                    "/" +
                                                    recentAnnotations.songs[
                                                        elem.annotation_song_id
                                                    ].song_urlname
                                                        .replace(new RegExp("\\/", "g"), "%2F")
                                                        .replace(new RegExp("\\?", "g"), "%3F")
                                                        .replace(new RegExp("\\#", "g"), "%23")
                                                }
                                            >
                                                <p className="lead" style={{}}>
                                                    <strong>{beautifyText(shortName)} </strong>
                                                </p>
                                                <p>
                                                    {beautifyText(
                                                        recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_search
                                                            .replace(
                                                                recentAnnotations.songs[
                                                                    elem.annotation_song_id
                                                                ].song_name,
                                                                "",
                                                            )
                                                            .trim(),
                                                    )}
                                                </p>
                                            </FakeA>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="span12">
                            <div className="solidline" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="span12 aligncenter">
                            <h3 className="title">
                                Najlepsze <strong>Interpretacje</strong> z Ostatnich 24 Godzin
                            </h3>
                            <div className="blankline30" />

                            <ul
                                className="bxslider"
                                style={{ display: recentVisible ? "block" : "none" }}
                            >
                                {annotation24h.map((elem) => {
                                    let shortName =
                                        recentAnnotations.songs[elem.annotation_song_id].song_name;
                                    let shortAnnotation = beautifyText(elem.annotation_text).substr(
                                        0,
                                        200,
                                    );
                                    if (elem.annotation_text.length > 200) shortAnnotation += "...";
                                    return (
                                        <li key={elem.annotation_id}>
                                            <blockquote>{shortAnnotation}</blockquote>
                                            <center>
                                                <img
                                                    alt=""
                                                    style={{ marginBottom: "5px" }}
                                                    src="img/tik-tok-songs.jpg"
                                                />

                                                <FakeA
                                                    href={
                                                        "/" +
                                                        recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_urlname
                                                            .replace(new RegExp("\\/", "g"), "%2F")
                                                            .replace(new RegExp("\\?", "g"), "%3F")
                                                            .replace(new RegExp("\\#", "g"), "%23")
                                                    }
                                                >
                                                    <h6>
                                                        <b>{beautifyText(shortName)}</b> <br />
                                                        {beautifyText(
                                                            recentAnnotations.songs[
                                                                elem.annotation_song_id
                                                            ].song_search
                                                                .replace(
                                                                    recentAnnotations.songs[
                                                                        elem.annotation_song_id
                                                                    ].song_name,
                                                                    "",
                                                                )
                                                                .trim(),
                                                        )}
                                                    </h6>
                                                </FakeA>
                                            </center>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                    <Ad adtype="2" />
                    <br />
                    <div className="row">
                        <div className="span12" style={{ overflow: "auto" }}>
                            <table className="table">
                                <tbody>
                                    <tr style={{ backgroundColor: "#0e161d" }}>
                                        <th>
                                            <center>
                                                <font color="#f0b400">Top Użytkownicy</font>
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                <font color="#1ac6ff">Aktywni Użytkownicy</font>
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                <font color="#00cc00">Nowi Użytkownicy</font>
                                            </center>
                                        </th>
                                    </tr>

                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => {
                                        if (
                                            ranking.sortedRanking[idx] === undefined &&
                                            ranking.activeUsers[idx] === undefined &&
                                            ranking.byNewest[idx] === undefined
                                        )
                                            return <></>;

                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <FakeA
                                                        href={
                                                            ranking.sortedRanking[idx] !== undefined
                                                                ? "/profil/" +
                                                                  ranking.sortedRanking[idx]
                                                                      .user_name
                                                                : ""
                                                        }
                                                    >
                                                        {" "}
                                                        {ranking.sortedRanking[idx] !== undefined
                                                            ? ranking.sortedRanking[idx].user_name
                                                            : ""}{" "}
                                                        <font color="#00cc00">
                                                            <b>
                                                                {ranking.sortedRanking[idx] !==
                                                                    undefined &&
                                                                ranking.sortedRanking[idx].points >
                                                                    0
                                                                    ? "+" +
                                                                      ranking.sortedRanking[idx]
                                                                          .points
                                                                    : ""}{" "}
                                                            </b>
                                                        </font>{" "}
                                                    </FakeA>
                                                </td>
                                                <td>
                                                    <FakeA
                                                        href={
                                                            ranking.activeUsers[idx] !== undefined
                                                                ? "/profil/" +
                                                                  ranking.activeUsers[idx].user_name
                                                                : ""
                                                        }
                                                    >
                                                        {" "}
                                                        {ranking.activeUsers[idx] !== undefined
                                                            ? ranking.activeUsers[idx].user_name
                                                            : ""}{" "}
                                                        <font color="#00cc00">
                                                            <b>
                                                                {ranking.activeUsers[idx] !==
                                                                    undefined &&
                                                                ranking.activeUsers[idx].points > 0
                                                                    ? "+" +
                                                                      ranking.activeUsers[idx]
                                                                          .points
                                                                    : ""}{" "}
                                                            </b>
                                                        </font>{" "}
                                                    </FakeA>
                                                </td>
                                                <td>
                                                    <FakeA
                                                        href={
                                                            ranking.byNewest[idx] !== undefined
                                                                ? "/profil/" +
                                                                  ranking.byNewest[idx].user_name
                                                                : ""
                                                        }
                                                    >
                                                        {" "}
                                                        {ranking.byNewest[idx] !== undefined
                                                            ? ranking.byNewest[idx].user_name
                                                            : ""}{" "}
                                                        <font color="#00cc00">
                                                            <b>
                                                                {ranking.byNewest[idx] !==
                                                                    undefined &&
                                                                ranking.byNewest[idx].points > 0
                                                                    ? "+" +
                                                                      ranking.byNewest[idx].points
                                                                    : ""}{" "}
                                                            </b>
                                                        </font>{" "}
                                                    </FakeA>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <center>
                        <FakeA href="/ranking">
                            <button
                                className="btn"
                                style={{
                                    backgroundColor: "#f0b400",
                                    border: "1px solid #f0b400",
                                    borderRadius: "3px",
                                }}
                            >
                                RANKING
                            </button>
                        </FakeA>
                    </center>
                    <br />
                    <br />
                    <div className="row">
                        <div className="span3">
                            <div
                                style={{ backgroundColor: "#fff2cc" }}
                                className="service-box aligncenter"
                            >
                                <div className="icon">
                                    <i
                                        style={{ backgroundColor: "#f0b400" }}
                                        className="icon-circled icon-bgprimary icon-repeat icon-4x"
                                    />
                                </div>
                                <h5>
                                    Zapętlaj <span style={{ color: "#f0b400" }}>Piosenki</span>
                                </h5>
                                <p>
                                    Uczysz się tekstu na pamięć i potrzebujesz małej pomocy? Wybierz
                                    dowolny fragment utworu i włącz tryb zapętlania.
                                </p>
                            </div>
                        </div>
                        <div className="span3">
                            <div
                                style={{ backgroundColor: "#fff2cc" }}
                                className="service-box aligncenter"
                            >
                                <div className="icon">
                                    <i
                                        style={{ backgroundColor: "#1ac6ff" }}
                                        className="icon-circled icon-bgsuccess icon-lightbulb icon-4x"
                                    />
                                </div>
                                <h5>
                                    Zmieniony <span style={{ color: "#1ac6ff" }}>Sens</span>
                                </h5>
                                <p>
                                    Każdy użytkownik może interpretować teksty piosenek w dowolny
                                    sposób i całkowicie przekręcać sens słów.
                                </p>
                            </div>
                        </div>
                        <div className="span3">
                            <div
                                style={{ backgroundColor: "#fff2cc" }}
                                className="service-box aligncenter"
                            >
                                <div className="icon">
                                    <i
                                        style={{ backgroundColor: "#00cc00" }}
                                        className="icon-circled icon-bgdanger icon-copy icon-4x"
                                    />
                                </div>
                                <h5>
                                    Tłumaczenia <span style={{ color: "#00cc00" }}>Tekstów</span>
                                </h5>
                                <p>
                                    Odkrywaj i dodawaj przetłumaczone teksty piosenek, serwis
                                    umożliwia wgrywanie tłumaczeń w dowolnym języku.
                                </p>
                            </div>
                        </div>
                        <div className="span3">
                            <div
                                style={{ backgroundColor: "#fff2cc" }}
                                className="service-box aligncenter"
                            >
                                <div className="icon">
                                    <i
                                        style={{ backgroundColor: "#f0b400" }}
                                        className="icon-circled icon-bgwarning icon-music icon-4x"
                                    />
                                </div>
                                <h5>
                                    Kariera <span style={{ color: "#f0b400" }}>Artysty</span>
                                </h5>
                                <p>
                                    Obserwuj karierę wybranego artysty, słuchaj jego najnowszych
                                    utworów, sprawdź albumy i dowiedz się gdzie je nabyć.
                                </p>
                            </div>
                        </div>
                    </div>
                    <center>
                        <div className="row">
                            <div className="span12">
                                <i>
                                    <i className="icon-hand-right" /> Serwis oferuje obszerny spis
                                    tekstów piosenek wydanych przez artystów z całego świata
                                    połączony z dużą dawką humoru. Znajdź dowolny utwór i przeczytaj
                                    jego interpretację dodaną przez użytkowników w dość zabawnej
                                    formie. Większość treści na stronie tworzą użytkownicy tacy jak
                                    ty, którzy trollują odwiedzających, dodając ironiczne,
                                    sarkastyczne i zabawne adnotacje do tekstów piosenek. Zapraszamy
                                    do zabawy!
                                    <br />
                                    <br />
                                    <i className="icon-hand-right" /> Dołącz do naszej społeczności
                                    już dziś i baw się konwencją! Dodawaj nieprawdziwe lecz zabawne
                                    znaczenie wersów piosenek, oglądaj powiązane klipy, odkrywaj
                                    albumy i pnij się w rankingu najlepszych trolli. Naprawdę
                                    doceniamy wszystkich, którzy dzielą się swoimi szalonymi
                                    pomysłami i wyjątkowym poczuciem humoru. Pisz recenzje, dodawaj
                                    teksty własnych piosenek, zapętlaj muzykę, odkrywaj artystów i
                                    wgrywaj tłumaczenia zagranicznych hitów.
                                    <br />
                                    <br />
                                    <i className="icon-hand-right" /> Nie możesz znaleźć jakiejś
                                    piosenki w naszej bazie? Skontaktuj się z nami albo sam dodaj
                                    jej tekst w kilka sekund! Każdy użytkownik może bez rejestracji
                                    dodawać nowe piosenki oraz publikować własne, pokręcone
                                    interpretacje utworów muzycznych. Jeśli zamierzasz zostać z nami
                                    dłużej, zdecydowanie polecamy ci zarejestrować się w serwisie.
                                    Dzięki temu znajdziesz się w rankingu największych trolli i
                                    zawalczysz o bycie w ścisłej czołówce.
                                </i>
                            </div>
                        </div>
                    </center>
                    <div className="row team" style={{ marginBottom: "0px" }}>
                        <div className="span12">
                            <h4 className="title">
                                Ostatnio Dodane <strong>Piosenki</strong>
                            </h4>
                        </div>

                        {recentSongs.slice(0, 12).map((elem, idx) => {
                            let shortName = elem.song_name;
                            if (shortName.length > 14) shortName = shortName.substr(0, 11) + "...";

                            let shortArtist = elem.song_search.replace(elem.song_name, "").trim();
                            if (shortArtist.length > 14)
                                shortArtist = shortArtist.substr(0, 11) + "...";

                            let s = {};
                            if (idx <= 6) s = { marginBottom: "30px" };

                            return (
                                <div className="span2" style={s} key={elem.song_id}>
                                    <div
                                        style={{ backgroundColor: "#fff2cc" }}
                                        className="team-box"
                                    >
                                        <FakeA
                                            style={{ backgroundColor: "#f0b400" }}
                                            href={elem.song_urlname
                                                .replace(new RegExp("\\/", "g"), "%2F")
                                                .replace(new RegExp("\\?", "g"), "%3F")
                                                .replace(new RegExp("\\#", "g"), "%23")}
                                            className="thumbnail"
                                        >
                                            {elem.song_youtube.id !== undefined &&
                                                elem.song_youtube.id !== "" && (
                                                    <img
                                                        style={{ maxHeight: "62px" }}
                                                        src={elem.song_youtube.thumbnails.medium.url.replace(
                                                            /https:\/\//g,
                                                            "/api/ytproxy/",
                                                        )}
                                                        alt={
                                                            beautifyText(elem.song_search) +
                                                            " tekst"
                                                        }
                                                    />
                                                )}
                                            {(elem.song_youtube.id === undefined ||
                                                elem.song_youtube.id === "") && (
                                                <img
                                                    style={{ maxHeight: "62px" }}
                                                    src="/img/placeholder.jpg"
                                                    alt={beautifyText(elem.song_search) + " tekst"}
                                                />
                                            )}
                                        </FakeA>
                                        <div className="roles aligncenter">
                                            <FakeA
                                                href={elem.song_urlname
                                                    .replace(new RegExp("\\/", "g"), "%2F")
                                                    .replace(new RegExp("\\?", "g"), "%3F")
                                                    .replace(new RegExp("\\#", "g"), "%23")}
                                            >
                                                <p>
                                                    <strong>{beautifyText(shortName)}</strong>
                                                </p>
                                                <p>{beautifyText(shortArtist)}</p>
                                            </FakeA>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="span12">
                            <p style={{ clear: "both" }}></p>
                            {recentSongs.slice(12, 12 + 20).map((elem, idx) => {
                                let shortName = elem.song_name;
                                if (shortName.length > 14)
                                    shortName = shortName.substr(0, 11) + "...";

                                let shortArtist = elem.song_search
                                    .replace(elem.song_name, "")
                                    .trim();
                                if (shortArtist.length > 14)
                                    shortArtist = shortArtist.substr(0, 11) + "...";

                                let s = {};
                                if (idx <= 6) s = { marginBottom: "30px" };

                                return (
                                    <FakeA
                                        href={elem.song_urlname
                                            .replace(new RegExp("\\/", "g"), "%2F")
                                            .replace(new RegExp("\\?", "g"), "%3F")
                                            .replace(new RegExp("\\#", "g"), "%23")}
                                    >
                                        {" "}
                                        {beautifyText(elem.song_search) + " "}
                                    </FakeA>
                                );
                            })}
                        </div>
                    </div>
                    <Ad adtype="3" />
                    <br />
                    <div className="row">
                        <div className="span12">
                            <div className="row">
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#1ac6ff" }}
                                                className="ico icon-circled icon-bgdark icon-upload-alt active icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Dodaj <strong>Piosenkę</strong>
                                            </h4>
                                            <p>
                                                Dajemy ci możliwość dodania tekstu własnej piosenki,
                                                jeśli jeszcze nie mamy jej w naszej bazie. Po
                                                dodaniu każdy będzie mógł interpretować jej wersy.
                                            </p>
                                            <b>
                                                <FakeA href="/dodaj">Dodaj Tekst</FakeA>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#00cc00" }}
                                                className="ico icon-circled icon-bgdark icon-eye-open active icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Śledź <strong>Aktywność</strong>
                                            </h4>
                                            <p>
                                                Zarejestruj konto już dziś i dostań się do top listy
                                                najbardziej zwariowanych użytkowników, z których
                                                poczuciem humoru przyjdzie ci rywalizować.
                                            </p>
                                            <b>
                                                <FakeA href="/ranking">Zobacz Ranking</FakeA>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div className="span4">
                                    <div className="box">
                                        <div className="icon">
                                            <i
                                                style={{ backgroundColor: "#f0b400" }}
                                                className="ico icon-circled icon-bgdark icon-search active icon-3x"
                                            />
                                        </div>
                                        <div className="text">
                                            <h4>
                                                Znajdź <strong>Teksty</strong>
                                            </h4>
                                            <p>
                                                Codziennie dodajemy do naszej kolekcji tysiące
                                                nowych piosenek i nie tylko. Znajdziesz tu miliony
                                                utworów, a także remixy, covery i podkłady muzyczne.
                                            </p>
                                            <b>
                                                <FakeA href="/rejestracja">Zarejestruj Się</FakeA>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row team" style={{ marginBottom: "0px" }}>
                        {/* <div className="span12">
                            <h4 className="title">
                                Ostatnio Dodane <strong>Piosenki</strong>
                            </h4>
                        </div> */}

                        {recentUserAddedSongs.slice(0, 12).map((elem, idx) => {
                            let shortName = elem.song_name;
                            if (shortName.length > 14) shortName = shortName.substr(0, 11) + "...";

                            let shortArtist = elem.song_search.replace(elem.song_name, "").trim();
                            if (shortArtist.length > 14)
                                shortArtist = shortArtist.substr(0, 11) + "...";

                            let s = {};
                            if (idx <= 6) s = { marginBottom: "30px" };

                            return (
                                <div className="span2" style={s} key={elem.song_id}>
                                    <div
                                        style={{ backgroundColor: "#fff2cc" }}
                                        className="team-box"
                                    >
                                        <FakeA
                                            style={{ backgroundColor: "#f0b400" }}
                                            href={elem.song_urlname
                                                .replace(new RegExp("\\/", "g"), "%2F")
                                                .replace(new RegExp("\\?", "g"), "%3F")
                                                .replace(new RegExp("\\#", "g"), "%23")}
                                            className="thumbnail"
                                        >
                                            {elem.song_youtube.id !== undefined &&
                                                elem.song_youtube.id !== "" && (
                                                    <img
                                                        style={{ maxHeight: "62px" }}
                                                        src={elem.song_youtube.thumbnails.medium.url.replace(
                                                            /https:\/\//g,
                                                            "/api/ytproxy/",
                                                        )}
                                                        alt={
                                                            beautifyText(elem.song_search) +
                                                            " tekst"
                                                        }
                                                    />
                                                )}
                                            {(elem.song_youtube.id === undefined ||
                                                elem.song_youtube.id === "") && (
                                                <img
                                                    style={{ maxHeight: "62px" }}
                                                    src="/img/placeholder.jpg"
                                                    alt={beautifyText(elem.song_search) + " tekst"}
                                                />
                                            )}
                                        </FakeA>
                                        <div className="roles aligncenter">
                                            <FakeA
                                                href={elem.song_urlname
                                                    .replace(new RegExp("\\/", "g"), "%2F")
                                                    .replace(new RegExp("\\?", "g"), "%3F")
                                                    .replace(new RegExp("\\#", "g"), "%23")}
                                            >
                                                <p>
                                                    <strong>{beautifyText(shortName)}</strong>
                                                </p>
                                                <p>{beautifyText(shortArtist)}</p>
                                            </FakeA>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="span12">
                            <p style={{ clear: "both" }}></p>
                            {recentUserAddedSongs.slice(12, 12 + 20).map((elem, idx) => {
                                let shortName = elem.song_name;
                                if (shortName.length > 14)
                                    shortName = shortName.substr(0, 11) + "...";

                                let shortArtist = elem.song_search
                                    .replace(elem.song_name, "")
                                    .trim();
                                if (shortArtist.length > 14)
                                    shortArtist = shortArtist.substr(0, 11) + "...";

                                let s = {};
                                if (idx <= 6) s = { marginBottom: "30px" };

                                return (
                                    <FakeA
                                        href={elem.song_urlname
                                            .replace(new RegExp("\\/", "g"), "%2F")
                                            .replace(new RegExp("\\?", "g"), "%3F")
                                            .replace(new RegExp("\\#", "g"), "%23")}
                                    >
                                        {" "}
                                        {beautifyText(elem.song_search) + " "}
                                    </FakeA>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
