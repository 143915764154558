import { Helmet } from "react-helmet"

export default function DMCA() {

    return <>
        <Helmet>
            <title>DMCA | tekst-piosenki.pl</title>
            <meta name="description" content="Znajdziesz tu regulamin serwisu, cenne wskazówki dla użytkowników, politykę dotyczącą ciasteczek i sposoby bezpiecznego przechowywania danych." />
        </Helmet>
        <section id="content">
        <div className="container">
            <div className="row">
                <div className="span8">
                    <br />
                    <ol>
                        <li>1. Informacje Osobiste
                            <ol>
                                <li>
                                    <strong>Przeglądanie</strong> https://tekst-piosenki.pl/ Można oglądać zawartość
                                    https://tekst-piosenki.pl/ bez
                                    <strong> Konta Użytkownika</strong>. Można też skontaktować się z
                                    Administratorem za pośrednictwem
                                    <strong>  Centrum Pomocy</strong>
                                    <strong> Użytkownika</strong> lub bezpośrednio przez wysłanie wiadomości e-mail, nie rejestrując konta.</li>
                                <li>
                                    <strong>Konto</strong> w https://tekst-piosenki.pl/ Niektóre działania w https://tekst-piosenki.pl/ takie jak w szczególności zamieszczanie
                                    Wpisów na
                                    Forum i przesyłanie
                                    Komentarzy (a w nich Linków lub
                                    Osadzonych Obiektów) wymagają
                                    Rejestracji Konta Użytkownika i są możliwe wyłącznie dla
                                    Zalogowanych Użytkowników. Podczas
                                    Rejestracji należy wprowadzić pewne informacje osobiste, w tym adres e-mail i hasło. Są
                                    one używane w celu ochrony
                                    Konta Użytkownika przed
                                    Nieautoryzowanym Dostępem.</li>
                                <li>
                                    <strong>Informacje dotyczące użytkowania</strong>. Podczas korzystania z
                                    https://tekst-piosenki.pl/
                                    Administrator może rejestrować informacje na taki temat, jak (w szczególności):
                                    <ol>
                                        <li>– dane o subskrybowanych grupach tematycznych,</li>
                                        <li>– dane o innych
                                            Użytkownikach, z którymi dany
                                            Użytkownik się komunikuje,
                                        </li>
                                        <li>– dane o oglądanych przez
                                            Użytkownika
                                            Wpisach i
                                            Komentarzach,</li>
                                        <li>– dane o elementach „klikanych” przez
                                            Użytkownika w
                                            https://tekst-piosenki.pl/</li>
                                    </ol>
                                </li>
                                <li>
                                    https://tekst-piosenki.pl/ (w tym elementach
                                    <strong> Interfejsu Użytkownika</strong> i
                                    <strong>Ustawieniach</strong>). W celu zapewnienia odpowiedniej jakości obsługi
                                    Administrator może umieszczać tag (określany też jako plik „web beacon”) w wiadomościach
                                    HTML e-mail działu obsługi klienta lub innych wysyłanych wiadomościach, aby potwierdzić ich dostarczenie.</li>
                                <li>
                                    <strong>Zawartość</strong> przesłana do https://tekst-piosenki.pl/ Wszelkie
                                    Informacje Osobiste oraz
                                    Wpisy i
                                    Komentarze udostępnione on-line, w tym strona
                                    Profilu Użytkownika, mogą być gromadzone i używane przez innych
                                    Użytkowników.</li>
                            </ol>
                        </li>
                        <li>2. Sposoby wykorzystania danych i Cookies
                            <ol>
                                <li>W przypadku przesłania do https://tekst-piosenki.pl/ Informacji Osobistych, mogą one zostać użyte do utrzymywania, udostępniania
                                    i udoskonalania https://tekst-piosenki.pl/ oraz przetwarzania wszystkich wysłanych Wpisów i Komentarzy.</li>
                                <li>Administrator nie używa informacji osobistych Użytkowników do wysyłania wiadomości handlowych czy reklamowych
                                    bez ich zgody. Administrator może używać adresu e-mail do celów innych niż reklamowe lub administracyjnych
                                    (takich jak powiadamianie o istotnych zmianach w https://tekst-piosenki.pl/ lub obsługa Użytkownika). Na stronie
                                    ustawień Konta Użytkownika można wybrać, jak często Administrator wysyła wiadomości e-mail z informacjami
                                    o aktualizacjach.</li>
                                <li>Administrator używa plików cookie, web beacon oraz informacji dzienników, aby (w szczególności):
                                    <ol>
                                        <li>zapisywać informacje, dzięki czemu nie trzeba wprowadzać ich ponownie podczas kolejnych odwiedzin
                                            Użytkownika w https://tekst-piosenki.pl/</li>
                                        <li>dostarczać Użytkownikowi niestandardową i spersonalizowaną treść oraz informacje;</li>
                                        <li>monitorować efektywność kampanii marketingowych;</li>
                                        <li>monitorować zbiorcze pomiary, np. łączną liczbę odwiedzających i wyświetlonych stron;</li>
                                        <li>śledzić Wpisy i Komentarze użytkownika oraz jego status w ewentualnych promocjach, loteriach
                                            i konkursach.</li>
                                    </ol>
                                </li>
                                <li>Cookies:
                                    <ol>
                                        <li>mogą być używane przez Administratora lub jego Partnerów Marketingowych celem identyfikacji Użytkownika
                                        </li>
                                        <li>mogą być wykorzystywane celem śledzenia wszelkiej aktywności Użytkownika, w tym – w szczególności
                                            – do pozyskiwania informacji o których mowa w powyższym punkcie 3. oraz do zbierania następujących
                                            danych (faktycznie lub potencjalnie):
                                            <ol>
                                                <li>
                                                    <ol>
                                                        <li>informacje o przeglądanych w ramach https://tekst-piosenki.pl/ stronach</li>
                                                        <li>informacje o innych Użytkownikach, z którymi dany Użytkownik się komunikuje,
                                                            na jakie profile wchodzi, etc.</li>
                                                        <li>informacje o prowadzonych przez Użytkownika interakcjach z innymi Użytkownikami
                                                            i poszczególnymi częściami lub elementami https://tekst-piosenki.pl/</li>
                                                        <li>informacje o wszelkich elementach „klikanych” przez Użytkownika w https://tekst-piosenki.pl/,</li>
                                                        <li>informacje z jakich zewnętrznych stron Użytkownik wszedł do Serwisu</li>
                                                        <li>informacje do jakich zewnętrznych stron Użytkownik został przekierowany z Serwisu</li>
                                                        <li>informacje o numerze IP z jakiego Użytkownik wszedł na Serwis i/lub z jakiego
                                                            się logował, etc</li>
                                                        <li>informacje o wszelkich innych interakcjach Użytkownika z Portalem lub wywołanymi
                                                            przez niego jawnie lub niejawnie elementami</li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>mogą być wykorzystywane celem dopasowania treści do upodobań oraz nawyków Użytkownika (analiza
                                            behawioralna), w tym chodzi (w szczególności) o reklamy, w tym tzw. marketing behawioralny
                                            oraz autopromocję Serwisu</li>
                                        <li>Użytkownik ma prawo sprzeciwu – musi w tym celu odpowiednio skonfigurować urządzenie końcowe
                                            (w szczególności przeglądarkę – wyłączenie lub ograniczenie stosowania cookies) lub skontaktować
                                            się z Administratorem; może też złożyć odpowiednie oświadczenie
                                        </li>
                                        <li>sprzeciw nie dotyczy jednak cookies o charakterze technicznym (a nie stricte śledzącym, służącym
                                            przede wszystkim celom marketingowym), niezbędnych do prawidłowego działania https://tekst-piosenki.pl/ i
                                            jego usług</li>
                                    </ol>
                                </li>
                            </ol>
                            <ol>
                                <li>[
                                    <strong>Profilowanie</strong>] Informacje o których mowa w punktach 3. i 4. mogą być wykorzystywane do profilowania
                                    – zarówno przez Administratora, jak i jego Partnerów Marketingowych, przy poszanowaniu prawa do sprzeciwu,
                                    o którym mowa w punkcie 4 (d) niniejszej Polityki i z zastrzeżeniem określonym w punkcie 4 (e).</li>
                                <li>[
                                    <strong>Monitorowanie mailingu</strong>] W celu zapewnienia odpowiedniej jakości obsługi Administrator może
                                    umieszczać tag (określany też czasem jako plik „web beacon”) w wiadomościach HTML e-mail działu obsługi
                                    klienta lub innych wysyłanych wiadomościach (w tym również w newsletterze, jak i w mailingu reklamowym),
                                    aby potwierdzić ich dostarczenie.</li>
                                <li>[
                                    <strong>Mailing – odpowiednie stosowanie</strong>] Do wiadomości wysyłanych drogą mailową, które zostają
                                    wysyłane w formacie html, o których mowa w punkcie uprzednim mają odpowiednio zastosowanie postanowienia
                                    powyższych punktów 3, 4 i 5. niniejszej Polityki (chodzi w szczególności o działania Użytkownika
                                    w ramach wiadomości e-mail, np. „kliknięcia” w aktywne elementy tej wiadomości, np. linki).</li>
                            </ol>
                        </li>
                        <li>3. Wybór Użytkownika co do ustawień prywatności, w tym Cookies
                            <ol>
                                <li>Mając swój Profil Użytkownika, Zarejestrowany Użytkownik może w dowolnym momencie zaktualizować lub poprawić
                                    informacje dotyczące jego osobistego profilu, preferencji poczty e-mail i ustawień prywatności.</li>
                                <li>Zarejestrowany Użytkownik w każdej chwili można określać, które informacje są dostępne dla innych Użytkowników,
                                    edytując swój Profil Użytkownika.</li>
                                <li>Użytkownik może odmówić przekazania informacji osobistych do https://tekst-piosenki.pl/. W takiej sytuacji nadal możesz
                                    mieć dostęp do niektórych Treści Użytkowników i przeglądać witrynę https://tekst-piosenki.pl/, ale świadczenie niektórych
                                    usług może być niemożliwe.</li>
                                <li>Użytkownik może wyłączyć stosowanie (obsługę) cookies w ustawieniach przeglądarki.</li>
                                <li>Wyłączenie obsługi cookies w przeglądarce i/lub urządzeniu może spowodować ograniczenie funkcjonalności
                                    https://tekst-piosenki.pl/ oraz być przyczyną różnych niedogodności dla Użytkownika.</li>
                            </ol>
                        </li>
                        <li>4. Reklamy w https://tekst-piosenki.pl/
                            <ol>
                                <li>Celem https://tekst-piosenki.pl/ jest udostępnianie trafnych i użytecznych reklam. Aby to osiągnąć, stosowane są
                                    PLIKI COOKIE oraz systemy reklamowe innych firm. Do wyświetlania odpowiednich reklam mogą być używane różne informacje,
                                    w tym pliki cookie, pliki web beacon, adresy IP, statystyki użycia oraz inne nieosobiste informacje
                                    dotyczące komputera lub urządzenia (takie jak typ przeglądarki i system operacyjny). W przypadku
                                    zalogowania na Koncie Użytkownika można też wyświetlać reklamy na podstawie informacji podanych w
                                    ramach tego konta.</li>
                                <li>Administrator używa w https://tekst-piosenki.pl/ m. In. plików Cookiem, aby ułatwić reklamodawcom i wydawcom wyświetlanie
                                    reklam w tej i innych witrynach partnerskich oraz zarządzanie nimi. Reklamy mogą być wyświetlane
                                    na podstawie zainteresowań powiązanych z informacjami o aktywności online, które nie umożliwiają
                                    identyfikacji użytkownika. Do takich informacji należą między innymi dane o wyświetlanych zawartościach.
                                    Reklamy mogą też być wyświetlane na podstawie wcześniejszej aktywności w witrynie danego reklamodawcy.
                                    Te informacje, na podstawie których nie można zidentyfikować Użytkownika, są przechowywane oddzielnie
                                    od danych Konta Użytkownika oraz innych posiadanych przez Administratora informacji, które umożliwiają
                                    identyfikację.</li>
                                <li>Inne firmy reklamowe mogą wyświetlać reklamy w https://tekst-piosenki.pl/, o ile spełniają wymagania Administratora dotyczące
                                    wyświetlania reklam. Administrator nie udostępnia serwerom ani sieciom tych firm żadnych informacji
                                    osobistych bez zgody Użytkownika. Aby uzyskać więcej informacji na temat praktyk stosowanych przez
                                    te serwery i sieci oraz instrukcje dotyczące wycofania zgody na objęcie nimi, należy zapoznać się
                                    z ich politykami prywatności. Polityka Prywatności https://tekst-piosenki.pl/ nie ma w tym przypadku zastosowania.
                                    Administrator nie ma możliwości kontrolowania działań innych reklamodawców czy witryn. Wszelkie dane
                                    uzyskane przez serwery reklamowe innych firm i udostępnione Portalowi są przetwarzane zgodnie z tą
                                    polityką prywatności.</li>
                            </ol>
                        </li>
                        <li>5. Więcej informacji
                            <ol>
                                <li>Administrator przestrzega polskich zasad bezpiecznego przechowywania danych osobowych. Odpowiedzi na
                                    pytania dotyczące produktu lub konta można znaleźć na stronach Centrum Pomocy.</li>
                                <li>Aby uzyskać więcej informacji na temat naszych procedur ochrony prywatności, należy zapoznać się z listą
                                    wersji zarchiwizowanych lub napisać na adres mailowy Administratora.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="span4">
                    <aside className="right-sidebar">
                        <div className="widget">
                            <br />
                            <ul className="cat">
                                <li><i className="icon-angle-right" /> Przestrzegaj zasad użytkowania</li>
                                <li><i className="icon-angle-right" /> Rejestracja nie jest wymagana</li>
                                <li><i className="icon-angle-right" /> Spam jest surowo zabroniony</li>
                                <li><i className="icon-angle-right" /> Wszystkie narzędzia są darmowe</li>
                                <li><i className="icon-angle-right" /> Strona działa na PC i smartfonach</li>
                                <li><i className="icon-angle-right" /> Testuj funkcje bez limitów</li>
                            </ul>
                        </div>
                        <div className="widget">
                            <div className="tabs">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#one" data-toggle="tab">OGÓLNE</a></li>
                                    <li><a href="#two" data-toggle="tab">WSKAZÓWKI</a></li>
                                </ul>
                                <div style={{ backgroundColor: '#fff2cc' }} className="tab-content">
                                    <div className="tab-pane active" id="one">
                                        <ul className="popular">
                                            <li>
                                                <img src="img/dummies/blog/small/1.jpg" className="thumbnail pull-left" />
                                                <p>Ochrona prywatności www.tekst-piosenki.pl</p>
                                                <span>Chronione przez DMCA</span>
                                            </li>
                                            <li>
                                                <img src="img/dummies/blog/small/2.jpg" className="thumbnail pull-left" />
                                                <p>Szukaj tekstów piosenek i publikuj objaśnienia</p>
                                                <span>Parodia genius.com</span>
                                            </li>
                                            <li>
                                                <img src="img/dummies/blog/small/3.jpg" className="thumbnail pull-left" />
                                                <p>Odpisujemy na wiadomości email w ciągu 24 godzin</p>
                                                <span>Szybki kontakt</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane" id="two">
                                        <ul className="recent">
                                            <li>
                                                <p>Nie używaj wulgaryzmów</p>
                                            </li>
                                            <li>
                                                <p>Nie obrażaj użytkowników</p>
                                            </li>
                                            <li>
                                                <p>Nie wklejaj linków wychodzących</p>
                                            </li>
                                            <li>
                                                <p>Nie spamuj</p>
                                            </li>
                                            <li>
                                                <p>Przeczytaj regulamin strony</p>
                                            </li>
                                            <li>
                                                <p>Nie reklamuj towarów ani usług</p>
                                            </li>
                                            <li>
                                                <p>Szanuj twórców muzyki</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
        </section>

    </>

}