import { Helmet } from "react-helmet";

export default function ONas() {
    return <>
        <Helmet>
            <title>O Nas | tekst-piosenki.pl</title>
            <meta name="description" content="Garść informacji o witrynie, opis funkcji i możliwości, lista dostępnych narzędzi, objaśnienia oraz cenne wskazówki dla nowych użytkowników." />
        </Helmet>

        <section style={{backgroundColor: '#fff2cc'}} id="inner-headline">
  <div className="container">
    <div className="row">
      <div className="span12">
        <div className="inner-heading">
          <ul className="breadcrumb">
            <li><a href="/">Strona Główna</a> <i className="icon-angle-right" /></li>
             &nbsp;O Nas
          </ul>
          <h2>Kim jesteśmy? Jak to działa?</h2>
        </div>
      </div>
    </div>
  </div>
</section>


    <section id="content">

        <div className="container">
            <div className="row">
                <div className="span12">
                    <i className="icon-hand-right" /> Wykaż się poczuciem humoru połączonym z bujną wyobraźnią i dołącz do grona aktywnych użytkowników, którzy publikują zabawne intepretacje piosenek, konkurując ze sobą w rankingu największych lirycznych trolli.
                    Dodawaj <b>nieprawdziwe i humorystyczne znaczenia tekstów</b>, ironizuj, dziel się swoimi pokręconymi pomysłami i spostrzeżeniami. Publikuj tłumaczenia, pisz komentarze, dodawaj remixy, covery i instrumentale, zapętlaj utwory lub wybrane ich fragmenty i dowiedz się gdzie legalnie nabyć albumy.
                    Obserwuj karierę wybranego artysty, słuchaj jego najnowszych utworów, sprawdzaj zapowiedzi i recenzje.
                    <br /><br />
                    <i className="icon-hand-right" /> Każdy użytkownik może korzystać ze wszystkich funkcji serwisu bez opłat oraz bez konieczności rejestracji. Jeżeli jednak chciałbyś znaleźć się na liście rankingowej i rywalizować z pozostałymi użytkownikami, konieczne będzie założenie konta.
                    Za najzabawniejsze adnotacje <b>zostaniesz nagrodzony punktami</b> przyznawanymi przez osoby odwiedzające witrynę. W bazie mamy wiele milionów tekstów piosenek, a każdego dnia do spisu dodawane są nowe tytuły. Ponadto dajemy ci możliwość publikacji tekstu własnej piosenki. Zanim zaczniesz korzystać z dobrodziejstw naszej witryny polecamy ci zapoznać się z regulaminem.
                    <br />
                    <div className="row">
                        <br />
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#f0b400' }} className="ico icon-circled icon-bgdark icon-edit-sign icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Własne <strong>Interpretacje</strong></h4>
                                    <p>
                                        Publikuj humorystyczne znaczenia wybranych wersów dowolnej piosenki używając sarkazmu, własnych pomysłów, wyobraźni i skojarzeń.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#000' }} className="ico icon-circled icon-bgdark icon-thumbs-up icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Liryczny <strong>Trolling</strong></h4>
                                    <p>
                                        Oceniaj nieprawdziwe i zabawne interpretacje tekstów piosenek, głosuj na najlepszych lirycznych trolli i sledź ranking użytkowników.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#f0b400' }} className="ico icon-circled icon-bgdark icon-copy active icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Pisz <strong>Tłumaczenia</strong></h4>
                                    <p>
                                        Dodawaj tłumczenia piosenek na dowolny język. Korzystaj ze wszystkich funkcji serwisu za darmo i bez konieczności rejestracji.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#000' }} className="ico icon-circled icon-bgprimary icon-save icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Baza <strong>Piosenek</strong></h4>
                                    <p>
                                        Użyj wyszukiwarki i znajdź wszystkie piosenki, albumy i klipy wybranego wykonawcy. Słuchaj utworów online, pisz adnotacje i komentarze.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#f0b400' }} className="ico icon-circled icon-bgdanger icon-wrench icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Sprawdź <strong>Możliwości</strong></h4>
                                    <p>
                                        Zapętlaj wybrany utwór muzyczny, zabawnie interpretuj jego wersy, sprawdź gdzie legalnie można go zakupić i zobacz oficjalny klip.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span4">
                            <div className="box">
                                <div className="icon">
                                    <i style={{ backgroundColor: '#000' }} className="ico icon-circled icon-bgsuccess icon-signal icon-3x" />
                                </div>
                                <div className="text">
                                    <h4>Śledź <strong>Ranking</strong></h4>
                                    <p>
                                        Obserwuj ranking użytkowników, których śmieszne interpretacje zdobyły największą ilość głosów. Załóż konto i weź udział w zabawie.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <i className="icon-hand-right" /> Nasz serwis oferuje obszerny spis tekstów piosenek wydanych przez artystów z całego świata połączony z olbrzymią dawką humoru. Skorzystaj z wyszukiwarki i znajdź teledyski, podkłady muzyczne, materiały edukacyjne i wiele więcej. Większość <u>treści na stronie tworzą użytkownicy</u> tacy jak ty, dodając tłumaczenia tekstów, recenzje, nowe tytuły, a także ironiczne, sarkastyczne i zabawne adnotacje do tekstów piosenek. Zapraszamy do zabawy!
                    Strona jest przystosowana zarówno dla komputerów osobistych jak i urządzeń mobilnych, a dzięki ciągłym aktualizacjom utrzymujemy serwis w doskonałej kondycji.
                    <br /><br />
                    <i className="icon-hand-right" /> Staramy się o to, by interfejs był jak najbardziej przejrzysty i przyjazdy dla użytkowników.
                    W przypadku gdy znalazłeś jakiś błąd na stronie, masz dla nas wskazówkę, sugestię lub propozycję, polecamy ci napisać do nas <u>wiadomość email</u> i opisać sprawę jak najbardziej szczegółowo. Jest to aktualnie najszybsza forma kontaktu z administracją. Obecnie nie oferujemy kontaktu telefonicznego. Na nadesłane wiadomości zwykle odpowiadamy w ciągu 24 godzin i staramy się rozwiązać problem tak szybko jak to tylko możliwe.
                    <br /><br />
                    <div className="row">
                        <div className="span3">
                            <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                                <img src="img/baza-piosenek.png" style={{ marginBottom: '5px' }} />
                                <div className="roles aligncenter">
                                    <p className="lead"><strong>Baza Tekstów</strong></p>
                                    <p>
                                        Miliony piosenek, klipów, podkładów muzycznych oraz remixów i coverów.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                                <img src="img/zabawne-interpretacje.png" style={{ marginBottom: '5px' }} />
                                <div className="roles aligncenter">
                                    <p className="lead"><strong>Tłumaczenia</strong></p>
                                    <p>
                                        Tysiące anglojęzycznych tekstów piosenek przetłumaczonych na język polski.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                                <img src="img/alewersypl.png" style={{ marginBottom: '5px' }} />
                                <div className="roles aligncenter">
                                    <p className="lead"><strong>Interpretacje</strong></p>
                                    <p>
                                        Mnóstwo zabawnych i pokręconych interpretacji dodanych przez użytkowników.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                                <img src="img/piosenki.png" style={{ marginBottom: '5px' }} />
                                <div className="roles aligncenter">
                                    <p className="lead"><strong>Ranking</strong></p>
                                    <p>
                                        Top lista użytkowników z największą ilością punktów za aktywność na stronie.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </section>
    </>
}