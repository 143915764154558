import { Helmet } from "react-helmet"

export default function Kontakt() {

    return <>
        <Helmet>
            <title>Kontakt | tekst-piosenki.pl</title>
            <meta name="description" content="Chcesz zadać nam pytanie, podzielić się swoim pomysłem albo zgłosić błąd na stronie? Wyślij wiadomość na naszą skrzynką mailową, a my odpowiemy w ciągu 24 godzin." />
        </Helmet>

        <section style={{ backgroundColor: '#fff2cc' }} id="inner-headline">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="inner-heading">
                            <ul className="breadcrumb">
                                <li><a href="/">Strona Główna</a> <i className="icon-angle-right" /></li>
                                &nbsp;Kontakt
                            </ul>
                            <h2>Napisz do nas</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span8">
                        Kontakt z administracją najłatwiej uzyskać poprzez wiadomości email, staramy się odpowiadać na nadesłane wiadomości w ciągu 24 godzin. Chcesz nas o coś zapytać? Znalazłeś błąd na stronie? A może masz dla nas jakąś propozycję albo pomysł na ulepszenie serwisu? Skontaktuj się z nami, a my odpowiemy tak szybko jak to tylko możliwe. Pisz śmiało na podany poniżej adres email.
                        <center>
                            <br />
                            <h5><strong>alewersy.pl@gmail.com</strong></h5>
                        </center>
                        Z chęcią przeczytamy wszystkie sugestie oraz zgłoszenia nadesłane przez użytkowników. Szybko i sprawnie aktualizujemy treści na stronie, wymieniamy informacje i moderujemy teksty dodane przez użytkowników. Zanim zdecydujesz się z nami skontaktować, zalecamy ci odwiedzić stronę FAQ. Być może znajduje się tam już odpowiedź na pytanie, które planujesz nam zadać.
                    </div>
                    <div className="span4">
                        <center>
                            <img src="img/kontakt.png" />
                        </center>
                    </div>
                </div>
            </div>
        </section>
    </>

}