import PageContent from "./components/PageContent";

import { Link } from 'react-router-dom';
import FakeA from './components/fakeA';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { beautifyText, checkText } from "./components/Misc";
import AlewersyAPI from "./services/AlewersyAPI";
import CookiesInfo from "./components/CookiesInfo";

const alewersyAPI = AlewersyAPI.getInstance();

function App() {
    let [songSearchValue, setSongSearchValue] = useState("");
    let [randomSongs, setRandomSongs] = useState([]);
    let [isLogged, setIsLogged] = useState(false);
    const location = useLocation();
    //console.log(location.pathname);
    const navigate = useNavigate();

    function handleStorageChange(changes) {
        console.log(changes);

        if (localStorage.userdata !== undefined) {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
    }

    function rebuildMenu() {

        window.$('.selectmenu').remove();
        var $menu = window.$('.navigation nav'),
            optionsList = '<option value="" selected>Idź do..</option>';

        $menu.find('li').each(function () {
            var $this = window.$(this),
                $anchor = $this.children('a'),
                depth = $this.parents('ul').length - 1,
                indent = '';

            if (depth) {
                while (depth > 0) {
                    indent += ' - ';
                    depth--;
                }
            }

            window.$(".nav li").parent().addClass("bold");
            optionsList += '<option value="' + $anchor.attr('href') + '">' + indent + ' ' + $anchor.text() + '</option>';
        }).end()
            .after('<select class="selectmenu">' + optionsList + '</select>');

        window.$('select.selectmenu').on('change', function () {
            window.location = window.$(this).val();
        });

    }

    useEffect(() => {
        rebuildMenu();

    }, [isLogged]);

    useEffect(() => {


        rebuildMenu();



        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = true;
        document.body.appendChild(script);

        downloadRandomSongs();
        // console.clear();

        if (localStorage.userdata !== undefined) {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

    }, []);

    async function downloadRandomSongs() {
        let random = await alewersyAPI.getRandomSongs();
        setRandomSongs(random.slice(0, 8));
    }

    function searchSong() {
        if (songSearchValue.trim() !== '') {

            if (checkText(songSearchValue.trim()) === false) {
                alert('Nie można wyszukiwać wyrazów zawierających wulgaryzmy!');
                return; 
            }
            console.log('searching for ' + songSearchValue);
            setSongSearchValue('');
            navigate(`/szukaj/${encodeURI(songSearchValue.trim())}`);
        }
    }

    function searchSongkeyUp(e) {
        if (e.keyCode === 13) {
            searchSong();
        }
    }

    return (<> <div id="wrapper">
        <header>
            <br />
            <div className="container">
                <div className="row nomargin">
                    <div className="span3">
                        <div className="logo">
                            <FakeA href="/"> <img src="/img/tekst-piosenki-pl.png" /> </FakeA> </div> </div> <div className="span9">
                        <div className="navbar navbar-static-top">
                            <div className="navigation">
                                <nav>
                                    <ul className="nav topnav">
                                        <li className="dropdown">
                                            <Link to="/"> Główna </Link> </li> <li className="dropdown">
                                            <Link to="/blog"> Blog </Link> </li> <li className="dropdown">
                                            <Link to="/onas"> O Nas </Link> </li> <li className="dropdown">
                                            <Link to="/faq"> FAQ </Link> </li> <li className="dropdown">
                                            <Link to='/ranking'> Ranking </Link> </li> <li className="dropdown">
                                            <Link to='/prywatnosc'> Prywatność </Link> </li> <li className="dropdown">
                                            <Link to='/dmca'> DMCA </Link> </li> <li className="dropdown">
                                            <Link to='/archiwum'> Archiwum </Link> </li> <li>
                                            <Link to="/kontakt"> Kontakt </Link> </li> {
                                            isLogged == true && <>
                                                <li>
                                                    <Link to={"/profil/" + JSON.parse(localStorage.userdata).username}> Profil </Link> </li><li> <Link
                                                        onClick={
                                                            (e) => {
                                                                e.preventDefault();
                                                                localStorage.removeItem("userdata");
                                                                setIsLogged(false);
                                                                navigate("/");
                                                            }
                                                        }
                                                        to="/logout"> Wyloguj </Link> </li> </>
                                        } {
                                            isLogged == false && <>
                                                <li>
                                                    <Link to="/login"> Zaloguj </Link> </li> <li>
                                                    <Link to="/rejestracja"> Rejestracja </Link> </li> </>}

                                        <li className="dropdown active">
                                            <Link to="/dodaj"> Dodaj Piosenkę </Link> </li> </ul> </nav> </div> { /* end navigation */} </div> </div> </div> </div> </header> { /* end header */} <div style={
                                                { backgroundColor: '#000' }}>
            <br />
            <center> <input style={
                { border: '1px solid #fff', borderRadius: '3px' }}
                value={songSearchValue}
                onKeyUp={
                    (e) => { searchSongkeyUp(e) }}
                onChange={
                    (e) => { setSongSearchValue(e.target.value); }}
                placeholder="Tytuł piosenki..." /> &nbsp; <button onClick={
                    () => { searchSong() }}
                    style={
                        { backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }}> Szukaj </button></center>
            <br />
        </div>

        { /* <section id="content"> */} <PageContent /> { /* </section> */}

        <footer>
            <div className="container">
                <div className="row">
                    <div className="span3">
                        <div className="widget">
                            <a href="/"> <img src="/img/tekst piosenki pl.png" /> </a> <br /> <br />
                            <i style={
                                { color: '#1ac6ff' }}
                                className="icon-forward" /> Puść wodze fantazji i podziel się humorystyczną interpretacją dowolnej piosenki.   Przekręcaj znaczenie wersów w swoich ulubionych utworach, czytaj i dodawaj tłumaczenia, oglądaj klipy, zapętlaj muzykę, komentuj i dodawaj nowe piosenki. Ironizuj, trolluj, śmiej się, używaj sarkazmu i skojarzeń. </div> </div> <div className="span3">
                        <div className="widget">
                            <h5 className="widgetheading"> Kontakt </h5> <address>
                                <strong> Tekst-Piosenki.pl </strong><br />
                                Kielce, Psie Górki, Polska <br />
                                Śmieszne Interpretacje Tekstów Piosenek
                                <br />
                            </address> <p>
                                Napisz do nas wiadomość email, <br /> a my odpowiemy w ciągu 24 godzin. <br />
                                <i className="icon-envelope-alt" /> alewersy.pl@gmail.com <br />
                                <i className="icon-phone" /> niedostępny </p> </div> </div> <div className="span6">
                        <div className="widget">
                            <h5 className="widgetheading"> Losowe piosenki </h5> <ul className="link-list">

                                { /* <li><a href="result.html">Rihanna Umbrella</a></li> */}

                                {randomSongs.map((elem) => <li key={elem.song_id}> <FakeA href={'/' + elem.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}> {beautifyText(elem.song_search)} </FakeA></li>)}



                            </ul> </div> </div> </div> </div> <div id="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="span6">
                            <div className="copyright">
                                <p> <span> {
                                    (new Date()).getFullYear()}© <a href="/"> tekst-piosenki.pl </a> Wszystkie prawa zastrzeżone.</span> </p> </div> </div> <div className="span6">
                            <div className="credits">
                                Dla fanów muzyki z wyrafinowanym poczuciem humoru. </div> </div> </div> </div> </div> </footer> </div> <CookiesInfo />
    </>
    );
}

export default App;