import { useState, useEffect } from 'react';
import FakeA from "./fakeA";
import AlewersyAPI from '../services/AlewersyAPI';
import { beautifyText } from "./Misc";
function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}


const alewersyAPI = AlewersyAPI.getInstance();

export default function NoweInterpretacje() {
    let [lastSorted, setSorted] = useState([]);
    let [recentAnnotations, setRecentAnnotations] = useState([]);
    let [recentSongs, setRecentSongs] = useState([]);
    let [annotationToday, setAnnotationToday] = useState(null);
    let [annotationWeek, setAnnotationWeek] = useState([]);
    let [annotationEver, setAnnotationEver] = useState([]);
    let [annotation24h, setAnnotation24h] = useState([]);
    let [lastWeekSorted, setLastWeekSorted] = useState([]);
    useEffect(() => {
        downloadRecentAnnotations();
        downloadRecentSongs();
    }, []);

    useEffect(() => {
        //console.log('recent annotations was changed! ');
        if (recentAnnotations.lastWeek === undefined) return;

        var d = new Date();
        let timestamp = parseInt((d.getTime()) / 1000);
        //console.log(`currentTimestamp: ${timestamp}`);

        d.setHours(0, 0, 0, 0);
        let timestampMidnight = parseInt((d.getTime()) / 1000);
        //console.log(`timestampMidnight: ${timestampMidnight}`);

        let annot_today = recentAnnotations.lastWeek.filter((elem) => {
            if (elem.annotation_timestamp >= timestampMidnight) {
                return true;
            } else return false;
        });

        annot_today = annot_today.sort((a, b) => {
            if (a.annotation_points > b.annotation_points) return -1;
            if (a.annotation_points < b.annotation_points) return 1;
            return 0;
        });

        //console.log("sorted: ");
        //console.log(annot_today);

        let annot_24h = recentAnnotations.lastWeek.filter((elem) => {
            if (elem.annotation_timestamp >= (timestamp - 86400)) {
                return true;
            } else return false;
        });

        // console.log(`annot_today length: ${annot_today.length}`);


        annot_today = getUniqueAnnotations(annot_today).slice(0, 20);

        // console.log("unix")

        let everUnique = getUniqueAnnotations(recentAnnotations.ever).slice(0, 20);
        setAnnotationEver(everUnique);


        if (annot_today.length > 0 && annot_today.length < 20) {
            // dodajemy z annotations ever
            annot_today = annot_today.concat(everUnique);
            annot_today = getUniqueAnnotations(annot_today).slice(0, 20);
        }

        setAnnotationToday(annot_today);


        let annotLastWeekUnique = recentAnnotations.lastWeek;
        annotLastWeekUnique = getUniqueAnnotations(annotLastWeekUnique).slice(0, 20);

        if (annotLastWeekUnique.length < 20) {
            // dodajemy z annotations ever
            annotLastWeekUnique = annotLastWeekUnique.concat(everUnique);
            annotLastWeekUnique = getUniqueAnnotations(annotLastWeekUnique).slice(0, 20);
        }

        if (annot_24h.length == 0) {
            // dodajemy z annotations ever
            annot_24h = annot_24h.concat(everUnique);
            annot_24h = getUniqueAnnotations(annot_24h).slice(0, 20);
        }

        setAnnotationWeek(annotLastWeekUnique);
        setAnnotation24h(annot_24h);

        //console.log(annot_24h);

        let lastWeekS = JSON.parse(JSON.stringify(recentAnnotations.ever));
        lastWeekS.sort((a, b) => {
            if (a.annotation_timestamp > b.annotation_timestamp) return -1;
            if (b.annotation_timestamp > a.annotation_timestamp) return 1;
            return 0;
        });

        // lastWeekS = lastWeekS.slice(0, 8);

        //console.log('last week sorted: ');
        // console.log(lastWeekS);

        let lastWeekSunique = getUniqueAnnotations(lastWeekS);
        lastWeekSunique = lastWeekSunique.slice(0, 8);
        setLastWeekSorted(lastWeekSunique);

        //console.log("recentAnnotations=")
        //console.log(recentAnnotations);

        let lastUnique = getUniqueAnnotations(recentAnnotations.last);
        setSorted(lastUnique.slice(0, 8));

    }, [recentAnnotations]);

    async function downloadRecentSongs() {
        let songs = await alewersyAPI.getRecentSongs();
        setRecentSongs(songs.songs);
    }

    async function downloadRecentAnnotations() {
        let annotations = await alewersyAPI.getRecentAnnotations();
        // console.log(annotations);
        setRecentAnnotations(annotations);
    }

    return <>
        <h6 className="widgetheading">Nowe Interpretacje</h6>







        {lastSorted.slice(0, 6).map((elem, idx) => {


            let shortName = recentAnnotations.songs[elem.annotation_song_id].song_name;
            if (shortName.length > 16) shortName = shortName.substr(0, 15) + '...';

            let s = {};
            if (idx <= 3) s = { marginBottom: '30px' };


            return (

                <center key={elem.annotation_id}>
                    <div className="mobile">
                        <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                            <FakeA href={'/' + recentAnnotations.songs[elem.annotation_song_id].song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')} style={{ backgroundColor: '#f0b400' }} className="thumbnail">

                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id === undefined && <img src="/img/placeholder2.png" alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}
                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id !== undefined && <img src={recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}


                            </FakeA>
                            <div className="roles aligncenter">
                                <br />
                                <FakeA href={'/' + recentAnnotations.songs[elem.annotation_song_id].song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}><p><strong>{beautifyText(shortName)}</strong></p>
                                    <p>
                                        {beautifyText(recentAnnotations.songs[elem.annotation_song_id].song_search.replace(recentAnnotations.songs[elem.annotation_song_id].song_name, '').trim())}
                                    </p>
                                </FakeA>
                            </div>
                        </div>
                        <br />
                    </div>
                </center>





            )
        })}

    </>
}