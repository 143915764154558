
import {
    //Route,
    //Link,
    useNavigate,
    //useLocation
} from "react-router-dom";

function FakeA(props) {
    // const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
         <a 
         style={props.style?props.style:{}}
            className={props.className?props.className:""} href={props.href} onClick={(e)=> {e.preventDefault(); navigate(props.href); } } > {props.children} </a>
        </>
    )
}

export default FakeA;