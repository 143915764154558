import { Helmet } from "react-helmet"

export default function Error404() {

    return <>
        <Helmet>
            <title>Błąd 404 | tekst-piosenki.pl</title>
            <meta name="description" content="Błąd 404. Podana strona nie istnieje. Wróć na stronę główną i spróbuj ponownie." />  </Helmet>
        <div>
            <section style={{ backgroundColor: '#fff2cc' }} id="inner-headline">
                <div className="container">
                    <div className="row">
                        <div className="span12">
                            <div className="inner-heading">
                                <ul className="breadcrumb">
                                    <li><a href="/">Strona Główna</a> <i className="icon-angle-right" /></li>
                                    &nbsp;Błąd 404
                                </ul>
                                <h2>Podana strona nie istnieje</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="span12">
                            <br /><br />
                            Nieprawidłowy adres strony. Wróć na stronę główną i spróbuj ponownie.
                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>

}