import React, { useEffect, useState } from 'react';

window.movie_start = 0;
window.movie_end = 999999;

window.onPlayerStateChange = function() {
    //console.log("OLD window.onPlayerStateChange");
};

function YoutubePlayer(props) {
    let [player, setPlayer] = useState(null);
    let [videoId, setVideoId] = useState('');

    let [startSeconds, setStartSeconds] = useState(0);
    let [endSeconds, setEndSeconds] = useState(999999);

    let playerConfig = {
        height: '560',
        width: '315',
        videoId: props.videoId,
        playerVars: {
            //origin: "https://www.youtube.com",
            //"playsinline" : 1,
            autoplay: 0,            // Auto-play the video on load
            controls: 1,            // Show pause/play buttons in player
            showinfo: 0,            // Hide the video title
            modestbranding: 1,      // Hide the Youtube Logo
            fs: 1,                  // Hide the full screen button
            cc_load_policy: 0,      // Hide closed captions
            iv_load_policy: 3,      // Hide the Video Annotations
            start: startSeconds,
            end: endSeconds,
            autohide: 0, // Hide video controls when playing
        },
        events: {
            onStateChange: ((e)=> { window.onPlayerStateChange(e); }),       // reference to Iframe API
            onReady: function (e) {              // mute the video when loaded
                //e.target.mute();             
            }
        }
    };

     // 		section.start = timeToSec(document.querySelector('#play-from'));
	//	section.end = timeToSec(document.querySelector('#play-to'));

    function onStateChange(state) {
        //console.log('onStateChange');
        //console.log(props.options);

        if (player) {
        //console.log(`startSeconds: ${startSeconds}`);
            if (state.data === window.YT.PlayerState.ENDED) {


                // player.loadVideoById({
                //     videoId,
                //     startSeconds: window.movie_start,
                //     endSeconds:window.movie_end
                // });

                

                if (props.options.shouldLoop) {
                    console.log("looping from begining");
                    player.seekTo(window.movie_start);
                    player.playVideo();
                }
            }
        }
    };


    // if(section.loop==false) {
	// 	btn.innerText = 'Stop';
	// 	section.start = timeToSec(document.querySelector('#play-from'));
	// 	section.end = timeToSec(document.querySelector('#play-to'));
	// 	section.loop = true;
	// 	player.seekTo(section.start);
	// 	player.playVideo();
	// } else {
	// 	btn.innerText = 'Loop';
	// 	section.loop = false;
	// 	section.start = section.end = 0;
	// 	player.pauseVideo();
	// 	player.seekTo(section.start);
	// }

    useEffect(()=> {
        console.log("options player changed!");
        window.onPlayerStateChange = onStateChange;

        if (player) {
            
        }

        if (player) {
            window.movie_start = props.options.start;
            window.movie_end = props.options.end;

            setStartSeconds(props.options.start);
            setEndSeconds(props.options.end);

            if (props.options.state === "playing") {

                
                player.loadVideoById({
                    videoId: props.videoId,
                    startSeconds: props.options.start,
                    endSeconds: props.options.end
                });

            } else {

                if (player) {
                    player.loadVideoById({
                        videoId: props.videoId,
                        startSeconds: props.options.start,
                        endSeconds: props.options.end
                    });
                    
                    //player.seekTo(2);
                    //player.pauseVideo(); 
                    player.stopVideo();
                }

            }

            // if (props.options.state === "playing") {
            //     console.log("jumping to: " + props.options.start);
            //     player.playVideo();
            //     player.seekTo(props.options.start);
            //     //player.playVideo();
            // } else {
            //     player.pauseVideo();
            // }
        }

    }, [props.options]);

    useEffect(() => {
        console.log('changing videoid to ' + props.videoId);
        //setVideoId(props.videoId);

        if (player)
        player.loadVideoById({
                    videoId: props.videoId,
                   
                });

        // loadVideo();
    }, [props.videoId]);

    useEffect(() => {
        if (!window.YT) {
            const script = document.createElement("script");
            script.src = "https://www.youtube.com/iframe_api";
            script.async = true;
            window.onYouTubePlayerAPIReady = loadVideo;
            document.body.appendChild(script);

            //const firstScriptTag = document.getElementsByTagName('script')[0];
            //firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
        } else {
            loadVideo();
        }
    }, []);

    function onReady() {

    }

    function loadVideo() {
        if (window.YT === undefined) return;
        //console.log("onYouTubePlayerAPIReady");
        //console.log("loading video....");

        //console.log(window);
        //console.log(window.YT);

        let myplayer = new window.YT.Player(`youtube-player-${props.videoId}`, playerConfig);
        player = myplayer;

        setPlayer(myplayer);
    }

    return (<>
        <div id="scrollhandler">   </div>
        {props.videoId.trim() !== '' && <div id={`youtube-player-${props.videoId}`} /> }
    </>);
}

export default YoutubePlayer;