import { useState, useEffect } from "react";
import AlewersyAPI from "../services/AlewersyAPI";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "./Loader";

const alewersyAPI = AlewersyAPI.getInstance();

function VideoTab(props) {
    let [userData, setUserdata] = useState(
        localStorage.userdata !== undefined ? JSON.parse(localStorage.userdata) : null,
    );

    let [adding, setAdding] = useState(false);
    let [songUrl, setSongUrl] = useState("");
    let [captcha, setCaptcha] = useState(null);
    let [captchaVisible, setCaptchaVisible] = useState(false);
    let [checking, setChecking] = useState(false);
    let [result, setResult] = useState({});
    let [error, setError] = useState("");

    let song = props.song;
    let plural = "REMIXY";

    switch (props.type) {
        case "cover":
            plural = "COVERY";
            break;
        case "instrumental":
            plural = "INSTRUMENTALE";
            break;
        default:
            break;
    }

    useEffect(() => {
        setAdding(false);
        //console.log("CHANGE REFRESH TIME");
    }, [props.refreshStamp]);

    function addNew() {
        setSongUrl("");
        setAdding(true);
        setResult({});
        setCaptchaVisible(false);
    }

    function onCaptchaChange(type, value) {
        console.log(value);

        setCaptcha(value);
    }

    useEffect(() => {
        if (captcha !== null) {
            addNewElement();
        }
    }, captcha);

    function checkYoutubeURL() {
        if (songUrl.trim() === "") {
            alert("najpierw wklej URL YouTube!");
            return;
        }
        identifyUrl();
    }

    async function identifyUrl() {
        console.log("waiting...");
        setError("");
        setChecking(true);
        let result = await alewersyAPI.checkYoutubeURL(songUrl.trim());
        console.log(result);

        if (result.error) {
            setError(result.error);
        } else {
            setResult(result);
            setCaptchaVisible(true);
        }
        setChecking(false);
    }

    async function addNewElement() {
        if (captcha === null) {
            alert("rozwiąż captchę!");
            return;
        }

        let answer = await alewersyAPI.addNewElement({
            song_id: song.song_id,
            type: props.type,
            videoId: result.id,
            videoThumbnail: result.thumbnail,
            videoTitle: result.title,
            "g-recaptcha-response": captcha,
        });

        console.log(answer);

        if (answer.result === "error") {
            alert(result.error);
            return;
        }

        let type = -1;

        switch (props.type) {
            case "remix":
                type = 0;
                break;
            case "cover":
                type = 1;
                break;
            case "instrumental":
                type = 2;
                break;
            default:
                break;
        }

        let newElement = {
            song_id: props.song.song_id,
            element_type: type,
            element_video_id: result.id,
            element_thumbnail_url: result.thumbnail,
            element_video_title: result.title,
        };

        if (props.onNewElement) {
            props.onNewElement(newElement);
        }

        // console.log(newAnnotation);
        // song.annotation.push(newAnnotation);
        // selectAnnotatedLine(linesSelected.sort()[0]);
        // setAddingAnnotation(false);

        // onCaptchaChange("annotation", null);

        setAdding(false);
        setCaptcha(null);
    }

    return (
        <>
            {adding && (
                <>
                    <small>
                        {song.song_name.toUpperCase()} NOWY {props.type.toUpperCase()}
                    </small>
                    <br />
                    <br />

                    <div className="clearfix"> </div>
                    <br />

                    <div>
                        <h3 style={{ margin: "0px" }}>Dodaj nowy {props.type}</h3>

                        {result.id !== undefined && (
                            <>
                                <p style={{ color: "black" }}> Title: {result.title} </p>
                                <img
                                    style={{ width: "400px" }}
                                    src={result.thumbnail.replace(/https:\/\//g, "/api/ytproxy/")}
                                />

                                <p style={{ color: "black" }}>
                                    {" "}
                                    solve captcha to add {props.type}{" "}
                                </p>

                                {captchaVisible && (
                                    <ReCAPTCHA
                                        style={{ margin: "5px" }}
                                        sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                                        onChange={(value) => {
                                            onCaptchaChange("annotation", value);
                                        }}
                                    />
                                )}

                                {/* <a onClick={(e)=>{setAdding(false); setCaptcha(null); }} style={{float: "left", marginRight: '5px'}} className="btn btn-red"><i className="icon-ban-circle"></i>&nbsp; CANCEL</a>
                                                    {captchaVisible && <a onClick={(e)=>{addNewElement(); }} style={{float: "left"}} className="btn btn-red"><i className="icon-edit"></i>&nbsp; ADD {props.type.toUpperCase()}</a>} */}
                            </>
                        )}
                        {result.id === undefined && checking == false && (
                            <>
                                <p style={{ margin: "0px", marginTop: "10px", width: "100%" }}>
                                    Wklej URL YouTube:
                                </p>
                                <input
                                    style={{ width: "300px" }}
                                    type="text"
                                    value={songUrl}
                                    onChange={(e) => {
                                        setSongUrl(e.target.value);
                                    }}
                                    onKeyUp={(e) => {}}
                                />{" "}
                                {error.length > 0 && (
                                    <span style={{ fontWeight: "bold", color: "#aa0000" }}>
                                        {error}
                                    </span>
                                )}
                                <p style={{ clear: "both" }} />
                                <a
                                    onClick={(e) => {
                                        setAdding(false);
                                        setCaptcha(null);
                                    }}
                                    style={{ float: "left", marginRight: "5px" }}
                                    className="btn btn-red"
                                >
                                    <i className="icon-ban-circle"></i>&nbsp; ANULUJ
                                </a>
                                {captchaVisible == false && (
                                    <a
                                        onClick={(e) => {
                                            checkYoutubeURL();
                                        }}
                                        style={{ float: "left" }}
                                        className="btn btn-red"
                                    >
                                        <i className="icon-edit"></i>&nbsp; SPRAWDŹ URL{" "}
                                    </a>
                                )}
                            </>
                        )}

                        {checking && <Loader />}
                    </div>
                </>
            )}

            {adding == false && (
                <>
                    {" "}
                    <small>
                        {song.song_name.toUpperCase()} {plural}
                    </small>
                    <br />
                    <a
                        onClick={(e) => {
                            addNew();
                        }}
                        style={{
                            float: "right",
                            backgroundColor: "#f0b400",
                            border: "1px solid #f0b400",
                            borderRadius: "3px",
                        }}
                        className="btn btn-red"
                    >
                        <i className="icon-music"></i>&nbsp; DODAJ {props.type.toUpperCase()}
                    </a>
                    <br />
                    <br />
                    {props.elements &&
                        props.elements.map((element, idx) => {
                            return (
                                <div
                                    className="span3 clickable"
                                    onClick={(e) => {
                                        if (props.onVideoSelect)
                                            props.onVideoSelect(element.element_video_id);
                                    }}
                                >
                                    {userData &&
                                        (userData.username === "reinhold" ||
                                            userData.username === "admin") && (
                                            <button
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if (window.confirm("Na pewno usunąć?")) {
                                                        console.log(element);
                                                        let res = await alewersyAPI.deleteElement(
                                                            element.element_id,
                                                        );
                                                        if (res.status === "error") {
                                                            alert(res.error);
                                                        } else {
                                                            window.location.reload();
                                                        }
                                                    }
                                                }}
                                                className="btn btn-danger"
                                            >
                                                USUŃ
                                            </button>
                                        )}

                                    <center>
                                        <img
                                            alt=""
                                            src={element.element_thumbnail_url.replace(
                                                /https:\/\//g,
                                                "/api/ytproxy/",
                                            )}
                                        />
                                        <br />
                                        <p
                                            style={{
                                                color: "black",
                                                fontSize: "13px",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            {element.element_video_title}
                                        </p>
                                    </center>
                                </div>
                            );
                        })}
                    {props.elements && props.elements.length == 0 && (
                        <div
                            style={{ background: "#e6e6e6", padding: "15px" }}
                            className="tab-content"
                        >
                            {" "}
                            <div>{plural.toUpperCase()} NIE DODANE</div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default VideoTab;
