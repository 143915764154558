import { Helmet } from "react-helmet";
import NoweInterpretacje from "./NoweInterpretacje";
import AlewersyAPI from "../services/AlewersyAPI";
import { useState, useEffect } from 'react';
import Paginator from "./Paginator";
import FakeA from "./fakeA";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Ad from "./Ad.jsx";

const alewersyAPI = AlewersyAPI.getInstance();

export default function Ranking() {
    let [ranking, setRanking] = useState([]);
	let navigate = useNavigate();
	let params = useParams();
    let perPage = 40;
	let [currentPage, setCurrentPage] = useState((params.page !== undefined && !isNaN(parseInt(params.page))) ? parseInt(params.page) : 1);
	let [pageCount, setPageCount] = useState(0);

    let [userData, setUserdata] = useState(null );

    useEffect(() => { 


        (async () => {
            let ranking = await alewersyAPI.getRanking();
            setRanking(ranking.sortedRanking);

            let totalPages = Math.ceil(ranking.sortedRanking.length / perPage);
            //console.log(`totalPages: ${totalPages}`);
            setPageCount(totalPages);
        })();
    }, []);

    function onPageSelect(page) {
		console.log("selecting page " + page);

		setCurrentPage(page);
		// downloadArtistInfo(page);

		if (page == 1) navigate('/ranking');
		else navigate('/ranking/' + page);
	}

    return <>
        <Helmet>
            <title>Ranking Użytkowników | tekst-piosenki.pl</title>
            <meta name="description" content="Top lista użytkowników publikujących treści w naszym serwisie. Dołącz do zabawy, publikuj śmieszne interpretacje i pnij się w rankingu największych trolli." />

        </Helmet>

        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span9">
                        <br />
                        <center><h3 className="title"><strong>Ranking Użytkowników</strong></h3>
                            <p>Top lista użytkowników, którzy zebrali najwięcej plusów za publikowanie zabawnych i pokręconych intepretacji. Zarejestruj się już dziś i rywalizuj z najlepszymi o tytuł największego lirycznego trolla. Powodzenia!</p>
                        </center>
                        <br />
                        <div className="solidline" />
                        <br />
                        <Ad adtype="1"/>
                        
                        <br />
                        <div className="clearfix"> </div>
                        <center>

                            {ranking.slice( (currentPage-1)*perPage, (currentPage-1)*perPage+perPage).map((user) => {

                                return <h6 key={user.user_id}><b><FakeA href={"/profil/"+user.user_name}>{user.user_name}</FakeA> <font color="#00cc00">{user.points>0?"+"+user.points:""}</font></b></h6>

                            })}

                        </center>
                        <br />
                        <center>
                            {pageCount>1 && <Paginator prelink="/ranking/" pageCount={pageCount} currentPage={currentPage} onPageSelect={onPageSelect} /> }


                        </center>
                    </div>
                    <div className="span3">
                        <aside className="right-sidebar">
                            <div className="widget">
                                <i>Ranking</i>
                                <center>
                                    <img src="/img/ranking.png" style={{ marginBottom: '12px', marginTop: '5px' }} />
                                </center>
                                <br />
                                <NoweInterpretacje />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    </>
}
