import { Helmet } from "react-helmet";
import NoweArtykuly from "./NoweArtykuly";
import AlewersyAPI from "../services/AlewersyAPI";
import { useState, useEffect } from "react";
import Paginator from "./Paginator";
import FakeA from "./fakeA";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Parser } from "react-tiny-bbcode";
import "react-quill/dist/quill.snow.css";
import { beautifyText } from "./Misc";

const alewersyAPI = AlewersyAPI.getInstance();

export default function Article() {
  let navigate = useNavigate();
  let params = useParams();
  let [shortText, setShortText] = useState("");
  const { url } = params;
  let [userData, setUserdata] = useState(null);
  let [recentSongs, setRecentSongs] = useState([]);

  let [article, setArticle] = useState({
    article_text: "",
    article_title: "",
  });

  async function downloadRecentSongs() {
    let songs = await alewersyAPI.getRecentUserAddedSongs();
    setRecentSongs(songs.songs);
  }

  useEffect(() => {
    // console.log(params);

    (async () => {
      let art = await alewersyAPI.getArticle(url);
      // console.log(art);

      setArticle(art[0]);

      let shortText;
      let fullText = art[0].article_text;

      // let match = elem.article_text.match(/\<img src="(.+?)"/);
      let fullimageMatch = /\<img src="(.+?)"/;
      let fullimageMatchG = /\<img src="(.+?)"/g;

      shortText = fullText.replace(fullimageMatchG, "");
      shortText = shortText.replace(new RegExp("\\n", "g"), " ");
      shortText = shortText.replace(new RegExp("\\[.+?\\]", "g"), "");
      shortText = shortText.trim();

      if (shortText.length > 140) {
        shortText = shortText.substr(0, 140);

        shortText = shortText.trim();

        while (shortText.charAt(shortText.length - 1) !== " ") {
          shortText = shortText.slice(0, -1);
        }

        shortText = shortText.trim();
        if (shortText.charAt(shortText.length - 1) === ",")
          shortText = shortText.slice(0, -1);
        shortText += "...";
      }

      setShortText(shortText);

      // let ranking = await alewersyAPI.getRanking();
      // setRanking(ranking.sortedRanking);

      // let totalPages = Math.ceil(ranking.sortedRanking.length / perPage);
      // //console.log(`totalPages: ${totalPages}`);
      // setPageCount(totalPages);
    })();
  }, [url]);

  useEffect(() => {
    downloadRecentSongs();
  }, []);

  return (
    <>
      <Helmet>
        <title>{article.article_title} | tekst-piosenki.pl</title>
        <meta name="description" content={shortText} />
      </Helmet>

      <section id="content">
        <div className="container">
          <div className="row">
            <div className="span9">
              <br />

              <h3>
                <b>{article.article_title}</b>
              </h3>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: article.article_text }}
              ></div>

              <br />
              <div className="row team">
                <div className="span12">
                  <h4>
                    <strong>
                      <span className="colored">Nowe Piosenki</span>
                    </strong>
                  </h4>
                </div>

                {recentSongs.slice(0, 8).map((elem, idx) => {
                  let shortName = elem.song_name;
                  if (shortName.length > 14)
                    shortName = shortName.substr(0, 11) + "...";

                  let shortArtist = elem.song_search
                    .replace(elem.song_name, "")
                    .trim();
                  if (shortArtist.length > 14)
                    shortArtist = shortArtist.substr(0, 11) + "...";

                  let s = {};
                  if (idx <= 4) s = { marginBottom: "30px" };

                  return (
                    <div className="span2" style={s} key={elem.song_id}>
                      <div
                        style={{ backgroundColor: "#fff2cc" }}
                        className="team-box"
                      >
                        <FakeA
                          style={{ backgroundColor: "#f0b400" }}
                          href={
                            "/" +
                            elem.song_urlname
                              .replace(new RegExp("\\/", "g"), "%2F")
                              .replace(new RegExp("\\?", "g"), "%3F")
                              .replace(new RegExp("\\#", "g"), "%23")
                          }
                          className="thumbnail"
                        >
                          {elem.song_youtube.id !== undefined &&
                            elem.song_youtube.id !== "" && (
                              <img
                                style={{ maxHeight: "62px" }}
                                src={elem.song_youtube.thumbnails.medium.url.replace(
                                  /https:\/\//g,
                                  "/api/ytproxy/"
                                )}
                                alt={beautifyText(elem.song_search) + " tekst"}
                              />
                            )}
                          {(elem.song_youtube.id === undefined ||
                            elem.song_youtube.id === "") && (
                            <img
                              style={{ maxHeight: "62px" }}
                              src="/img/placeholder.jpg"
                              alt={beautifyText(elem.song_search) + " tekst"}
                            />
                          )}
                        </FakeA>
                        <div className="roles aligncenter">
                          <FakeA
                            href={
                              "/" +
                              elem.song_urlname
                                .replace(new RegExp("\\/", "g"), "%2F")
                                .replace(new RegExp("\\?", "g"), "%3F")
                                .replace(new RegExp("\\#", "g"), "%23")
                            }
                          >
                            <p>
                              <strong>{beautifyText(shortName)}</strong>
                            </p>
                            <p>{beautifyText(shortArtist)}</p>
                          </FakeA>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="span3">
              <aside className="right-sidebar">
                <div className="widget">
                  <NoweArtykuly />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
