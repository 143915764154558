import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams, useNavigate } from "react-router-dom";
import Paginator from "./Paginator";
import FakeA from "./fakeA.jsx";
import Loader from "./Loader";
import { beautifyText } from "./Misc";

import AlewersyAPI from "../services/AlewersyAPI";
const alewersyAPI = AlewersyAPI.getInstance();

export default function Blog() {
    let navigate = useNavigate();
    let params = useParams();

    let [articles, setArticles] = useState([]);
    let [currentPage, setCurrentPage] = useState(params.page !== undefined && !isNaN(parseInt(params.page)) ? parseInt(params.page) : 1);
    let [pageCount, setPageCount] = useState(0);
    let [loading, setLoading] = useState(false);
    let perPage = 12;

    useEffect(() => {
        console.log("params:");
        console.log(params);
        console.log(`currentPage = ` + currentPage);
        window.$("#da-slider").cslider();
        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = true;
        document.body.appendChild(script);

        downloadArticlesInfo(currentPage);
    }, []);

    async function downloadArticlesInfo(currentPage) {
        setArticles([]);
        setLoading(true);

        //let result = await alewersyAPI.getArtists((currentPage - 1) * perPage, perPage);
        let result = await alewersyAPI.getArticles(); // ((currentPage - 1) * perPage, perPage);

        let totalPages = Math.ceil(result.length / perPage);
        //console.log(`totalPages: ${totalPages}`);
        setPageCount(totalPages);
        setLoading(false);

        setArticles(result.slice((currentPage - 1) * perPage, currentPage * perPage));
    }

    function onPageSelect(page) {
        console.log("selecting page " + page);

        setCurrentPage(page);
        downloadArticlesInfo(page);

        if (page == 1) navigate("/blog");
        else navigate("/blog/" + page);
    }

    return (
        <>
            <Helmet>
                <title>Blog | tekst-piosenki.pl</title>
                <meta name="description" content="Znajdziesz tu listę wszystkich twórców muzyki z całego świata. Odkrywaj nowoczesne kawałki i stare hity, remixy, ścieżki dźwiękowe i wiele więcej." />
            </Helmet>

            <section id="content" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <br />
                    <br />
                    <center>
                        <h3 class="title">
                            <strong>Aktualności</strong>
                        </h3>
                    </center>
                    <br />
                    <div className="row team" style={{ marginBottom: "10px" }}>
                        {loading && (
                            <>
                                <center>
                                    {" "}
                                    Ładowanie... <Loader /> <br />
                                    <br />{" "}
                                </center>{" "}
                            </>
                        )}
                        <center>
                            {articles &&
                                articles.map((elem, idx) => {
                                    // .slice(0, 4)
                                    if (elem.article_url === undefined) {
                                        elem.article_url = "";
                                    }

                                    let thumb = null;
                                    //create regexp for first <img src>
                                    let match = elem.article_text.match(/\<img src="(.+?)"/);
                                    // let match = elem.article_text.match(/\[img\](.+)\[\/img\]/);
                                    // console.log(match);
                                    if (match) thumb = match[1];

                                    // console.log(elem.article_text);

                                    let shortName = elem.article_title;
                                    if (shortName.length > 64) shortName = shortName.substr(0, 63) + "...";

                                    let s = {
                                        marginBottom: "25px",
                                    };
                                    // if (idx <= 3) s = { marginBottom: "0px" };

                                    return (
                                        <div className="span3" key={idx} style={s}>
                                            <div
                                                style={{
                                                    backgroundColor: "#fff2cc",
                                                }}
                                                className="team-box"
                                            >
                                                <FakeA
                                                    href={
                                                        "/artykul/" +
                                                        elem.article_url.replace(new RegExp("\\/", "g"), "%2F").replace(new RegExp("\\?", "g"), "%3F").replace(new RegExp("\\#", "g"), "%23")
                                                    }
                                                    style={{
                                                        backgroundColor: "#f0b400",
                                                    }}
                                                    className="thumbnail"
                                                >
                                                    {!thumb && (
                                                        <img
                                                            style={{
                                                                height: "123px",
                                                                width: "100%",
                                                                objectFit: "cover",
                                                            }}
                                                            src="/img/placeholder.jpg"
                                                            alt=""
                                                        />
                                                    )}

                                                    {thumb && (
                                                        <img
                                                            style={{
                                                                height: "123px",
                                                                width: "100%",
                                                                objectFit: "cover",
                                                            }}
                                                            src={thumb}
                                                            alt=""
                                                        />
                                                    )}
                                                </FakeA>

                                                <div className="roles aligncenter">
                                                    <FakeA
                                                        href={
                                                            "/artykul/" +
                                                            elem.article_url.replace(new RegExp("\\/", "g"), "%2F").replace(new RegExp("\\?", "g"), "%3F").replace(new RegExp("\\#", "g"), "%23")
                                                        }
                                                    >
                                                        <p
                                                            className="lead"
                                                            style={{
                                                                fontSize: "1.2em",
                                                                // overflow: "hidden",
                                                                // whiteSpace: "nowrap",
                                                                // textOverflow: "ellipsis",
                                                                fontWeight: "bold",
                                                            }}
                                                            alt={elem.article_title}
                                                        >
                                                            {beautifyText(shortName)}{" "}
                                                        </p>
                                                    </FakeA>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </center>
                        <p style={{ clear: "both" }} />
                        {loading == false && pageCount > 1 && <Paginator prelink="/blog/" pageCount={pageCount} currentPage={currentPage} onPageSelect={onPageSelect} />}

                        <br />
                    </div>
                </div>
            </section>
        </>
    );
}
