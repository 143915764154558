import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FakeA from './fakeA';
import AlewersyAPI from '../services/AlewersyAPI';
import NoweInterpretacje from './NoweInterpretacje';
import { beautifyText } from "./Misc";

const alewersyAPI = AlewersyAPI.getInstance();
const perPage = 18;

export default function Profil() {
    const navigate = useNavigate();
    let params = useParams();
    const { username } = params;
    let [userName, setUserName] = useState(null);
    let [userData, setUserdata] = useState(null);
	let [recentSongs, setRecentSongs] = useState([]);
    let [currentPage, setCurrentPage] = useState(0);
    let [pageCount, setPageCount] = useState(1);

    useEffect(() => {

        setUserdata(null);
        (async () => {
            let result = await alewersyAPI.getUser(username);
            // console.log(result);

            let fixed = result[0];
            let fixedSongs = [];

            for (let song in result[0].songs) {
                fixedSongs.push( {song_url: song, ...result[0].songs[song]} );
            }

            fixedSongs.sort((a,b)=> {

                if (a.points>b.points) return -1;
                if (b.points<b.points) return 1;
                return 0; 
            });

            // console.log(JSON.stringify(fixedSongs, null, 2));
            fixed.fixedSongs = fixedSongs;

            setUserdata({
                username: result[0].user_name,
                email: result[0].user_mail,
                ...fixed
            });

        })();

    }, [username]);
    
    useEffect(()=> {
        // console.log(userData);

        if (userData === null) return;

        let count = Object.keys(userData.songs).length;
        setPageCount( Math.ceil(count/perPage) );
        //console.log(  Math.ceil(count/perPage))

    }, [userData]);

    async function downloadRecentSongs() {
		let songs = await alewersyAPI.getRecentSongs();
		setRecentSongs(songs.songs);
	}

    useEffect(() => {
        downloadRecentSongs();
    }, []);

    async function deleteAccount(username) {
        if (window.confirm("Czy na pewno chcesz usunąć konto '"+username+"' ?")) {

            let result = await alewersyAPI.deleteAccount(username);
            console.log(result);

            navigate('/');
        }

    }

    if (userData === null) return <></>;

    return <>
        <Helmet>
            <title>{userData.username} - Profil Użytkownika | tekst-piosenki.pl</title>
            <meta name="description" content={"Profil użytkownika " + userData.username + ". Znajdziesz tu listę wszystkich piosenek, do których użytkownik dodał humorystyczne interpretacje."} />

        </Helmet>
        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span9">
                        <br />
                        <center><h3 className="title"><strong>{userData.username}</strong> <font color="#00cc00"><b>{userData.points > 0 ? "+" + userData.points : ""}</b></font></h3>
                            <h6 className="title">Miejsce w rankingu: <font color="#f0b400"><b>{userData.position}</b></font></h6>

                        {username !== "admin" && localStorage.userdata !== undefined && JSON.parse(localStorage.userdata ).username==="admin" && <>
                            <button onClick={(e)=>{ deleteAccount(username); }  } > USUŃ TO KONTO </button>
                        </>}

                        </center>
                        <br />
                        <div className="solidline" />
                        <br />
                        <center><p>{Object.keys(userData.songs).length === 0 ? "Brak aktywności" : "Aktywność użytkownika:"} </p></center>
                        <br />
                        <div className="clearfix"> </div>
                        <center>
                            {userData.fixedSongs.slice(currentPage*perPage, currentPage*perPage + perPage).map((song, idx) => {

                                return <h6 key={idx}><b><FakeA href={'/' + song.song_url}>{song.title}</FakeA> <font color="#00cc00">{song.points > 0 ? "+" + song.points : ""}</font></b></h6>
                            })}

                        </center>
                        <br />
                        <center>
                            {pageCount>1 && <>

                                {[...Array(pageCount).keys()].map((page)=> {

                                    let color = '#f0b400';
                                    if (page === currentPage) color = "#f03400";
                                return <a 
                                    onClick={(e)=>{ setCurrentPage(page); }}
                                    key={page}><button className="btn" style={{ color: '#fff', backgroundColor: color, border: '1px solid #f0b400', borderRadius: '3px' }}>{page+1}</button> </a>
                                 

                                }) }

                            
                            </>}
                           
                              </center>
                        <br />
                        <div className="row team">
                            <div className="span12">
                                <h4><strong><span className="colored">Nowe Piosenki</span></strong></h4>
                            </div>
                   
                            {recentSongs.slice(0, 8).map((elem, idx) => {

                                let shortName = elem.song_name;
                                if (shortName.length > 14) shortName = shortName.substr(0, 11) + '...';

                                let shortArtist = elem.song_search.replace(elem.song_name, '').trim();
                                if (shortArtist.length > 14) shortArtist = shortArtist.substr(0, 11) + '...';

                                let s = {};
                                if (idx <= 4) s = { marginBottom: '30px' };

                                return (
                                    <div className="span2" style={s} key={elem.song_id}>
                                        <div style={{ backgroundColor: "#fff2cc" }} className="team-box">
                                            <FakeA style={{ backgroundColor: '#f0b400' }} href={'/'+elem.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')} className="thumbnail">

                                                {(elem.song_youtube.id !== undefined && elem.song_youtube.id !== "") && <img style={{ maxHeight: '62px' }} src={elem.song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={beautifyText(elem.song_search) + " tekst"} />}
                                                {(elem.song_youtube.id === undefined || elem.song_youtube.id === "") && <img style={{ maxHeight: '62px' }} src="/img/placeholder.jpg" alt={beautifyText(elem.song_search) + " tekst"} />}


                                            </FakeA>
                                            <div className="roles aligncenter">
                                                <FakeA href={'/'+elem.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}><p><strong>{beautifyText(shortName)}</strong></p>
                                                    <p>
                                                        {beautifyText(shortArtist)}
                                                    </p>
                                                </FakeA>

                                            </div>
                                        </div>
                                    </div>);
                            })}

                        </div>
                    </div>
                    <div className="span3">
                        <aside className="right-sidebar">
                            <div className="widget">
                                <i>Użytkownik</i>
                                <center>
                                    <img src="/img/profil.png" style={{ marginBottom: '12px', marginTop: '5px' }} />
                                    <h6><b><font color="#f0b400">{userData.username}</font></b></h6>
                                </center>
                                <br />

                                <NoweInterpretacje />

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    </>
}