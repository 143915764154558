
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Paginator from './Paginator';
import Loader from './Loader';
import { beautifyText } from "./Misc";

import AlewersyAPI from '../services/AlewersyAPI';
const alewersyAPI = AlewersyAPI.getInstance();

export default function Archiwum() {

	let navigate = useNavigate();
	let params = useParams();

	let [artists, setArtists] = useState([]);
	let [currentPage, setCurrentPage] = useState((params.page !== undefined && !isNaN(parseInt(params.page))) ? parseInt(params.page) : 1);
	let [pageCount, setPageCount] = useState(0);
	let [loading, setLoading] = useState(false);
	let perPage = 500;

	useEffect(() => {
		console.log('params:');
		console.log(params);

		console.log(`currentPage = ` + currentPage);
		window.$('#da-slider').cslider();
		const script = document.createElement("script");
		script.src = "/js/custom.js";
		script.async = true;
		document.body.appendChild(script);


		downloadArtistInfo(currentPage);
	}, []);

	async function downloadArtistInfo(currentPage) {
		setArtists([]);
		setLoading(true);

		let result = await alewersyAPI.getArtists((currentPage - 1) * perPage, perPage);
		// console.log(result.result);
		setArtists(result.result);

		let totalPages = Math.ceil(result.total / perPage);
		//console.log(`totalPages: ${totalPages}`);
		setPageCount(totalPages);
		setLoading(false);
	}

	function onPageSelect(page) {
		console.log("selecting page " + page);

		setCurrentPage(page);
		downloadArtistInfo(page);

		if (page == 1) navigate('/archiwum');
		else navigate('/archiwum/' + page);
	}

	return <>
		<Helmet>
			<title>A-Z Baza Artystów | tekst-piosenki.pl</title>
			<meta name="description" content="Znajdziesz tu listę wszystkich twórców muzyki z całego świata. Odkrywaj nowoczesne kawałki i stare hity, remixy, ścieżki dźwiękowe i wiele więcej." />

		</Helmet>

		<section id="content">
			<div className="container">

				<div className="row">
					<div className="span8">
						<article className="single">
							<div className="row">
								<div className="span12">
									<div className="post-image">
										<center>
											<br />
											<h4 className="title"><strong>Baza Artystów</strong></h4>
										</center>
									</div>
									<br />



									<Paginator prelink="/archiwum/" pageCount={pageCount} currentPage={currentPage} onPageSelect={onPageSelect} />

									{loading && <><center> Ładowanie... <Loader /> <br /><br /> </center> </>}
									<center>

										{artists.map((elem) => { return <p key={elem.artist_id} style={{ margin: '0px' }}> <Link to={'/' + elem.artist_urlname}>{beautifyText(elem.artist_name)}</Link> </p> }
										)}
									</center>

									{loading == false && <Paginator prelink="/archiwum/" pageCount={pageCount} currentPage={currentPage} onPageSelect={onPageSelect} />}




								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	</>

}