import { Helmet } from "react-helmet"
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import AlewersyAPI from "../services/AlewersyAPI";
const alewersyAPI = AlewersyAPI.getInstance();

export default function Zaloguj() {

    let navigate = useNavigate();
    let [password, setPassword] = useState("");
    let [username, setUsername] = useState("");

    async function Login() {
        console.log("login");
        if (password.trim() === '' || username.trim() ==='') {
            alert("nazwa użytkownika oraz hasło są wymagane!");
            return;
        }

        let result = await alewersyAPI.loginUser({username: username.trim(), password: password.trim()});
        console.log(result);

        if (result.status === "wrong credentials") {
            alert("błędne hasło i/lub login!");
        } else if (result.status === 'success') {
            localStorage.setItem('userdata', JSON.stringify(result.user) );
            window.dispatchEvent( new Event('storage') );

            navigate('/profil/'+username.trim());
        }
    }

    function searchSongkeyUp(e) {
		if (e.keyCode === 13) {
			Login();
		}
	}

    return <>
        <Helmet>
            <title>Zaloguj | tekst-piosenki.pl</title>
            <meta name="description" content="Zaloguj się na konto w serwisie i ciesz się pełną funkcjonalnością witryny. Zbieraj punkty za najzabawniejsze interpretacje i wspinaj się w rankingu." />
        </Helmet>

        <section style={{ backgroundColor: '#fff2cc' }} id="inner-headline">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="inner-heading">
                            <ul className="breadcrumb">
                                <li><a href="/">Strona Główna</a> <i className="icon-angle-right" /></li>
                                &nbsp;Logowanie
                            </ul>
                            <h2>Zaloguj</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span8">
                        <center>
                            <p style={{ margin: '5px' }}>Adres email lub nazwa użytkownika:</p>
                            <input value={username} 
                                onKeyUp={(e) => { searchSongkeyUp(e) }}
                                onChange={(e)=>{ setUsername(e.target.value); }} 
                                type="text" style={{ width: '50%' }} />
                            <p style={{ margin: '5px' }}>Hasło:</p>
                            <input value={password} 
                                onKeyUp={(e) => { searchSongkeyUp(e) }}
                                onChange={(e)=>{ setPassword(e.target.value); }} 
                                type="password" style={{ width: '50%' }} />
                        </center>
                        <center>
                            <br />
                            <a onClick={(e)=>{
                                Login();
                            }} style={{ backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-key" />&nbsp; ZALOGUJ</a>
                        </center>
                    </div>
                    <div className="span4">
                        <center>
                            <img src="img/logowanie.png" />
                        </center>
                    </div>
                </div>
            </div>
        </section>

    </>

}