import { Helmet } from "react-helmet"
import {useEffect} from "react";

export default function FAQ() {
    useEffect(() => {
        window.$('#da-slider').cslider();

        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = true;
        document.body.appendChild(script);


        window.$('.accordion').on('show', function (e) {

            window.$(e.target).prev('.accordion-heading').find('.accordion-toggle').addClass('active');
            window.$(e.target).prev('.accordion-heading').find('.accordion-toggle i').removeClass('icon-plus');
            window.$(e.target).prev('.accordion-heading').find('.accordion-toggle i').addClass('icon-minus');
        });

        window.$('.accordion').on('hide', function (e) {
            window.$(this).find('.accordion-toggle').not(window.$(e.target)).removeClass('active');
            window.$(this).find('.accordion-toggle i').not(window.$(e.target)).removeClass('icon-minus');
            window.$(this).find('.accordion-toggle i').not(window.$(e.target)).addClass('icon-plus');
        });

    }, []);


    return <>
        <Helmet>
            <title>FAQ | tekst-piosenki.pl</title>
            <meta name="description" content="Chcesz o coś zapytać? Zanim wyślesz do nas wiadomość sprawdź FAQ, być może na tej stronie znajdziesz swoje pytanie i gotową odpowiedź." />
        </Helmet>
        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <h4 className="title"><strong>Pytania</strong> ogólne</h4>
                        {/* start: Accordion */}
                        <div className="accordion" id="accordion2">
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle active" data-toggle="collapse" data-parent="#accordion2" href="#collapseOne">
                                        <i className="icon-minus" /> Jak to działa? </a>
                                </div>
                                <div id="collapseOne" className="accordion-body collapse in">
                                    <div className="accordion-inner">
                                        Witryna umożliwia czytanie i dodawanie tekstów piosenek oraz ich interpretację w dość nietypowy sposób. Użytkownicy mają za zadanie publikować śmieszne, nieprawdziwe i sarkastyczne interpretacje wybranych wersów piosenek. Za najzabawniejsze adnotacje są oni nagradzani punktami przyznawanymi przez osoby odwiedzające witrynę i dzięki temu mogą rywalizować ze sobą w rankingu. Ponadto użytkownicy mogą dodawać tłumaczenia tekstów, pisać komentarze, dodawać remixy, covery i instrumentale. Serwis umożliwia również zapętlanie piosenek lub ich wybranych fragmentów, znalezienie sklepów z ofertą zakupu wybranego utworu, wyszukiwanie albumów ulubionego wykonawcy oraz oglądanie oficjalnych klipów.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href="#collapseTwo">
                                        <i className="icon-plus" /> Jak dodać interpretację? </a>
                                </div>
                                <div id="collapseTwo" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        W pierwszej kolejności kliknij przycisk "DODAJ INTERPRETACJĘ", a następnie zaznacz konkretne wersy piosenki, które chcesz zinterpretować. Wpisz tekst, ukończ wyzwanie captcha i zatwierdź adnotację. Obowiązuje zakaz wklejania linków w treści dodawanych materiałów. Pamiętaj o tym, że w zabawie chodzi o dodanie humorystycznej adnotacji okraszonej ironią, dowcipem i finezją. Liczymy na twoją kreatywność, poczucie humoru i bujną wyobraźnię. Nie dodawaj prawdziwego znaczenia wersów piosenek.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href="#collapseFive">
                                        <i className="icon-plus" /> Jak działa ranking? </a>
                                </div>
                                <div id="collapseFive" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        Osoby odwiedzające stronę mogą w prosty sposób oceniać humorystyczne interpretacje dodane przez użytkowników. Wszystkie głosy są automatycznie zliczane, a każdy zarejestrowany użytkownik, którego publikacje otrzymały co najmniej jeden głos, trafia na listę rankingową. Warto w tym miejscu dodać, że nawet osoby nieposiadające konta w naszym serwisie mogą interpretować teksty, jednak do rankingu trafiają wyłącznie zarejestrowane profile.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href="#collapseThree">
                                        <i className="icon-plus" /> Jak dodać nową piosenkę? </a>
                                </div>
                                <div id="collapseThree" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        Kliknij przycisk "Dodaj Piosenkę" w górnym menu, a następnie wypełnij formularz, podając nazwę artysty, tytuł piosenki oraz jej tekst. Na końcu ukończ wyzwanie captcha i zatwierdź piosenkę. Pamiętaj o tym, że wklejanie linków w treści jest surowo zabronione. Po dodaniu utworu trafi on do naszej bazy, a wtedy każdy użytkownik będzie mógł go wyszukać i interpretować, a także dodawać tłumaczenia, komentarze, remixy, instrumentale oraz covery. Dodatkowo nasze skrypty automatycznie znajdą w serwisie YouTube video odpowiadające nazwie piosenki i wyświetlą je na stronę, a dzięki temu oprócz możliwości przeczytania tekstu będzie można również włączyć odsłuch audio.
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end: Accordion */}
                        <div className="blankline30" />
                        <div className="solidline" />
                        <div className="blankline20" />
                        <h4 className="title"><strong>Kwestie</strong> techniczne</h4>
                        {/* start: Accordion */}
                        <div className="accordion" id="accordion3">
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle active" data-toggle="collapse" data-parent="#accordion3" href="#billing-collapseOne">
                                        <i className="icon-minus" /> Do czego służy zapętlanie video? </a>
                                </div>
                                <div id="billing-collapseOne" className="accordion-body collapse in">
                                    <div className="accordion-inner">
                                        Zaimplementowana przez nas funkcja zapętlania video pochodzącego z serwisu YouTube może okazać się pomocna w wielu przypadkach. Najczęściej użytkownicy korzystają z niej gdy bardzo spodobał im się fragment ulubionej piosenki lub nawet cały utwór i chcą go odtwarzać w pętli. Niektórzy pragną nauczyć się na pamięć tekstu wybranej piosenki i najłatwiej przychodzi im to właśnie zapętlając ją w nieskończoność. Inni z kolei wykorzystują zapętlanie w celach edukacyjnych, m.in. do utrwalenia sobie treści zawartych we fragmentach utworu lub filmu dokumentalnego.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion3" href="#billing-collapseTwo">
                                        <i className="icon-plus" /> Jak dodać cover lub remix? </a>
                                </div>
                                <div id="billing-collapseTwo" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        Nagrałeś cover piosenki i chciałbyś się nim podzielić z szerszym gronem? Po pierwsze znajdź oryginalny utwór korzystając z naszej wyszukiwarki. Po kliknięciu przycisku "DODAJ COVER" pojawi się okienko, w którym należy wkleić link do covera, pod warunkiem że znajduje się on w serwisie YouTube. Następnie ukończ wyzwanie captcha i zatwierdź dodanie materiału. Postępuj analogicznie jeśli zechcesz dodać remix lub instrumental.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion3" href="#billing-collapseThree">
                                        <i className="icon-plus" /> Czy rejestracja jest konieczna? </a>
                                </div>
                                <div id="billing-collapseThree" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        Każda osoba odwiedzająca naszą witrynę może korzystać ze wszystkich funkcji serwisu za darmo bez konieczności rejestracji. Wyjątkiem jest chęć znalezienia się w rankingu użytkowników, którzy dodali najwyżej ocenione śmieszne interpretacje. Jeśli chcesz wziąć udział w rywalizacji o miano najlepszego lirycznego trolla, zachęcamy do założenia konta. W przeciwnym razie wszystkie twoje interpretacje będą anonimowe.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-group">
                                <div className="accordion-heading">
                                    <a style={{ backgroundColor: '#fff2cc' }} className="accordion-toggle" data-toggle="collapse" data-parent="#accordion3" href="#billing-collapseFour">
                                        <i className="icon-plus" /> Jak zgłosić problem? </a>
                                </div>
                                <div id="billing-collapseFour" className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        W przypadku gdy znalazłeś błąd na stronie, masz dla nas radę, sugestię lub propozycję, polecamy ci napisać do nas wiadomość email na adres alewersy.pl@gmail.com i opisać sprawę jak najbardziej szczegółowo. Jest to najszybsza forma kontaktu z administracją. Na obecną chwilę nie oferujemy kontaktu telefonicznego. Na nadesłane wiadomości zwykle odpowiadamy w ciągu 24 godzin.
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end: Accordion */}
                    </div>
                </div>
            </div>
        </section>
    </>

}