import Offcanvas from 'react-bootstrap/Offcanvas';
import {Button} from 'react-bootstrap';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

export default function CookiesInfo ({ name, ...props }) {
  const navigate = useNavigate();

  const [show, setShow] = useState( window.localStorage.cookiesAccepted === undefined );

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
    
      <Offcanvas show={show} placement={"bottom"} backdrop={false}  scroll={true} onHide={handleClose} {...props}>
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
        <div style={{position: 'relative',display: 'inline-block', width: '100%'}}>
          <span style={{lineHeight: "40px"}}>Używamy cookies i podobnych technologii m.in. w celach: świadczenia usług, reklam, statystyk. Korzystanie z witryny bez zmiany ustawień Twojej przeglądarki oznacza, że będą one umieszczane w Twoim urządzeniu końcowym.</span>
          
          
          <Button variant="dark" style={{float: 'right  '}}
          
          onClick={(e)=> {
              window.localStorage.cookiesAccepted = true;
              setShow(false);
          }}
          > Akceptuję </Button>
          <Button variant="dark" style={{float: 'right', marginRight: '5px'}}
            onClick={(e)=> {
              navigate('/prywatnosc');
            }}
          > Czytaj więcej</Button>
          
          </div>

         

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}