import { Helmet } from "react-helmet";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "./Loader";
import FakeA from "./fakeA";
import AlewersyAPI from "../services/AlewersyAPI";
import AnnotationEditor from "./AnnotationEditor";
import LoopControl from "./LoopControl";
import YoutubePlayer from "./YoutubePlayer";
import ReCAPTCHA from "react-google-recaptcha";
import "../css/Result.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import VideoTab from "./VideoTab";
import languages from "./languageList.json";
import { beautifyText } from "./Misc";
import Ad from "./Ad";

import { StickyContainer, Sticky } from "react-sticky";

let languagesList = [];
for (let lang in languages) {
    languagesList.push({
        code: lang,
        ...languages[lang],
    });
}
// console.log(languagesList);

let wwwregexp =
    /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;
const alewersyAPI = AlewersyAPI.getInstance();

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

function LanguageComboBox(props) {
    return (
        <Autocomplete
            id="language-combo-box"
            value={props.value}
            onChange={props.onChange}
            options={languagesList}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
                <TextField {...params} label="wybierz język" variant="outlined" />
            )}
        />
    );
}

function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}

function openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
}

export default function Result(props) {
    let [userData, setUserdata] = useState(
        localStorage.userdata !== undefined ? JSON.parse(localStorage.userdata) : null,
    );

    let forceUpdate = useForceUpdate();
    let [addingAnnotation, setAddingAnnotation] = useState(false);
    let [addingComment, setAddingComment] = useState(false);
    let [commentAuthor, setCommentAuthor] = useState("");
    let [commentText, setCommentText] = useState("");
    let [addingTranslation, setAddingTranslation] = useState(false);
    let [annotationsShowed, setAnnotationsShowed] = useState([]);
    let [annotationLineSelected, setAnnotationLineSelected] = useState(-1);
    let [song, setSong] = useState(null);
    let [recentAnnotations, setRecentAnnotations] = useState([]);
    let [recentSorted, setRecentSorted] = useState([]);
    let [translationLanguage, setTranslationLanguage] = useState({});
    let [translationText, setTranslationText] = useState("");
    let [songTranslations, setSongTranslations] = useState(null);
    let [selectedTranslation, setSelectedTranslation] = useState({});
    let [height, setHeight] = useState(0);

    let [songTranslationsByCode, setSongTranslationsByCode] = useState({});
    let [uniqueLanguages, setUniqueLanguages] = useState([]);
    let [captchaValues, setCaptchaValues] = useState({});
    let [selectedLanguage, setSelectedLanguage] = useState(null);
    let [elementsByType, setElementsByType] = useState({ 0: [], 1: [], 2: [] });
    let [youTubeId, setYouTubeId] = useState(null);
    let [comments, setSongComments] = useState([]);

    let [elementsRefreshTimestamp, setElementsRefreshTimestamp] = useState({
        time: new Date().getTime(),
    });

    const handleRect = useCallback((node) => {
        console.log(node?.getBoundingClientRect().height);
        setHeight(node?.getBoundingClientRect().height);
    }, []);

    function onNewElement(element) {
        // console.log('onNewElement');
        // console.log(element);
        elementsByType[element.element_type].push(element);
        setElementsByType({ ...elementsByType });
    }

    async function voteComment(comment) {
        console.log("voting comment id " + comment.comment_id);
        let newpoints = 0;

        if (currentUserData !== null) {
            //uzytkownik zalogowany
            if (userCommentVotes.indexOf(comment.comment_id) !== -1) {
                //already voted up
                console.log("voting comment down [by logged user]");
                let res = await alewersyAPI.voteUpComment(
                    comment.comment_id,
                    false,
                    currentUserData.username,
                );
                newpoints = res.response.comment_points;

                let idx = userCommentVotes.indexOf(comment.comment_id);
                userCommentVotes.splice(idx, 1);
                setUserCommentVotes([...userCommentVotes]);
            } else {
                console.log("voting comment up [by logged user]");
                let res = await alewersyAPI.voteUpComment(
                    comment.comment_id,
                    true,
                    currentUserData.username,
                );
                newpoints = res.response.comment_points;

                userCommentVotes.push(comment.comment_id);
                setUserCommentVotes([...userCommentVotes]);
            }
        } else {
            if (window.localStorage["comment_voted_up" + comment.comment_id] !== undefined) {
                //already voted up
                //console.log("voting down");
                window.localStorage.removeItem("comment_voted_up" + comment.comment_id);
                let res = await alewersyAPI.voteUpComment(comment.comment_id, false);
                newpoints = res.response.comment_points;
            } else {
                //console.log("voting up");
                window.localStorage["comment_voted_up" + comment.comment_id] = true;
                let res = await alewersyAPI.voteUpComment(comment.comment_id, true);
                newpoints = res.response.comment_points;
            }
        }

        for (let comm of comments) {
            if (comm.comment_id == comment.comment_id) {
                comm.comment_points = newpoints;
            }
        }

        comments.sort(sortComments);
        console.log(comments);
        setSongComments([...comments]);
    }

    function onVideoSelect(videoId) {
        // console.log(videoId);
        document.getElementById("featured").scrollIntoView(true);
        setYouTubeId(videoId);
    }

    async function downloadRecentAnnotations() {
        let annotations = await alewersyAPI.getRecentAnnotations();
        // console.log(annotations);
        setRecentAnnotations(annotations);
    }

    function sortComments(a, b) {
        if (a.comment_points > b.comment_points) return -1;
        if (a.comment_points < b.comment_points) return 1;

        if (a.comment_points == b.comment_points) {
            if (a.comment_id > b.comment_id) return 1;
            if (a.comment_id < b.comment_id) return -1;
            if (a.comment_id == b.comment_id) return 0;
        }
    }

    async function downloadSongComments() {
        let comments = await alewersyAPI.getSongComments(props.song.song_id);
        comments.sort(sortComments);

        setSongComments(comments);
    }

    async function downloadSongElements() {
        let elements = await alewersyAPI.getSongElement(props.song.song_id);
        // setSongElements(translations.translations);

        //console.log(elements);

        let elementsByType = { 0: [], 1: [], 2: [] };

        elements.forEach((elem, idx) => {
            if (elementsByType[elem.element_type] === undefined) {
                elementsByType[elem.element_type] = [];
            }

            elementsByType[elem.element_type].push(elem);
        });

        //console.log(elementsByType);

        setElementsByType(elementsByType);
    }

    async function downloadSongTranslations() {
        let translations = await alewersyAPI.getSongTranslations(props.song.song_id);
        setSongTranslations(translations.translations);

        let translationLanguages = {};
        let selectedTranslation = {};

        songTranslationsByCode = {};
        for (let translation of translations.translations) {
            translationLanguages[translation.translation_code] = 1;

            if (songTranslationsByCode[translation.translation_code] === undefined)
                songTranslationsByCode[translation.translation_code] = [];

            songTranslationsByCode[translation.translation_code].push(translation);
            selectedTranslation[translation.translation_code] = 0;
        }

        translationLanguages = Object.keys(translationLanguages);
        setSelectedLanguage(translationLanguages[0]);

        // console.log(`unique languages: ${translationLanguages}`);

        setUniqueLanguages(translationLanguages);
        setSelectedTranslation(selectedTranslation);

        //console.log(songTranslationsByCode);
        setSongTranslationsByCode(songTranslationsByCode);
    }

    function clearCaptchaVal(type) {
        console.log(`clearing captcha: type: ${type}`);

        if (captchaValues[type]) delete captchaValues[type];
        setCaptchaValues({ ...captchaValues });
        console.log(captchaValues);
    }

    function onCaptchaChange(type, value) {
        console.log(`captcha change: type: ${type}`);

        if (value === null) delete captchaValues[type];
        else captchaValues[type] = value;
        setCaptchaValues({ ...captchaValues });

        console.log(captchaValues);
    }

    //     let body = {
    //     nick, tresc, article_id: this.state.articleId,
    //     'g-recaptcha-response': recaptcha
    // };

    useEffect(() => {
        setYouTubeId(props.song.youtube.id);

        setSong(props.song);
        downloadRecentAnnotations();
        downloadSongTranslations();
        downloadSongElements();
        downloadSongComments();
    }, [props.song]);

    useEffect(() => {
        //console.log("got recent annotations!" + Object.keys(recentAnnotations));
        if (recentAnnotations.ever === undefined) return;

        let recentSorted = JSON.parse(JSON.stringify(recentAnnotations.last));
        recentSorted.sort((a, b) => {
            if (a.annotation_timestamp > b.annotation_timestamp) return -1;
            if (b.annotation_timestamp > a.annotation_timestamp) return 1;
            return 0;
        });

        // lastWeekS = lastWeekS.slice(0, 8);

        let myRecentSorted = getUniqueAnnotations(recentSorted);
        myRecentSorted = myRecentSorted.slice(0, 12);

        setRecentSorted(myRecentSorted);
    }, [recentAnnotations]);

    let location = useLocation();
    let params = useParams();
    let [youtubePlayerOptions, setYoutubePlayerOptions] = useState({
        state: "stopped",
        shouldLoop: false,
    });

    let [annotationText, setAnnotationText] = useState("");
    let [annotationAuthor, setAnnotationAuthor] = useState("");
    let [linesSelected, setLinesSelected] = useState([]);
    let [userVotes, setUserVotes] = useState([]);
    let [userCommentVotes, setUserCommentVotes] = useState([]);

    function onChangeAnnotationSelection(newLines) {
        setLinesSelected(newLines);
    }
    //console.log(location);
    //console.log(params.text);

    let [pageData, setPageData] = useState(null);
    let [currentUserData, setCurrentUserData] = useState(null);

    // useEffect(() => {
    //     console.log(currentUserData);
    //     console.log("currentuserdata is set!");

    // }, [currentUserData]);

    useEffect(() => {
        // console.log("useEffect");
        // console.log(localStorage.userdata);
        if (localStorage.userdata !== undefined) {
            (async () => {
                let result = await alewersyAPI.getUser(JSON.parse(localStorage.userdata).username);
                // console.log(result);

                if (result[0].user_votes === "") result[0].user_votes = [];
                else {
                    result[0].user_votes = JSON.parse(result[0].user_votes);
                }

                if (result[0].user_comment_votes === "") result[0].user_comment_votes = [];
                else {
                    result[0].user_comment_votes = JSON.parse(result[0].user_comment_votes);
                }

                setUserVotes(result[0].user_votes);
                setUserCommentVotes(result[0].user_comment_votes);

                setCurrentUserData({
                    username: result[0].user_name,
                    email: result[0].user_mail,
                    ...result[0],
                });
            })();
        }

        window.done = false;
        //console.log('downloading data from api: ' + params.text);

        window.$("#da-slider").cslider();
        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://static.addtoany.com/menu/page.js";
        script2.async = true;
        document.body.appendChild(script2);

        if (document.getElementById("defaultOpen")) document.getElementById("defaultOpen").click();

        //openCity({}, 'lyrics');
        //downloadData();

        setPageData(props.song);
        // sortAnnotations();
    }, []);

    useEffect(() => {
        if (document.getElementById("defaultOpen")) document.getElementById("defaultOpen").click();
    }, [pageData]);

    useEffect(() => {
        if (song) {
            if (window.done) return;
            if (song.annotation.length > 0) {
                selectAnnotatedLine(song.annotation[0].annotation_lines[0]);
            }
            window.done = true;
        }
    }, [song]);

    // async function downloadData() {
    //     let result = await alewersyAPI.identify(params.text);
    //     console.log('founded: ');
    //     console.log(result);
    //     setPageData(result);
    // }

    if (pageData === null) return <></>;

    let stickyStyle = { float: "right", width: "40%" };
    stickyStyle.height = `${height}px`;

    if (annotationsShowed.length === 0) {
        stickyStyle.display = "none";
    }

    let song_lyrics = song.song_lyrics;

    let elems = song_lyrics.split("\n");

    try {
        if (
            elems[0].search(new RegExp("[\"|”|'|“]" + song.song_name + "[\"|”|'|“]", "gi")) !== -1
        ) {
            //mamy tytuł w pierwszej linijce
            song_lyrics = elems.slice(1).join("\n").trim();
        }
    } catch (err) {}

    song_lyrics = song_lyrics.replace(/\n/g, "<br/>");

    song_lyrics = song_lyrics.replace(/\[verse/g, "[Verse");
    song_lyrics = song_lyrics.replace(/\[pre/g, "[Pre");
    song_lyrics = song_lyrics.replace(/\[chorus/g, "[Chorus");
    song_lyrics = song_lyrics.replace(/\[bridge/g, "[Bridge");
    song_lyrics = song_lyrics.replace(/\[outro/g, "[Outro");
    song_lyrics = song_lyrics.replace(/-ss/g, "ass");
    song_lyrics = song_lyrics.replace(/-cker/g, "ucker");
    song_lyrics = song_lyrics.replace(/t-t/g, "tit");
    song_lyrics = song_lyrics.replace(/h-ll/g, "hell");

    let queryTermOriginal = song.artist.artist_name + " " + song.song_name;
    let queryTerm = (song.artist.artist_name + " " + song.song_name).replace(/\s+/g, "+");
    let queryTerm20 = (song.artist.artist_name + " " + song.song_name).replace(/\s+/g, "%20");
    let queryTerm3 = (song.artist.artist_name + " " + song.song_name).replace(/\s+/g, "-");

    let youtube = song.youtube;

    if (Object.keys(youtube).length === 0) {
        // no youtube data
        youtube = {
            id: "",
            thumbnails: {
                default: {
                    url: "",
                },
                medium: {
                    url: "",
                },
            },
        };
    }

    let youtubeid = youtube.id;
    let ytlink = `https://www.youtube.com/embed/${youtubeid}?playlist=${youtubeid}&loop=1&end=20&start=15`;
    //console.log(ytlink);
    //?version=3&loop=1&playlist=VIDEO_ID

    function loopControlOnChange(state, from, to) {
        if (state === "loop") {
            console.log("will play");

            setYoutubePlayerOptions({
                state: "playing",
                start: from,
                end: to,
                shouldLoop: true,
            });
        } else if (state === "stop") {
            console.log("will now stop");

            setYoutubePlayerOptions({
                state: "stopped",
                start: 0,
                end: 999999,
                shouldLoop: false,
            });
        }
    }

    async function addTranslation() {
        if (userData === null && captchaValues.translation === undefined) {
            alert("Rozwiąż captchę.");
            return;
        }

        if (translationLanguage.code === undefined) {
            alert("Wybierz język tłumaczenia.");
            return;
        }

        if (translationText.trim() === "") {
            alert("Tekst tłumaczenia jest obowiązkowy.");
            return;
        }

        //console.log("adding annotation to song " + song.song_id);

        let result = await alewersyAPI.addTranslation({
            code: translationLanguage.code,
            song_id: song.song_id,
            translationText,
            "g-recaptcha-response": captchaValues.translation,
        });

        console.log(result);
        if (result.result === "error") {
            alert(result.error);
            return;
        }

        let newTranslation = {
            translation_code: translationLanguage.code,
            translation_text: translationText,
            translation_timestamp: parseInt(new Date().getTime() / 1000),
            translation_id: result.insertId,
        };

        // console.log(newAnnotation);

        // song.annotation.push(newAnnotation);
        // selectAnnotatedLine(linesSelected.sort()[0]);
        setAddingTranslation(false);
        onCaptchaChange("translation", null);

        if (uniqueLanguages.indexOf(translationLanguage.code) === -1) {
            uniqueLanguages.push(translationLanguage.code);

            console.log("new uniqueLanguages: " + uniqueLanguages);
        }

        setSongTranslations([newTranslation, ...songTranslations]);

        if (songTranslationsByCode[translationLanguage.code] === undefined) {
            songTranslationsByCode[translationLanguage.code] = [];
        }

        songTranslationsByCode[translationLanguage.code].push(newTranslation);
        setSongTranslationsByCode({ ...songTranslationsByCode });
        console.log("new songTranslationsByCode");
        console.log(songTranslationsByCode[translationLanguage.code]);

        selectedTranslation[translationLanguage.code] =
            songTranslationsByCode[translationLanguage.code].length - 1;
        setSelectedTranslation({ ...selectedTranslation });

        setUniqueLanguages([...uniqueLanguages]);
        setSelectedLanguage(translationLanguage.code);
    }

    async function addCommentDODAJ() {
        if (userData === null && captchaValues.comment === undefined) {
            alert("Rozwiąż captchę.");
            return;
        }

        let name = userData === null ? "Anonimowy" : userData.username;

        // let name = commentAuthor.trim();
        // if (name === '') {
        //     if (window.confirm("You didn't enter your name. If you'll continue comment will be added as 'Anonymous'. Continue?")) {
        //         name = 'Anonymous';
        //     } else {
        //         return;
        //     }
        // }

        if (commentText.trim() === "") {
            alert("Treść komentarza jest obowiązkowa!");
            return;
        }

        console.log("adding comment to song " + song.song_id);

        let result = await alewersyAPI.addComment({
            song_id: props.song.song_id,
            author: name,
            text: commentText,
            "g-recaptcha-response": captchaValues.comment,
        });

        console.log(result);

        if (result.result === "error") {
            alert(result.error);
            return;
        }

        // let maxId = -1;

        // comments.forEach((elem) => {
        //     if (elem.comment_id> maxId) maxId = elem.comment_id;
        // });

        let newComment = {
            comment_id: result.response.results.insertId,
            song_id: props.song.song_id,
            comment_author: name,
            comment_text: commentText,
            comment_points: 0,
        };

        comments.push(newComment);
        comments.sort(sortComments);

        console.log(comments);

        setCommentText([...comments]);

        // song.annotation.push(newAnnotation);
        // selectAnnotatedLine(linesSelected.sort()[0]);
        setAddingComment(false);
        onCaptchaChange("comment", null);
    }

    async function deleteAnnotation(annotation_id) {
        if (window.confirm("Czy na pewno chcesz usunąć tę interpretację?")) {
            let result = await alewersyAPI.deleteAnnotation(annotation_id);
            console.log(result);

            // navigate('/');
            window.location.reload();
        }
    }

    async function addAnnotation() {
        if (userData === null && captchaValues.annotation === undefined) {
            alert("Rozwiąż captchę.");
            return;
        }

        let name = userData === null ? "Anonimowy" : userData.username;

        if (linesSelected.length === 0) {
            alert("Musisz wybrać conajmniej jedną linię!");
            return;
        }

        // if (name === '') {

        //     if (window.confirm("You didn't enter your name. If you'll continue annotation will be added as 'Anonymous'. Continue?")) {
        //         name = 'Anonymous';
        //     } else {
        //         return;
        //     }

        // }

        if (annotationText.trim() === "") {
            alert("Tekst interpretacji jest obowiązkowy!");
            return;
        }

        // console.log("adding annotation to song " + song.song_id);

        let result = await alewersyAPI.addAnnotation(
            song.song_id,
            name,
            annotationText,
            linesSelected,
            captchaValues.annotation,
        );

        console.log(result);
        if (result.result === "error") {
            alert(result.error);
            return;
        }

        let newAnnotation = {
            annotation_id: result.response.results.insertId,
            annotation_timestamp: 1617277782,
            annotation_author: name,
            annotation_text: annotationText,
            annotation_points: 0,
            //annotation_song_id: 155151,
            annotation_lines: linesSelected,
        };

        console.log(newAnnotation);

        song.annotation.push(newAnnotation);
        selectAnnotatedLine(linesSelected.sort()[0]);
        setAddingAnnotation(false);

        onCaptchaChange("annotation", null);
    }

    function selectAnnotatedLine(line) {
        console.log("selecting annotations for line: " + line);

        let annotationsShowed = [];
        for (let annotation of song.annotation) {
            if (annotation.annotation_lines.indexOf(line) !== -1) {
                annotationsShowed.push(annotation.annotation_id);
            }
        }

        //console.log(annotationsShowed);

        setAnnotationLineSelected(line);
        setAnnotationsShowed(annotationsShowed);
    }

    function addNewAnnotation(scroll = false) {
        if (scroll) document.getElementById("content").scrollIntoView(true);

        document.getElementById("defaultOpen").click();

        setAddingAnnotation(true);
        setLinesSelected([]);
        setAnnotationAuthor("");
        setAnnotationText("");
    }

    function addNewComment() {
        setAddingComment(true);
        clearCaptchaVal("comment", null);

        setCommentAuthor("");
        setCommentText("");
    }

    function addNewTranslation(scroll = false) {
        if (scroll) document.getElementById("lyrics").scrollIntoView(true);
        setAddingTranslation(true);

        setTranslationText("");
        setTranslationLanguage(null);
        clearCaptchaVal("translation", null);

        // setLinesSelected([]);
        // setAnnotationAuthor("");
        // setAnnotationText("");
    }

    function sortAnnotations() {
        song.annotation = song.annotation.sort((a, b) => {
            if (a.annotation_points > b.annotation_points) return -1;
            if (a.annotation_points < b.annotation_points) return 1;
            return 0;
        });
        song.annotation = [...song.annotation];
        setSong({ ...song });
    }

    async function voteUp(annotation) {
        console.log("voting annotation id " + annotation.annotation_id);

        let newpoints = 0;

        // console.log(currentUserData);

        if (currentUserData !== null) {
            //uzytkownik zalogowany
            if (userVotes.indexOf(annotation.annotation_id) !== -1) {
                //already voted up
                console.log("voting down [by logged user]");
                let res = await alewersyAPI.voteUp(
                    annotation.annotation_id,
                    false,
                    currentUserData.username,
                );
                newpoints = res.response.annotation_points;

                let idx = userVotes.indexOf(annotation.annotation_id);
                userVotes.splice(idx, 1);
                setUserVotes([...userVotes]);
            } else {
                console.log("voting up [by logged user]");
                let res = await alewersyAPI.voteUp(
                    annotation.annotation_id,
                    true,
                    currentUserData.username,
                );
                newpoints = res.response.annotation_points;

                userVotes.push(annotation.annotation_id);
                setUserVotes([...userVotes]);
            }
        } else {
            //anonimowy

            if (window.localStorage["voted_up" + annotation.annotation_id] !== undefined) {
                //already voted up
                console.log("voting down");
                window.localStorage.removeItem("voted_up" + annotation.annotation_id);
                let res = await alewersyAPI.voteUp(annotation.annotation_id, false);
                newpoints = res.response.annotation_points;
            } else {
                console.log("voting up");
                window.localStorage["voted_up" + annotation.annotation_id] = true;
                let res = await alewersyAPI.voteUp(annotation.annotation_id, true);
                newpoints = res.response.annotation_points;
            }
        }

        for (let annot of song.annotation) {
            if (annot.annotation_id == annotation.annotation_id) {
                annot.annotation_points = newpoints;
            }
        }

        sortAnnotations();

        //forceUpdate();
    }

    let lines = song_lyrics.split(/<br\/>/g);

    let thumbnailUrl = youtube.thumbnails.medium.url.replace(/https:\/\//g, "/api/ytproxy/");
    if (thumbnailUrl === "") thumbnailUrl = "/img/placeholder.jpg";

    let leftSideStyle = { float: "left", width: "100%" };

    if (annotationsShowed.length > 0) {
        leftSideStyle = { float: "left", width: "60%" };
    }

    return (
        <>
            <Helmet>
                <title>
                    {beautifyText(song.artist.artist_name)} {beautifyText(song.song_name)} Tekst
                    Piosenki - Śmieszne Interpretacje
                </title>
                <meta
                    name="description"
                    content={
                        beautifyText(song.artist.artist_name + " " + song.song_name) +
                        " tekst piosenki z humorystyczną interpretacją użytkowników. Podaj nieprawdziwe i zabawne znaczenie wybranych wersów."
                    }
                />
            </Helmet>

            {/* section featured */}
            <section id="featured" style={{ backgroundColor: "#000" }}>
                {/* opt in area for landing page */}
                <div className="landing">
                    <div className="container">
                        <div className="row">
                            <div className="span6">
                                <h2>
                                    <strong>
                                        {beautifyText(song.artist.artist_name)}{" "}
                                        <span className="colored">
                                            {beautifyText(song.song_name)}
                                        </span>
                                    </strong>
                                </h2>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href={"https://open.spotify.com/search/" + queryTerm20}
                                >
                                    <img style={{ marginRight: "2px" }} src="img/spotify.jpg" />
                                </a>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href={
                                        "https://www.youtube.com/results?search_query=" +
                                        encodeURIComponent(queryTermOriginal)
                                    }
                                >
                                    <img src="img/youtube.jpg" />
                                </a>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href={
                                        "https://www.amazon.com/s?k=" +
                                        encodeURIComponent(queryTermOriginal)
                                    }
                                >
                                    <img src="img/amazon.png" />
                                </a>
                                <a
                                    target="_blank"
                                    rel="nofollow"
                                    href={
                                        "https://www.apple.com/us/search/" +
                                        queryTerm3 +
                                        "?src=serp"
                                    }
                                >
                                    <img src="img/itunes.png" />
                                </a>
                                <br /> <br />
                                <p>
                                    <font color="white">
                                        {beautifyText(song.artist.artist_name)}{" "}
                                        {beautifyText(song.song_name)} tekst piosenki z
                                        humorystyczną interpretacją. Podaj zabawne i nieprawdziwe
                                        znaczenie wybranych wersów.
                                    </font>
                                </p>
                                {/* AddToAny BEGIN */}
                                {/* AddToAny END */}
                                <div
                                    style={{ float: "right" }}
                                    className="a2a_kit a2a_kit_size_28 a2a_default_style"
                                >
                                    <a href="https://wp.pl" className="a2a_button_facebook">
                                        {" "}
                                    </a>
                                    <a href="https://wp.pl" className="a2a_button_twitter">
                                        {" "}
                                    </a>
                                    <a
                                        href="https://wp.pl"
                                        className="a2a_button_facebook_messenger"
                                    >
                                        {" "}
                                    </a>
                                </div>
                                <img
                                    className="clickable"
                                    onClick={(e) => {
                                        setYouTubeId(props.song.youtube.id);
                                    }}
                                    alt=""
                                    style={{ width: "130px", marginBottom: "8px" }}
                                    src={thumbnailUrl}
                                    alt={
                                        beautifyText(song.artist.artist_name) +
                                        " " +
                                        beautifyText(song.song_name) +
                                        " tekst"
                                    }
                                />
                                <br />
                                {/* <img style={{ float: 'right' }} src="img/loop.PNG" /> */}
                                {youtube.id !== "" && (
                                    <LoopControl onChange={loopControlOnChange} />
                                )}
                                <small>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: beautifyText(youtube.title),
                                        }}
                                    />
                                </small>
                                <br />
                                <font color="white">
                                    Autor:{" "}
                                    <FakeA href={"/" + song.artist.artist_urlname}>
                                        {beautifyText(song.artist.artist_name)}
                                    </FakeA>
                                </font>{" "}
                                <br />
                                <font color="white">Tytuł:</font>{" "}
                                <span>{beautifyText(song.song_name)}</span>
                            </div>
                            <div className="span6">
                                <div className="video-container">
                                    {/* <iframe width={560} height={315} src="https://www.youtube.com/embed/WcKSPyhrGWc" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> */}

                                    {youTubeId && (
                                        <YoutubePlayer
                                            options={youtubePlayerOptions}
                                            videoId={youTubeId}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /section featured */}

            <br />
            <Ad adtype="1" />

            <section id="content">
                <div className="container">
                    {userData &&
                        (userData.username === "reinhold" || userData.username === "admin") && (
                            <button
                                onClick={async () => {
                                    if (window.confirm("Na pewno usunąć piosenkę?")) {
                                        let res = await alewersyAPI.deleteSong(song.song_id);
                                        if (res.status === "error") {
                                            alert(res.error);
                                        } else {
                                            window.location.href = "/";
                                        }
                                    }
                                }}
                                className="btn btn-danger"
                            >
                                USUŃ PIOSENKĘ
                            </button>
                        )}

                    <div className="row">
                        <a
                            onClick={(e) => {
                                openCity(e, "tekst");
                                setAddingAnnotation(false);
                            }}
                            className="btn btn-primary tablinks"
                            id="defaultOpen"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#f0b400",
                                border: "1px solid #f0b400",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Tekst Piosenki{" "}
                        </a>
                        <a
                            onClick={(e) => {
                                openCity(e, "tłumaczenie");
                                setAddingTranslation(false);
                            }}
                            className="btn btn-danger tablinks"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#1ac6ff",
                                border: "1px solid #1ac6ff",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Tłumaczenie ({songTranslations && songTranslations.length})
                        </a>
                        <a
                            onClick={(e) => {
                                openCity(e, "komentarze");
                                setAddingComment(false);
                            }}
                            className="btn btn-inverse tablinks"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#00cc00",
                                border: "1px solid #00cc00",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Komentarze ({comments.length})
                        </a>
                        <a
                            onClick={(e) => {
                                openCity(e, "remixy");
                                setElementsRefreshTimestamp({ time: new Date().getTime() });
                            }}
                            className="btn btn-info tablinks"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#f0b400",
                                border: "1px solid #f0b400",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Remixy ({elementsByType[0] && elementsByType[0].length})
                        </a>
                        <a
                            onClick={(e) => {
                                openCity(e, "covery");
                                setElementsRefreshTimestamp({ time: new Date().getTime() });
                            }}
                            className="btn btn-success tablinks"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#1ac6ff",
                                border: "1px solid #1ac6ff",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Covery ({elementsByType[1] && elementsByType[1].length})
                        </a>
                        <a
                            onClick={(e) => {
                                openCity(e, "instrumentale");
                                setElementsRefreshTimestamp({ time: new Date().getTime() });
                            }}
                            className="btn btn-warning tablinks"
                            style={{
                                marginRight: "3px",
                                backgroundColor: "#00cc00",
                                border: "1px solid #00cc00",
                                borderRadius: "3px",
                                marginTop: "4px",
                            }}
                        >
                            Instrumentale ({elementsByType[2] && elementsByType[2].length})
                        </a>

                        <div id="tekst" className="tabcontent">
                            {/* <small>FALLING TEKST</small>
                        <br />
                        <a href="OKIENKO DO DODAWANIA TEKSTU" style={{ float: 'right', backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-edit" />&nbsp; DODAJ INTERPRETACJĘ</a>
                        <div className="clearfix"> </div>
                        <div style={{ float: 'left', width: '60%' }}>
                            <br />
                            <p style={{ color: 'black', fontSize: '17px', lineHeight: '28px', fontFamily: 'Verdana' }}>
                                [Verse 1]<br />
                                <span style={{ backgroundColor: '#fff2cc' }}>I'm in my bed</span><br />
                                <span style={{ backgroundColor: '#fff2cc' }}>And you're not here</span><br />
                                <span style={{ backgroundColor: '#fff2cc' }}>And there's no one to blame but the drink and my wandering hands</span><br />
                                Forget what I said<br />
                                It's not what I meant<br />
                                And I can't take it back, I can't unpack the baggage you left<br />
                                <br />
                                [Chorus]<br />
                                What am I now? What am I now?<br />
                                <span style={{ backgroundColor: '#f0b400' }}>What if I'm someone I don't want around?</span><br />
                                I'm falling again, I'm falling again, I'm fallin'<br />
                                <span style={{ backgroundColor: '#fff2cc' }}>What if I'm down? What if I'm out?</span><br />
                                What if I'm someone you won't talk about?<br />
                                I'm falling again, I'm falling again, I'm fallin'<br />
                                <br />
                                [Verse 2]<br />
                                You said you care, and you missed me too<br />
                                And I'm well aware I write too many songs about you<br />
                                And the coffee's out at the Beachwood Cafe<br />
                                And it kills me 'cause I know we've ran out of things we can say<br />
                                <br />
                                [Chorus]<br />
                                What am I now? What am I now?<br />
                                What if I'm someone I don't want around?<br />
                                <span style={{ backgroundColor: '#fff2cc' }}>I'm falling again, I'm falling again, I'm fallin'</span><br />
                                <span style={{ backgroundColor: '#fff2cc' }}>What if I'm down? What if I'm out?</span><br />
                                What if I'm someone you won't talk about?<br />
                                I'm falling again, I'm falling again, I'm fallin'<br />
                                <br />
                                [Bridge]<br />
                                And I get the feeling that you'll never need me again<br />
                                <br />
                                [Chorus]<br />
                                What am I now? What am I now?<br />
                                What if you're someone I just want around?<br />
                                I'm falling again, I'm falling again, I'm fallin'<br />
                                What if I'm down? What if I'm out?<br />
                                What if I'm someone you won't talk about?<br />
                                I'm falling again, I'm falling again, I'm fallin'
                            </p>
                        </div>
                        <div style={{ float: 'right', width: '40%' }}>
                            <br /><br />
                            <b><small><a href="profil.html"><font color="#f0b400">Andrzej</font></a></small></b>
                            <p style={{ color: 'black', fontSize: '17px', lineHeight: '28px', fontFamily: 'Verdana' }}>
                                Beachwood Cafe is American cafe, located in Los Angeles. During the writing process for Fine Line, Harry spent most of his time in Los Angeles, and was even spotted at the cafe on October 20, 2018.
                                Harry’s ex, French-American model Camille Rowe, was also a fan of the venue, revealing in a September 2018 interview that a visit to Beachwood Cafe would be included in her “ideal day in L.A.”:
                                <img style={{ float: 'right', marginTop: '5px' }} src="img/interpretacja.PNG" /> <small><b><font color="#00cc00">+20</font></b></small>
                            </p>
                            <br /><br />
                            <b><small><font color="#f0b400">Anonimowy</font></small></b>
                            <p style={{ color: 'black', fontSize: '17px', lineHeight: '28px', fontFamily: 'Verdana' }}>
                                Several other connections to Camille have been made on Fine Line, with her voice even featured on the album’s prior track “Cherry”, in a voicemail recorded in French.
                                <img style={{ float: 'right', marginTop: '5px' }} src="img/interpretacja.PNG" />
                            </p>
                        </div> */}

                            {addingAnnotation && (
                                <>
                                    <small>
                                        {beautifyText(song.song_name.toUpperCase())} NEW ANNOTATION
                                    </small>
                                    <br />
                                    <br />

                                    <div className="clearfix"> </div>

                                    <br />

                                    <div style={{ float: "left", width: "60%" }}>
                                        <AnnotationEditor
                                            linesSelected={linesSelected}
                                            onChangeSelection={onChangeAnnotationSelection}
                                            lyrics={song_lyrics}
                                        />
                                    </div>

                                    <div style={{ float: "right", width: "40%" }}>
                                        <h4 style={{ margin: "0px" }}>
                                            Dodaj zabawną interpretację
                                        </h4>
                                        <p style={{ color: "#e96b56" }}>
                                            {" "}
                                            Wybierz wersy, które chcesz zinterpretować (wybrano:{" "}
                                            {linesSelected.length}){" "}
                                        </p>

                                        <p style={{ margin: "0px", width: "100%" }}>
                                            Interpretacja:
                                        </p>
                                        <textarea
                                            value={annotationText}
                                            onChange={(e) => {
                                                setAnnotationText(e.target.value);
                                            }}
                                            style={{
                                                resize: "none",
                                                height: "200px",
                                                width: "100%",
                                            }}
                                        ></textarea>

                                        {/* <p style={{ margin: '0px', width: '100%' }}>Nickname:</p> */}

                                        {/* <input
                                    value={annotationAuthor}
                                    onChange={(e) => { setAnnotationAuthor(e.target.value); }}
                                    style={{ width: '100%' }}
                                    type="text" /> */}

                                        {userData && (
                                            <p>
                                                Dodasz tę interpretację jako:{" "}
                                                <b>{userData.username} </b>
                                            </p>
                                        )}
                                        {userData === null && (
                                            <p>
                                                Nie jesteś zalogowany, dodasz tę interpretację jako:{" "}
                                                <b>Anonimowy</b>
                                            </p>
                                        )}

                                        {userData === null && (
                                            <center>
                                                {" "}
                                                <ReCAPTCHA
                                                    style={{ margin: "5px" }}
                                                    sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                                                    onChange={(value) => {
                                                        onCaptchaChange("annotation", value);
                                                    }}
                                                />{" "}
                                            </center>
                                        )}

                                        <a
                                            onClick={(e) => {
                                                addAnnotation();
                                            }}
                                            style={{ float: "right" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-edit"></i>&nbsp; DODAJ
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                setAddingAnnotation(false);
                                                clearCaptchaVal("annotation");
                                            }}
                                            style={{ float: "right", marginRight: "5px" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-ban-circle"></i>&nbsp; ANULUJ
                                        </a>

                                        {linesSelected.length > 0 && (
                                            <a
                                                onClick={(e) => {
                                                    setLinesSelected([]);
                                                }}
                                                style={{ float: "right", marginRight: "5px" }}
                                                className="btn btn-red"
                                            >
                                                <i className="icon-ban-circle"></i>&nbsp; ODZNACZ
                                                WSZYSTKIE LINIE
                                            </a>
                                        )}
                                    </div>
                                </>
                            )}

                            {!addingAnnotation && (
                                <>
                                    <small>
                                        {beautifyText(beautifyText(song.song_name.toUpperCase()))}{" "}
                                        TEKST
                                    </small>
                                    <br />

                                    <a
                                        onClick={(e) => {
                                            addNewAnnotation();
                                        }}
                                        style={{
                                            float: "right",
                                            backgroundColor: "#f0b400",
                                            border: "1px solid #f0b400",
                                            borderRadius: "3px",
                                        }}
                                        className="btn btn-red"
                                    >
                                        <i className="icon-edit"></i>&nbsp; DODAJ INTERPRETACJĘ
                                    </a>
                                    <div className="clearfix"> </div>
                                    <div ref={handleRect} style={leftSideStyle}>
                                        <br />

                                        {/* <p style={{color: 'black', fontSize: "17px", lineHeight: "28px", fontFamily:"Verdana"}} dangerouslySetInnerHTML={{__html: song_lyrics}}></p> */}

                                        <div style={{ color: "black" }}>
                                            {lines.map((line, idx) => {
                                                let newElem;
                                                let text = line.trim() !== "" ? line : "&nbsp;";

                                                let shouldNot = false;

                                                if (text.search(/guitar/gi) !== -1)
                                                    shouldNot = true;
                                                if (text.search(/\[verse/gi) !== -1)
                                                    shouldNot = true;
                                                if (text.search(/\[pre/gi) !== -1) shouldNot = true;
                                                if (text.search(/\[chorus/gi) !== -1)
                                                    shouldNot = true;
                                                if (text.search(/\[bridge/gi) !== -1)
                                                    shouldNot = true;
                                                if (text.search(/\[outro/gi) !== -1)
                                                    shouldNot = true;

                                                if (line.trim() === "" || shouldNot) {
                                                    newElem = (
                                                        <p
                                                            style={{ height: "25px" }}
                                                            key={idx}
                                                            className="emptyParagraph"
                                                        >
                                                            {beautifyText(text)}
                                                        </p>
                                                    );

                                                    return newElem;
                                                }

                                                let classes = "lineParagraph2";

                                                let gotAnnotation = false;
                                                for (let annotation of song.annotation) {
                                                    if (
                                                        annotation.annotation_lines.indexOf(idx) !==
                                                        -1
                                                    ) {
                                                        gotAnnotation = true;
                                                    }
                                                }

                                                if (gotAnnotation) {
                                                    newElem = (
                                                        <p
                                                            key={idx}
                                                            className="lineParagraph2"
                                                            style={{ width: "90%" }}
                                                        >
                                                            <span
                                                                key={idx}
                                                                className={
                                                                    (idx == annotationLineSelected
                                                                        ? "gotAnnotationSelected"
                                                                        : "gotAnnotation") +
                                                                    " clickable"
                                                                }
                                                                onClick={(e) => {
                                                                    selectAnnotatedLine(idx);
                                                                }}
                                                            >
                                                                {beautifyText(text)}
                                                            </span>{" "}
                                                        </p>
                                                    );
                                                } else {
                                                    newElem = (
                                                        <p
                                                            key={idx}
                                                            className="lineParagraph2"
                                                            style={{ width: "90%" }}

                                                            // onClick={ (e) => {selectLine(idx);} }
                                                        >
                                                            {beautifyText(text)}
                                                        </p>
                                                    );
                                                }

                                                return newElem;
                                            })}
                                        </div>
                                    </div>

                                    <StickyContainer style={stickyStyle}>
                                        <Sticky>
                                            {({ style }) => (
                                                <div style={style}>
                                                    {" "}
                                                    {song.annotation.map((annotation) => {
                                                        if (
                                                            annotationsShowed.indexOf(
                                                                annotation.annotation_id,
                                                            ) === -1
                                                        ) {
                                                        } else {
                                                            let thumbsUp = false;

                                                            if (currentUserData !== null) {
                                                                if (
                                                                    userVotes.indexOf(
                                                                        annotation.annotation_id,
                                                                    ) !== -1
                                                                ) {
                                                                    thumbsUp = true;
                                                                }
                                                            } else if (
                                                                window.localStorage[
                                                                    "voted_up" +
                                                                        annotation.annotation_id
                                                                ] !== undefined
                                                            ) {
                                                                thumbsUp = true;
                                                            }

                                                            return (
                                                                <div key={annotation.annotation_id}>
                                                                    <br />
                                                                    <br />
                                                                    <b>
                                                                        <small>
                                                                            <font color="#f0b400">
                                                                                {annotation.annotation_author ===
                                                                                    "Anonimowy" && (
                                                                                    <>
                                                                                        {" "}
                                                                                        {beautifyText(
                                                                                            annotation.annotation_author,
                                                                                        )}{" "}
                                                                                    </>
                                                                                )}

                                                                                {annotation.annotation_author !==
                                                                                    "Anonimowy" && (
                                                                                    <>
                                                                                        <FakeA
                                                                                            color="#f0b400"
                                                                                            href={
                                                                                                "/profil/" +
                                                                                                annotation.annotation_author
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "#f0b400",
                                                                                                }}
                                                                                                className="clickable"
                                                                                            >
                                                                                                {beautifyText(
                                                                                                    annotation.annotation_author,
                                                                                                )}{" "}
                                                                                            </span>
                                                                                        </FakeA>
                                                                                    </>
                                                                                )}
                                                                            </font>
                                                                        </small>
                                                                    </b>
                                                                    <div
                                                                        style={{
                                                                            color: "black",
                                                                            fontSize: "17px",
                                                                            lineHeight: "28px",
                                                                            fontFamily: "Verdana",
                                                                        }}
                                                                    >
                                                                        {/* <img alt="" style={{ float: 'left', marginRight: "10px" }} src="img/contributor.jpg" />  */}
                                                                        {beautifyText(
                                                                            annotation.annotation_text,
                                                                        )}

                                                                        <img
                                                                            className="clickable"
                                                                            onClick={(e) => {
                                                                                voteUp(annotation);
                                                                            }}
                                                                            alt=""
                                                                            style={{
                                                                                float: "right",
                                                                                marginTop: "5px",
                                                                            }}
                                                                            src={
                                                                                thumbsUp
                                                                                    ? "img/voteUP.PNG"
                                                                                    : "img/interpretacja.PNG"
                                                                            }
                                                                        />
                                                                        <small>
                                                                            <b>
                                                                                <font color="#00b300">
                                                                                    {" "}
                                                                                    {annotation.annotation_points >
                                                                                    0
                                                                                        ? "+" +
                                                                                          annotation.annotation_points
                                                                                        : ""}
                                                                                </font>
                                                                            </b>
                                                                        </small>
                                                                    </div>

                                                                    {localStorage.userdata !==
                                                                        undefined &&
                                                                        JSON.parse(
                                                                            localStorage.userdata,
                                                                        ).username === "admin" && (
                                                                            <>
                                                                                <button
                                                                                    onClick={(
                                                                                        e,
                                                                                    ) => {
                                                                                        deleteAnnotation(
                                                                                            annotation.annotation_id,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    USUŃ
                                                                                    INTERPRETACJĘ{" "}
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                    <div style={{ margin: "10px" }}>
                                                        <br />
                                                        <Ad adtype="4"></Ad>{" "}
                                                    </div>
                                                </div>
                                            )}
                                        </Sticky>
                                    </StickyContainer>

                                    <p style={{ clear: "both" }} />
                                </>
                            )}
                        </div>
                        <div id="tłumaczenie" className="tabcontent">
                            {/* <small>FALLING TŁUMACZENIE</small>
                        <br />
                        <a href="OKIENKO DO DODAWANIA TEKSTU" style={{ float: 'right', backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-file-text-alt" />&nbsp; DODAJ TŁUMACZENIE</a>
                        <br /><br />
                        <div className="tabs">
                            <ul className="nav nav-tabs">
                                <li className="active"><a href="#one" data-toggle="tab"><img src="img/poland.png" /> Polski</a></li>
                                <li><a href="#two" data-toggle="tab"><img src="img/france.png" /> French</a></li>
                                <li><a href="#three" data-toggle="tab"><img src="img/korea.png" /> Korean</a></li>
                            </ul>
                            <div style={{ background: '#fff9e6' }} className="tab-content">
                                <div className="tab-pane active" id="one">
                                    <p style={{ color: 'black', fontSize: '17px', lineHeight: '28px', fontFamily: 'Verdana' }}>
                                        [Wiersz 1] <br />
                                        Jestem w swoim łóżku <br />
                                        A ciebie tu nie ma
                                        I nie ma nikogo, kogo można winić, tylko napój i moje wędrujące ręce <br />
                                        Zapomnij, co powiedziałem
                                        Nie o to mi chodziło
                                        I nie mogę go odebrać, nie mogę rozpakować bagażu, który zostawiłeś
                                        <br />
                                        [Refren] <br />
                                        Czym jestem teraz Kim jestem teraz? <br />
                                        I to mnie zabija, bo wiem, że zabrakło nam rzeczy, które możemy powiedzieć
                                        <br />
                                        [Refren] <br />
                                        Czym jestem teraz Kim jestem teraz? <br />
                                        Co jeśli jestem kimś, kogo nie chcę?
                                        Upadam znowu, znowu spadam, upadam <br />
                                        Co jeśli jestem na dole? Co jeśli mnie nie będzie? <br />
                                        Co jeśli jestem kimś, o czym nie chcesz rozmawiać? <br />
                                        Upadam znowu, znowu spadam, upadam <br />
                                        <br />
                                        [Bridge] <br />
                                        I mam wrażenie, że już nigdy mnie nie będziesz potrzebować
                                        <br />
                                        [Refren] <br />
                                        Czym jestem teraz Kim jestem teraz? <br />
                                        Co jeśli jesteś kimś, kogo po prostu chcę?
                                        Upadam znowu, znowu spadam, upadam <br />
                                        Co jeśli jestem na dole? Co jeśli mnie nie będzie? <br />
                                        Co jeśli jestem kimś, o czym nie chcesz rozmawiać? <br />
                                        Upadam znowu, znowu spadam, upadam
                                    </p>
                                </div>
                                <div className="tab-pane" id="two">
                                    <p>
                                        Tale dolor mea ex, te enim assum suscipit cum, vix aliquid omittantur in. Duo eu cibo dolorum menandri, nam sumo dicit admodum ei. Ne mazim commune honestatis cum, mentitum phaedrum sit et.
                                    </p>
                                </div>
                                <div className="tab-pane" id="three">
                                    <p>
                                        Cu cum commodo regione definiebas. Cum ea eros laboramus, audire deseruisse his at, munere aeterno ut quo. Et ius doming causae philosophia, vitae bonorum intellegat usu cu.
                                    </p>
                                </div>
                            </div>
                        </div> */}

                            {addingTranslation && (
                                <>
                                    <small>
                                        {beautifyText(song.song_name.toUpperCase())} TŁUMACZENIE
                                    </small>
                                    <br />
                                    <br />

                                    <div className="clearfix"> </div>

                                    <div>
                                        <h4 style={{ margin: "0px" }}>Dodaj nowe tłumaczenie</h4>

                                        {/* <p style={{margin:'0px', width:'100%'}}>language:</p> */}
                                        {/* <input 
            value={annotationAuthor}
            onChange={(e)=>{ setAnnotationAuthor(e.target.value);}}
            
            type="text" /> */}

                                        <div style={{ marginTop: "10px" }}>
                                            <LanguageComboBox
                                                value={translationLanguage}
                                                onChange={(event, value) => {
                                                    setTranslationLanguage(value);
                                                }}
                                            />
                                        </div>

                                        <p style={{ margin: "0px", width: "100%" }}>Tekst:</p>
                                        <textarea
                                            value={translationText}
                                            onChange={(e) => {
                                                setTranslationText(e.target.value);
                                            }}
                                            style={{
                                                resize: "none",
                                                height: "350px",
                                                width: "100%",
                                            }}
                                        ></textarea>

                                        {userData === null && (
                                            <center>
                                                {" "}
                                                <ReCAPTCHA
                                                    style={{ margin: "5px" }}
                                                    sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                                                    onChange={(value) => {
                                                        onCaptchaChange("translation", value);
                                                    }}
                                                />{" "}
                                            </center>
                                        )}

                                        <a
                                            onClick={(e) => {
                                                addTranslation();
                                            }}
                                            style={{ float: "right" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-edit"></i>&nbsp; DODAJ TŁUMACZENIE
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                setAddingTranslation(false);
                                                clearCaptchaVal("translation");
                                            }}
                                            style={{ float: "right", marginRight: "5px" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-ban-circle"></i>&nbsp; ANULUJ
                                        </a>

                                        {/* {linesSelected.length > 0 && <a onClick={(e)=>{  setLinesSelected([]); }} style={{float: "right", marginRight: '5px'}} className="btn btn-red"><i className="icon-ban-circle"></i>&nbsp; DESELECT ALL LINES</a>} */}
                                    </div>
                                </>
                            )}

                            {addingTranslation == false && (
                                <>
                                    <small>
                                        {beautifyText(song.song_name.toUpperCase())} TŁUMACZENIE
                                    </small>

                                    {/* { languagesList.map((lang) => {
    return (<>
        <img src={'/api/getLang/' + lang.code} />
    </>)
})} */}
                                    <br />

                                    <a
                                        onClick={(e) => {
                                            addNewTranslation();
                                        }}
                                        style={{
                                            float: "right",
                                            backgroundColor: "#f0b400",
                                            border: "1px solid #f0b400",
                                            borderRadius: "3px",
                                        }}
                                        className="btn btn-red"
                                    >
                                        <i className="icon-file-text-alt"></i>&nbsp; DODAJ
                                        TŁUMACZENIE
                                    </a>

                                    <br />
                                    <br />

                                    <div className="tabs translationTabs" role="tablist">
                                        <ul className="nav nav-tabs">
                                            {uniqueLanguages.map((lang, idx) => {
                                                let className =
                                                    lang == selectedLanguage ? "active" : "";

                                                return (
                                                    <li key={lang} className={className}>
                                                        <a
                                                            href={"#" + lang}
                                                            id={"lang_" + lang}
                                                            data-toggle="tab"
                                                        >
                                                            <img
                                                                style={{ width: "16px" }}
                                                                alt=""
                                                                src={"/api/getLang/" + lang}
                                                            />{" "}
                                                            {languages[lang].name} [
                                                            {songTranslationsByCode[lang] &&
                                                                songTranslationsByCode[lang].length}
                                                            ]
                                                        </a>
                                                    </li>
                                                );
                                            })}

                                            {/* <li className="active"><a href="#one" data-toggle="tab"><img alt="" src="img/poland.png" /> Polski</a></li>
        <li><a href="#two" data-toggle="tab"><img alt="" src="img/france.png" /> French</a></li>
        <li><a href="#three" data-toggle="tab"><img alt="" src="img/korea.png" /> Korean</a></li> */}
                                        </ul>
                                        <div
                                            style={{ background: "#e6e6e6" }}
                                            className="tab-content"
                                        >
                                            {uniqueLanguages.length == 0 && (
                                                <div>BRAK DODANYCH TŁUMACZEŃ</div>
                                            )}

                                            {uniqueLanguages.map((lang, idx) => {
                                                let className =
                                                    lang == selectedLanguage ? "active" : "";

                                                let songs = songTranslationsByCode[lang];

                                                if (!songs) return <></>;

                                                return (
                                                    <div
                                                        key={idx}
                                                        className={"tab-pane " + className}
                                                        id={lang}
                                                    >
                                                        <div>
                                                            {songs.length > 1 && (
                                                                <>
                                                                    Wybierz tłumaczenie:{" "}
                                                                    <select
                                                                        style={{ width: "300px" }}
                                                                        value={
                                                                            selectedTranslation[
                                                                                lang
                                                                            ]
                                                                        }
                                                                        onChange={(e) => {
                                                                            //console.log(e.target.value);

                                                                            selectedTranslation[
                                                                                lang
                                                                            ] = e.target.value;
                                                                            setSelectedTranslation({
                                                                                ...selectedTranslation,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {songTranslationsByCode[
                                                                            lang
                                                                        ].map(
                                                                            (translation, idx) => {
                                                                                let d = new Date(
                                                                                    translation.translation_timestamp *
                                                                                        1000,
                                                                                );

                                                                                return (
                                                                                    <option
                                                                                        key={idx}
                                                                                        value={idx}
                                                                                    >
                                                                                        {"Translation #" +
                                                                                            (idx +
                                                                                                1) +
                                                                                            " from " +
                                                                                            d.toLocaleTimeString() +
                                                                                            " " +
                                                                                            d.toLocaleDateString()}
                                                                                    </option>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </select>{" "}
                                                                    <hr />
                                                                </>
                                                            )}

                                                            {songTranslationsByCode[lang][
                                                                selectedTranslation[lang]
                                                            ] && (
                                                                <>
                                                                    {userData &&
                                                                        (userData.username ===
                                                                            "reinhold" ||
                                                                            userData.username ===
                                                                                "admin") && (
                                                                            <button
                                                                                onClick={async () => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            "Na pewno usunąć tłumaczenie?",
                                                                                        )
                                                                                    ) {
                                                                                        let translation =
                                                                                            songTranslationsByCode[
                                                                                                lang
                                                                                            ][
                                                                                                selectedTranslation[
                                                                                                    lang
                                                                                                ]
                                                                                            ];

                                                                                        console.log(
                                                                                            translation,
                                                                                        );

                                                                                        let res =
                                                                                            await alewersyAPI.deleteTranslation(
                                                                                                translation.translation_id,
                                                                                            );
                                                                                        if (
                                                                                            res.status ===
                                                                                            "error"
                                                                                        ) {
                                                                                            alert(
                                                                                                res.error,
                                                                                            );
                                                                                        } else {
                                                                                            // window.location.href = "/";
                                                                                            window.location.reload();
                                                                                        }

                                                                                        // console.log(
                                                                                        //     ,
                                                                                        // );
                                                                                    }
                                                                                }}
                                                                                className="btn btn-danger"
                                                                            >
                                                                                USUŃ TŁUMACZENIE
                                                                            </button>
                                                                        )}
                                                                    <p
                                                                        style={{
                                                                            whiteSpace: "pre",
                                                                        }}
                                                                    >
                                                                        {beautifyText(
                                                                            songTranslationsByCode[
                                                                                lang
                                                                            ][
                                                                                selectedTranslation[
                                                                                    lang
                                                                                ]
                                                                            ].translation_text,
                                                                        )}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div id="komentarze" className="tabcontent">
                            {/* <small>FALLING KOMENTARZE</small>
                        <br />
                        <a href="OKIENKO DO DODAWANIA TEKSTU" style={{ float: 'right', backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-comment" />&nbsp; DODAJ KOMENTARZ</a>
                        <br /><br /> */}

                            {addingComment == true && (
                                <>
                                    <small>
                                        {beautifyText(song.song_name.toUpperCase())} Nowy komentarz
                                    </small>
                                    <br />
                                    <a
                                        onClick={(e) => {
                                            addNewComment();
                                        }}
                                        style={{
                                            float: "right",
                                            backgroundColor: "#f0b400",
                                            border: "1px solid #f0b400",
                                            borderRadius: "3px",
                                        }}
                                        className="btn btn-red"
                                    >
                                        <i className="icon-comment"></i>&nbsp; DODAJ KOMENTARZ
                                    </a>
                                    <br />
                                    <br />

                                    <div>
                                        <h4 style={{ margin: "0px" }}>Dodaj komentarz</h4>

                                        {/* <a onClick={(e)=>{setAdding(false); setCaptcha(null); }} style={{float: "left", marginRight: '5px'}} className="btn btn-red"><i className="icon-ban-circle"></i>&nbsp; ANULUJ</a>
                                                    {captchaVisible && <a onClick={(e)=>{addNewElement(); }} style={{float: "left"}} className="btn btn-red"><i className="icon-edit"></i>&nbsp; ADD {props.type.toUpperCase()}</a>} */}

                                        <p
                                            style={{
                                                margin: "0px",
                                                marginTop: "10px",
                                                width: "100%",
                                            }}
                                        >
                                            Komentarz:
                                        </p>
                                        <textarea
                                            value={commentText}
                                            onChange={(e) => {
                                                setCommentText(e.target.value);
                                            }}
                                            style={{
                                                resize: "none",
                                                height: "200px",
                                                width: "100%",
                                            }}
                                        ></textarea>

                                        {/* 
                                <p style={{ margin: '0px', width: '100%' }}>Nickname:</p>
                                <input style={{ width: '300px' }} type='text' value={commentAuthor} onChange={(e) => { setCommentAuthor(e.target.value) }} /> */}

                                        {/* <p style={{ margin: '0px', width: '100%' }}>Nickname:</p> */}

                                        {/* <input
                                    value={annotationAuthor}
                                    onChange={(e) => { setAnnotationAuthor(e.target.value); }}
                                    style={{ width: '100%' }}
                                    type="text" /> */}

                                        {userData && (
                                            <p>
                                                Dodasz ten komentarz jako:{" "}
                                                <b>{userData.username} </b>
                                            </p>
                                        )}
                                        {userData === null && (
                                            <p>
                                                Nie jesteś zalogowany, dodasz ten komentarz jako:{" "}
                                                <b>Anonimowy</b>
                                            </p>
                                        )}

                                        {userData === null && (
                                            <ReCAPTCHA
                                                style={{ margin: "5px" }}
                                                sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                                                onChange={(value) => {
                                                    onCaptchaChange("comment", value);
                                                }}
                                            />
                                        )}

                                        <a
                                            onClick={(e) => {
                                                setAddingComment(false);
                                                clearCaptchaVal("comment", null);
                                            }}
                                            style={{ float: "left", marginRight: "5px" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-ban-circle"></i>&nbsp; ANULUJ
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                addCommentDODAJ();
                                            }}
                                            style={{ float: "left" }}
                                            className="btn btn-red"
                                        >
                                            <i className="icon-edit"></i>&nbsp; DODAJ{" "}
                                        </a>
                                    </div>
                                </>
                            )}

                            {addingComment == false && (
                                <>
                                    <small>
                                        {beautifyText(song.song_name.toUpperCase())} KOMENTARZE
                                    </small>
                                    <br />
                                    <a
                                        onClick={(e) => {
                                            addNewComment();
                                        }}
                                        style={{
                                            float: "right",
                                            backgroundColor: "#f0b400",
                                            border: "1px solid #f0b400",
                                            borderRadius: "3px",
                                        }}
                                        className="btn btn-red"
                                    >
                                        <i className="icon-comment"></i>&nbsp; DODAJ KOMENTARZ
                                    </a>
                                    <br />
                                    <br />

                                    {comments.length == 0 && (
                                        <div style={{ background: "#e6e6e6", padding: "15px" }}>
                                            BRAK KOMENTARZY
                                        </div>
                                    )}

                                    {comments.map((comment) => {
                                        let thumbsUp = false;

                                        if (currentUserData !== null) {
                                            if (
                                                userCommentVotes.indexOf(comment.comment_id) !== -1
                                            ) {
                                                thumbsUp = true;
                                            }
                                        } else if (
                                            window.localStorage[
                                                "comment_voted_up" + comment.comment_id
                                            ] !== undefined
                                        ) {
                                            thumbsUp = true;
                                        }

                                        return (
                                            <>
                                                {userData &&
                                                    (userData.username === "reinhold" ||
                                                        userData.username === "admin") && (
                                                        <button
                                                            onClick={async () => {
                                                                if (
                                                                    window.confirm(
                                                                        "Na pewno usunąć komentarz?",
                                                                    )
                                                                ) {
                                                                    let res =
                                                                        await alewersyAPI.deleteComment(
                                                                            comment.comment_id,
                                                                        );
                                                                    if (res.status === "error") {
                                                                        alert(res.error);
                                                                    } else {
                                                                        window.location.reload();
                                                                    }
                                                                }
                                                            }}
                                                            className="btn btn-danger"
                                                        >
                                                            USUŃ KOMENTARZ
                                                        </button>
                                                    )}

                                                {comment.comment_author === "Anonimowy" && (
                                                    <>
                                                        {" "}
                                                        <small>
                                                            <b>
                                                                <font color="#f0b400">
                                                                    {beautifyText(
                                                                        comment.comment_author,
                                                                    )}
                                                                </font>
                                                            </b>
                                                        </small>{" "}
                                                    </>
                                                )}
                                                {comment.comment_author !== "Anonimowy" && (
                                                    <>
                                                        <FakeA
                                                            color="#f0b400"
                                                            href={
                                                                "/profil/" + comment.comment_author
                                                            }
                                                        >
                                                            {" "}
                                                            <small>
                                                                <b>
                                                                    <font color="#f0b400">
                                                                        {beautifyText(
                                                                            comment.comment_author,
                                                                        )}
                                                                    </font>
                                                                </b>
                                                            </small>
                                                        </FakeA>
                                                    </>
                                                )}

                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontSize: "17px",
                                                        lineHeight: "28px",
                                                        fontFamily: "Verdana",
                                                    }}
                                                >
                                                    <span>
                                                        {beautifyText(comment.comment_text)}
                                                    </span>{" "}
                                                    <small>
                                                        <b>
                                                            <font color="#00b300">
                                                                {comment.comment_points > 0 && (
                                                                    <>+{comment.comment_points}</>
                                                                )}
                                                            </font>
                                                        </b>
                                                    </small>
                                                    <br />
                                                    <img
                                                        onClick={(e) => {
                                                            voteComment(comment);
                                                        }}
                                                        className="clickable"
                                                        alt=""
                                                        style={{ float: "right" }}
                                                        src={
                                                            thumbsUp
                                                                ? "img/voteUP.PNG"
                                                                : "img/interpretacja.PNG"
                                                        }
                                                    />
                                                </p>
                                                <br />
                                            </>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                        <div id="remixy" className="tabcontent">
                            {/* <small>FALLING REMIXY</small>
                        <br />
                        <a href="OKIENKO DO DODAWANIA TEKSTU" style={{ float: 'right', backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-play-circle" />&nbsp; DODAJ REMIX</a>
                        <br /><br />

                        <div className="span3">
                            <center>
                                <img src="img/obrazek.jpg" />
                                <br />
                                <p style={{ color: 'black', fontSize: '13px', fontFamily: 'Arial' }}>{beautifyText(song.artist.artist_name)} {beautifyText(song.song_name)} Remix DJ Bobo 2019 hot</p>
                            </center>
                        </div> */}

                            <VideoTab
                                onVideoSelect={onVideoSelect}
                                onNewElement={onNewElement}
                                refreshStamp={elementsRefreshTimestamp}
                                elements={elementsByType[0]}
                                song={song}
                                type="remix"
                            />
                        </div>
                        <div id="covery" className="tabcontent">
                            <VideoTab
                                onVideoSelect={onVideoSelect}
                                refreshStamp={elementsRefreshTimestamp}
                                onNewElement={onNewElement}
                                elements={elementsByType[1]}
                                song={song}
                                type="cover"
                            />
                        </div>
                        <div id="instrumentale" className="tabcontent">
                            {/* <small>FALLING INSTRUMENTALE</small>
                        <br />
                        <a href="OKIENKO DO DODAWANIA TEKSTU" style={{ float: 'right', backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-microphone-off" />&nbsp; DODAJ INSTRUMENTAL</a>
                        <br /><br />
                        <div className="span3">
                            <center>
                                <img src="img/obrazek.jpg" />
                                <br />
                                <p style={{ color: 'black', fontSize: '13px', fontFamily: 'Arial' }}>{beautifyText(song.artist.artist_name)} {beautifyText(song.song_name)} Remix DJ Bobo 2019 hot</p>
                            </center>
                        </div>
                        <div className="span3">
                            <center>
                                <img src="img/obrazek.jpg" />
                                <br />
                                <p style={{ color: 'black', fontSize: '13px', fontFamily: 'Arial' }}>{beautifyText(song.artist.artist_name)} {beautifyText(song.song_name)} Remix DJ Bobo 2019 hot</p>
                            </center>
                        </div>
                        <div className="span3">
                            <center>
                                <img src="img/obrazek.jpg" />
                                <br />
                                <p style={{ color: 'black', fontSize: '13px', fontFamily: 'Arial' }}>{beautifyText(song.artist.artist_name)} {beautifyText(song.song_name)} Remix DJ Bobo 2019 hot</p>
                            </center>
                        </div> */}
                            <VideoTab
                                onVideoSelect={onVideoSelect}
                                refreshStamp={elementsRefreshTimestamp}
                                onNewElement={onNewElement}
                                elements={elementsByType[2]}
                                song={song}
                                type="instrumental"
                            />
                        </div>
                    </div>
                    <center>
                        <h5>
                            <strong>
                                Zobacz pozostałe piosenki artysty{" "}
                                <Link to={"/" + song.artist.artist_urlname}>
                                    <font color="#f0b400">
                                        {beautifyText(song.artist.artist_name)}
                                    </font>
                                </Link>
                                , napisz interpretację wersów{" "}
                                <a
                                    className="clickable"
                                    onClick={(e) => {
                                        addNewAnnotation(true);
                                    }}
                                >
                                    <font color="#1ac6ff">powyższego utworu</font>
                                </a>{" "}
                                lub dodaj{" "}
                                <Link to="/dodaj">
                                    <font color="#00cc00">nową piosenkę</font>
                                </Link>
                                .{" "}
                            </strong>
                        </h5>
                    </center>
                    <br />
                </div>

                <Ad adtype="2" />
                <br />
                <section id="content">
                    <div className="container">
                        <div className="row">
                            <div className="span6">
                                <center>
                                    {youtube.thumbnails.medium.url !== "" && (
                                        <img
                                            alt=""
                                            src={youtube.thumbnails.medium.url.replace(
                                                /https:\/\//g,
                                                "/api/ytproxy/",
                                            )}
                                            alt={
                                                beautifyText(song.artist.artist_name) +
                                                " " +
                                                beautifyText(song.song_name) +
                                                " tekst"
                                            }
                                        />
                                    )}

                                    {youtube.thumbnails.medium.url === "" && (
                                        <img
                                            alt=""
                                            src="/img/placeholder.jpg"
                                            alt={
                                                beautifyText(song.artist.artist_name) +
                                                " " +
                                                beautifyText(song.song_name) +
                                                " tekst"
                                            }
                                        />
                                    )}
                                </center>
                            </div>
                            <div className="span6">
                                <h4 className="title">
                                    {beautifyText(song.artist.artist_name)}{" "}
                                    {beautifyText(song.song_name)} <strong>Tekst</strong>
                                </h4>
                                <p>
                                    <i className="icon-music" /> Tekst piosenki{" "}
                                    {beautifyText(song.artist.artist_name)}{" "}
                                    {beautifyText(song.song_name)} ze śmieszną interpretacją wersów,
                                    tłumaczeniem, komentarzami, remixami, opcją zapętlania i linkami
                                    do sklepów. Podaj humorystyczne i nieprawdziwe znaczenie
                                    wybranych linijek używając sarkazmu, skojarzeń, ironii i bujnej
                                    wyobraźni.
                                </p>
                                <div className="blankline" />
                                <div className="a2a_kit a2a_kit_size_28 a2a_default_style">
                                    <a className="a2a_button_copy_link"> </a>
                                    <a className="a2a_button_pinterest"> </a>
                                    <a className="a2a_button_reddit"> </a>
                                    <a className="a2a_button_wykop"> </a>
                                </div>
                            </div>
                        </div>
                        <div className="row team">
                            <div className="span12">
                                <h4>
                                    <strong>
                                        <span className="colored">Najnowsze Interpretacje</span>
                                    </strong>
                                </h4>
                            </div>

                            {recentSorted.map((elem, idx) => {
                                if (recentAnnotations.songs[elem.annotation_song_id] === undefined)
                                    return <></>;

                                let shortName =
                                    recentAnnotations.songs[elem.annotation_song_id].song_name;
                                if (shortName.length > 16)
                                    shortName = shortName.substr(0, 15) + "...";

                                return (
                                    <React.Fragment>
                                        <div className="span2" key={elem.annotation_id}>
                                            <div
                                                style={{ backgroundColor: "#fff2cc" }}
                                                className="team-box"
                                            >
                                                <FakeA
                                                    style={{ backgroundColor: "#f0b400" }}
                                                    href={
                                                        "/" +
                                                        recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_urlname
                                                            .replace(new RegExp("\\/", "g"), "%2F")
                                                            .replace(new RegExp("\\?", "g"), "%3F")
                                                            .replace(new RegExp("\\#", "g"), "%23")
                                                    }
                                                    className="thumbnail"
                                                >
                                                    <img
                                                        src={recentAnnotations.songs[
                                                            elem.annotation_song_id
                                                        ].song_youtube.thumbnails.medium.url.replace(
                                                            /https:\/\//g,
                                                            "/api/ytproxy/",
                                                        )}
                                                        alt={
                                                            recentAnnotations.songs[
                                                                elem.annotation_song_id
                                                            ].song_search + " tekst"
                                                        }
                                                    />
                                                </FakeA>

                                                <div className="roles aligncenter">
                                                    <FakeA
                                                        href={
                                                            "/" +
                                                            recentAnnotations.songs[
                                                                elem.annotation_song_id
                                                            ].song_urlname
                                                                .replace(
                                                                    new RegExp("\\/", "g"),
                                                                    "%2F",
                                                                )
                                                                .replace(
                                                                    new RegExp("\\?", "g"),
                                                                    "%3F",
                                                                )
                                                                .replace(
                                                                    new RegExp("\\#", "g"),
                                                                    "%23",
                                                                )
                                                        }
                                                    >
                                                        <p>
                                                            <strong>
                                                                {beautifyText(shortName)}
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            {beautifyText(
                                                                recentAnnotations.songs[
                                                                    elem.annotation_song_id
                                                                ].song_search
                                                                    .replace(
                                                                        recentAnnotations.songs[
                                                                            elem.annotation_song_id
                                                                        ].song_name,
                                                                        "",
                                                                    )
                                                                    .trim(),
                                                            )}
                                                        </p>
                                                    </FakeA>
                                                </div>
                                            </div>
                                        </div>

                                        {idx === 5 ? (
                                            <p style={{ clear: "both" }}>&nbsp;</p>
                                        ) : (
                                            <></>
                                        )}
                                    </React.Fragment>

                                    // <div className="span2" key={elem.annotation_id}>
                                    //     <center>
                                    //         <FakeA href={'/' + recentAnnotations.songs[elem.annotation_song_id].song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}>

                                    //             {/* <img src={ recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/') } alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " Lyrics"} /> */}
                                    //             {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id === undefined && <img src="/img/placeholder2.png" alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " Lyrics"} />}
                                    //             {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id !== undefined && <img src={recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={beautifyText(recentAnnotations.songs[elem.annotation_song_id].song_search) + " Lyrics"} />}

                                    //             <br />
                                    //             <p style={{ color: "black", fontSize: "13px", marginBottom: '0px', fontFamily: "Arial" }}> {beautifyText(recentAnnotations.songs[elem.annotation_song_id].song_search.replace(recentAnnotations.songs[elem.annotation_song_id].song_name, '').trim())} </p>
                                    //             <p style={{ color: "black", fontSize: "13px", fontFamily: "Arial" }}> {beautifyText(shortName)} </p>
                                    //         </FakeA>
                                    //     </center>
                                    // </div>
                                );
                            })}
                        </div>

                        <Ad adtype="3" />
                        <br />
                    </div>
                </section>
            </section>
        </>
    );
}
