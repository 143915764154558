
import React, {useState } from 'react';
import '../css/AnnotationEditor.css';
import {beautifyText} from './Misc';

function AnnotationEditor(props) {
    //let [selectedLines, setSelectedLines] =  useState([]);
    let lines = props.lyrics.split(/<br\/>/g);

    function selectLine(idx) {
        console.log("selecting: " + idx);

        let selectedLines = props.linesSelected;
        let foundedIdx = selectedLines.indexOf(idx);

        if (foundedIdx === -1) {
            selectedLines.push(idx);
        } else {
            selectedLines.splice(foundedIdx, 1);
        }

        // setSelectedLines([...selectedLines]);


        if (props.onChangeSelection) {
            props.onChangeSelection([...selectedLines]);
        }
    }

    return (
        <div style={{color: 'black'}}>
            {lines.map( (line, idx)=> {

            let newElem;
            let text = line.trim() !==''?line: '&nbsp;';

            let shouldNot = false;

            if (text.search(/guitar/gi) !== -1) shouldNot= true;
            if (text.search(/\[intro/gi) !== -1) shouldNot= true;
            if (text.search(/\[hook/gi) !== -1) shouldNot= true;
            if (text.search(/\[verse/gi) !== -1) shouldNot= true;
            if (text.search(/\[pre/gi) !== -1) shouldNot= true;
            if (text.search(/\[chorus/gi) !== -1) shouldNot= true;
            if (text.search(/\[bridge/gi) !== -1) shouldNot= true;
            if (text.search(/\[outro/gi) !== -1) shouldNot= true;

            if (line.trim() === '' || shouldNot) {

                newElem = <p 
                    key={idx}
					style={{height: '25px'}}
                    className="emptyParagraph"
                >{beautifyText(text)}</p>;
            
                return newElem;

            }

            let classes = "lineParagraph";

              if (props.linesSelected.indexOf(idx) !== -1) {
                  // already selected
                  classes = "selected";
              } 

                newElem = <p 
                    key={idx}
                    className={classes}
                    
                    onClick={ (e) => {selectLine(idx);} }
                    >{beautifyText(text)}</p>;
              


              return newElem;
            } ) }
        </div>
    )
}

export default AnnotationEditor;
