import { Helmet } from "react-helmet"
import AlewersyAPI from '../services/AlewersyAPI';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import {useNavigate } from 'react-router-dom';
import {checkText} from "./Misc";
import ReCAPTCHA from "react-google-recaptcha";

const alewersyAPI = AlewersyAPI.getInstance();

let emailRegexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default function Rejestracja() {
    let navigate = useNavigate();

    let [password, setPassword] = useState("");
    let [username, setUsername] = useState("");
    let [email, setEmail] = useState("");
    let [buttonDisabled, setButtonDisabled] = useState(true);
    let [error1, setError1] = useState(null);
    let [error2, setError2] = useState(null);
    let [captcha, setCaptcha] = useState(null);

    useEffect(() => {

        if (username.trim() !== ""
            && email.trim() !== ""
            && password.trim() !== ""
            && error1 === null && error2 === null) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

    }, [error1, error2, password, username, email]);

    useEffect(() => {
        setError1(null);

        if (username.trim() !== '') {
            (async () => {

                let result = await alewersyAPI.getUser(username);
                if (result.length > 0) {
                    setError1("Ta nazwa użytkownika jest już zajęta!");
                } else {
                    setError1(null);
                }

            })();
        }

    }, [username]);

    useEffect(() => {
        setError2(null);

        if (email.trim() === '') return;

        let matches = email.trim().match(emailRegexp);
        if (matches === null) {
            setError2("To nie jest prawidłowy adres e-mail!")
            return;
        }

        if (username.trim() !== '') {
            (async () => {

                let result = await alewersyAPI.getUser(email);
                if (result.length > 0) {
                    setError2("Ten adres e-mail jest już wykorzystywany!");
                } else {
                    setError2(null);
                }

            })();
        }

    }, [email]);

    const onUsernameChange = debounce(async (e) => {
        setUsername(e.target.value.trim());
    }, 500);

    const onEmailChange = debounce(async (e) => {
        setEmail(e.target.value.trim());
    }, 500);

    async function zarejestruj() {

        if (!captcha) {
            alert('Rozwiąż captchę!');
            return;
        }

        if (username.trim() !== ""
            && email.trim() !== ""
            && password.trim() !== ""
            && error1 === null && error2 === null) {
            
        } else {
            alert("uzupełnij wymagane pola!");
            return;
        }

        if (checkText(username) === false ) {
            alert("nazwa użytkownika nie może zawierać wulgaryzmów, ani adresów WWW!");
            return;
        }
        
        console.log("registering account...");

        let result = await alewersyAPI.registerUser({username: username.trim(), 
            password: password.trim(), 
            email: email.trim()});
        

        if (result.status === "success") {
            localStorage.setItem('userdata', JSON.stringify(result.user) );
            window.dispatchEvent( new Event('storage') );

            navigate('/profil/' + username);
        } else {
            alert('błąd!');
        }
    }

    return <>
        <Helmet>
            <title>Rejestracja | tekst-piosenki.pl</title>
            <meta name="description" content="Załóż darmowe konto w serwisie i ciesz się pełną funkcjonalnością witryny. Zbieraj plusy za najzabawniejsze interpretacje i wspinaj się w rankingu." />
        </Helmet>


        <section style={{ backgroundColor: '#fff2cc' }} id="inner-headline">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="inner-heading">
                            <ul className="breadcrumb">
                                <li><a href="/">Strona Główna</a> <i className="icon-angle-right" /></li>
                                &nbsp;Rejestracja
                            </ul>
                            <h2>Załóż Konto</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span8">
                        <center>
                            <p style={{ margin: '5px' }}>Nazwa użytkownika:</p>
                            <input
                                onChange={ (e)=> { setButtonDisabled(true); onUsernameChange(e); }}
                                type="text" style={{ width: '50%' }} />

                            {error1 !== null ? <p style={{ fontWeight: 'bold', color: '#880000' }}>{error1}</p> : <></>}
                            <p style={{ margin: '5px' }}>Adres email:</p>
                            <input
                                onChange={ (e)=> { setButtonDisabled(true); onEmailChange(e); }}
                                type="text" style={{ width: '50%' }} />
                            {error2 !== null ? <p style={{ fontWeight: 'bold', color: '#880000' }}>{error2}</p> : <></>}


                            <p style={{ margin: '5px' }}>Hasło:</p>
                            <input type="password" value={password}
                                onChange={(e) => { setPassword(e.target.value) }} style={{ width: '50%' }} />

 <ReCAPTCHA
                                    style={{ margin: '5px' }}
                                    sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                                    onChange={(value) => { setCaptcha(value); }}
                                /> 

                        </center>
                        <center>
                            <br />
                            <button
                                onClick={zarejestruj}
                                disabled={buttonDisabled}
                                style={{ backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-user" />&nbsp; ZAREJESTRUJ</button>
                        </center>
                    </div>
                    <div className="span4">
                        <center>
                            <br />
                            <img src="/img/rejestracja.png" />
                        </center>
                    </div>
                </div>
            </div>
        </section>


    </>

}