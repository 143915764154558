import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AlewersyAPI from '../services/AlewersyAPI';
import Result from './Result'; 
import Error404 from './Error404';
import Artist from './Artist';
import Loader from "./Loader";


const alewersyAPI = AlewersyAPI.getInstance(); 

function Identify() {
    let params = useParams();
    let [pageData, setPageData] = useState({});

let navigate = useNavigate();

    useEffect( ()=> {
        setPageData({});
        console.log('identifying...');
        downloadData();

        window.scrollTo(0,0);
        
	}, [params.text]);
    

    async function downloadData() {
        let result = await alewersyAPI.identify(params.text);
        // console.log('Identify founded: ');
        //console.log(result);
        setPageData(result);
    }
 
    if (pageData && pageData.type === 'song') return <Result song={pageData} />;
    if (pageData && pageData.type === 'artist') return <Artist artist={pageData} />;
    if (pageData && pageData.type === 'unknown') {
	navigate('/404');
	return <></>;
}

    return <>
    
    
        <section id="content">
        <div className="container">
            <div className="row">

            <div className="span9">


                <h4 style={{display: "inline-block"}}>Loading...  </h4><Loader/>
                
            </div>
                </div>
        </div>
        </section>
                
                </>;

}

export default Identify;
