import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

export default function Logout() {
    let navigate = useNavigate();
    useEffect(()=> {
        localStorage.removeItem("userdata");
        navigate("/");
    }, []);

    return <>

    </>;
}