import { useState } from "react";

function LoopControl(props) {

    let [from, setFrom] = useState("0:00");
    let [to, setTo] = useState("1:00");
    let [error, setError] = useState(null);
    let [state, setState] = useState("stopped");

    function timeToSec(t) {
        let retval;
        var ss = t.split(':');

        if (ss.length == 1) {
            retval = parseInt(ss[0]);
        } else {
            retval = (parseInt(ss[0])*60)+parseInt(ss[1]);
        }

        return retval;
    }    

    function loop() {
        if (state === "stopped") {
            //console.log("looping");
            //console.log(`from ${from} to ${to}`);

            let secFrom = timeToSec(from);
            let secTo = timeToSec(to);

            if ((isNaN(secFrom) || isNaN(secTo)) || secTo <= secFrom)  {
                //console.log("wrong time");
                setError("please set correct time range for loop!");
            } else {
                //console.log(`from ${secFrom} to ${secTo}`);
                setError(null);
                setState("looping");

                if (props.onChange) {
                    props.onChange("loop", secFrom, secTo);
                }
            }
        } else if (state === "looping") {

            if (props.onChange) {
                props.onChange("stop");
            }
            setState("stopped");
        }
    }

    return (
        <>
            {/* <img alt="" style={{float: "right"}} src="img/loop.PNG" />  */}
            <div style={{position: 'relative', textAlign: 'center', paddingTop: '15px', borderRadius: '10px',
                color: '#ffa500', float: "right", paddingLeft: '5px',paddingRight: '5px', height: "45px", backgroundColor: 'black'}}>

                { error && <div style={{color: 'white', backgroundColor: '#aa0000', borderRadius: '5px', padding: '3px', fontWeight: 'bold', position: 'absolute', right: '5px', top: '-32px', fontSize: '0.8em'}}>{error}</div> }

            <span style={{marginRight: '2px'}} >Od</span>
            <input 
                disabled={ state==='looping'}
                style={{border: '1px solid #555555', borderRadius: '2px', width: '35px', marginBottom: '0px', backgroundColor: 'black', color: '#508be2' }} 
                onChange={ (e) => { setFrom(e.target.value);} }
                value={from} type="text" />
            <span style={{marginRight: '2px', marginLeft: '2px'}}>Do</span> 
            <input 
                onKeyDown={(e)=> {if (e.keyCode===13) loop(); } }
                disabled={ state==='looping'}
                style={{border: '1px solid #555555', borderRadius: '2px', width: '35px', marginBottom: '0px', backgroundColor: 'black', color: '#508be2' }} 
                onChange={ (e) => { setTo(e.target.value);} }
                value={to} type="text" />


            {state === "stopped" && <button 
                onClick={loop}
                style={{fontWeight: 'bold', border: 'none', backgroundColor: '#3980bd', borderRadius: '5px', color: 'white', height: '30px', marginLeft: '10px'}}>Zapętlaj</button>}
            {state === "looping" && <button 
                            onClick={loop}
                            style={{fontWeight: 'bold', border: 'none', backgroundColor: '#550000', borderRadius: '5px', color: 'white', height: '30px', marginLeft: '10px'}}>Stop</button>}

            </div>

        </>
    );
}

export default LoopControl;