let wulgaryzmy = 
`bitch
bitches
suck
sucks
negro
anal
sex
sexual
fuck
gay
gays
porn
porno
fucked
fucking
tits
ass
asshole
slut
sluts
motherfucker
motherfuckers
fucker
fuckers
penetration
fisting
cumshot
cumshots
creampie
drugs
drug
cialis
squirt
squirting
hentai
gangbang
lesbian
lesbians
masturbation
milf
milfs
orgy
orgies
piss
pissed
pissing
pornstar
pornstars
ebony
cunt
cunts
nigga
dick
dicks
whore
whores
blowjob
blowjobs
bukkake
viagra
kamagra
handjob
footjob
rimming
dildo
dildos
penis
erectile
vagina
vaginas
penises
cock
cocks
prick
pricks
niggers
nigger
faggot
faggots
cum
cums
dickhead
dickheads
xrumer
pussy
pussies
erection
erections
xevil
orgasm
orgasms
xanax
valium
vicodin
shitty
zennoposter
shit
bitch
bitches
suck
sucks
negro
anal
sex
sexual
fuck
gay
gays
porn
porno
fucked
fucking
tits
ass
asshole
slut
sluts
motherfucker
motherfuckers
fucker
fuckers
penetration
fisting
cumshot
cumshots
creampie
drugs
drug
cialis
squirt
squirting
hentai
gangbang
lesbian
lesbians
masturbation
milf
milfs
orgy
orgies
piss
pissed
pissing
pornstar
pornstars
ebony
cunt
cunts
nigga
dick
dicks
whore
whores
blowjob
blowjobs
bukkake
viagra
kamagra
handjob
footjob
rimming
dildo
dildos
penis
erectile
vagina
vaginas
penises
cock
cocks
prick
pricks
niggers
nigger
faggot
faggots
cum
cums
dickhead
dickheads
xrumer
pussy
pussies
erection
erections
xevil
orgasm
orgasms
xanax
valium
vicodin
shitty
zennoposter
chuj
chuja
chujek
chuju
chujem
chujnia
chujowy
chujowa
chujowe
cipa
cipę
cipe
cipą
cipie
dojebać
dojebac
dojebie
dojebał
dojebal
dojebała
dojebala
dojebałem
dojebalem
dojebałam
dojebalam
dojebię
dojebie
dopieprzać
dopieprzac
dopierdalać
dopierdalac
dopierdala
dopierdalał
dopierdalal
dopierdalała
dopierdalala
dopierdoli
dopierdolił
dopierdolil
dopierdolę
dopierdole
dopierdoli
dopierdalający
dopierdalajacy
dopierdolić
dopierdolic
dupa
dupie
dupą
dupcia
dupeczka
dupy
dupe
huj
hujek
hujnia
huja
huje
hujem
huju
jebać
jebac
jebał
jebal
jebie
jebią
jebia
jebak
jebaka
jebal
jebał
jebany
jebane
jebanka
jebanko
jebankiem
jebanymi
jebana
jebanym
jebanej
jebaną
jebana
jebani
jebanych
jebanymi
jebcie
jebiący
jebiacy
jebiąca
jebiaca
jebiącego
jebiacego
jebiącej
jebiacej
jebia
jebią
jebie
jebię
jebliwy
jebnąć
jebnac
jebnąc
jebnać
jebnął
jebnal
jebną
jebna
jebnęła
jebnela
jebnie
jebnij
jebut
koorwa
kórwa
kurestwo
kurew
kurewski
kurewska
kurewskiej
kurewską
kurewska
kurewsko
kurewstwo
kurwa
kurwaa
kurwami
kurwą
kurwe
kurwę
kurwie
kurwiska
kurwo
kurwy
kurwach
kurwami
kurewski
kurwiarz
kurwiący
kurwica
kurwić
kurwic
kurwidołek
kurwik
kurwiki
kurwiszcze
kurwiszon
kurwiszona
kurwiszonem
kurwiszony
kutas
kutasa
kutasie
kutasem
kutasy
kutasów
kutasow
kutasach
kutasami
matkojebca
matkojebcy
matkojebcą
matkojebca
matkojebcami
matkojebcach
nabarłożyć
najebać
najebac
najebał
najebal
najebała
najebala
najebane
najebany
najebaną
najebana
najebie
najebią
najebia
naopierdalać
naopierdalac
naopierdalał
naopierdalal
naopierdalała
naopierdalala
naopierdalała
napierdalać
napierdalac
napierdalający
napierdalajacy
napierdolić
napierdolic
nawpierdalać
nawpierdalac
nawpierdalał
nawpierdalal
nawpierdalała
nawpierdalala
obsrywać
obsrywac
obsrywający
obsrywajacy
odpieprzać
odpieprzac
odpieprzy
odpieprzył
odpieprzyl
odpieprzyła
odpieprzyla
odpierdalać
odpierdalac
odpierdol
odpierdolił
odpierdolil
odpierdoliła
odpierdolila
odpierdoli
odpierdalający
odpierdalajacy
odpierdalająca
odpierdalajaca
odpierdolić
odpierdolic
odpierdoli
odpierdolił
opieprzający
opierdalać
opierdalac
opierdala
opierdalający
opierdalajacy
opierdol
opierdolić
opierdolic
opierdoli
opierdolą
opierdola
piczka
pieprznięty
pieprzniety
pieprzony
pierdel
pierdlu
pierdolą
pierdola
pierdolący
pierdolacy
pierdoląca
pierdolaca
pierdol
pierdole
pierdolenie
pierdoleniem
pierdoleniu
pierdolę
pierdolec
pierdola
pierdolą
pierdolić
pierdolicie
pierdolic
pierdolił
pierdolil
pierdoliła
pierdolila
pierdoli
pierdolnięty
pierdolniety
pierdolisz
pierdolnąć
pierdolnac
pierdolnął
pierdolnal
pierdolnęła
pierdolnela
pierdolnie
pierdolnięty
pierdolnij
pierdolnik
pierdolona
pierdolone
pierdolony
pierdołki
pierdzący
pierdzieć
pierdziec
pizda
pizdą
pizde
pizdę
piździe
pizdzie
pizdnąć
pizdnac
pizdu
podpierdalać
podpierdalac
podpierdala
podpierdalający
podpierdalajacy
podpierdolić
podpierdolic
podpierdoli
pojeb
pojeba
pojebami
pojebani
pojebanego
pojebanemu
pojebani
pojebany
pojebanych
pojebanym
pojebanymi
pojebem
pojebać
pojebac
pojebalo
popierdala
popierdalac
popierdalać
popierdolić
popierdolic
popierdoli
popierdolonego
popierdolonemu
popierdolonym
popierdolone
popierdoleni
popierdolony
porozpierdalać
porozpierdala
porozpierdalac
poruchac
poruchać
przejebać
przejebane
przejebac
przyjebali
przepierdalać
przepierdalac
przepierdala
przepierdalający
przepierdalajacy
przepierdalająca
przepierdalajaca
przepierdolić
przepierdolic
przyjebać
przyjebac
przyjebie
przyjebała
przyjebala
przyjebał
przyjebal
przypieprzać
przypieprzac
przypieprzający
przypieprzajacy
przypieprzająca
przypieprzajaca
przypierdalać
przypierdalac
przypierdala
przypierdoli
przypierdalający
przypierdalajacy
przypierdolić
przypierdolic
qrwa
rozjebać
rozjebac
rozjebie
rozjebała
rozjebią
rozpierdalać
rozpierdalac
rozpierdala
rozpierdolić
rozpierdolic
rozpierdole
rozpierdoli
rozpierducha
skurwić
skurwiel
skurwiela
skurwielem
skurwielu
skurwysyn
skurwysynów
skurwysynow
skurwysyna
skurwysynem
skurwysynu
skurwysyny
skurwysyński
skurwysynski
skurwysyństwo
skurwysynstwo
spieprzać
spieprzac
spieprza
spieprzaj
spieprzajcie
spieprzają
spieprzaja
spieprzający
spieprzajacy
spieprzająca
spieprzajaca
spierdalać
spierdalac
spierdala
spierdalał
spierdalała
spierdalal
spierdalalcie
spierdalala
spierdalający
spierdalajacy
spierdolić
spierdolic
spierdoli
spierdoliła
spierdoliło
spierdolą
spierdola
srać
srac
srający
srajacy
srając
srajac
sraj
sukinsyn
sukinsyny
sukinsynom
sukinsynowi
sukinsynów
sukinsynow
śmierdziel
udupić
ujebać
ujebac
ujebał
ujebal
ujebana
ujebany
ujebie
ujebała
ujebala
upierdalać
upierdalac
upierdala
upierdoli
upierdolić
upierdolic
upierdoli
upierdolą
upierdola
upierdoleni
wjebać
wjebac
wjebie
wjebią
wjebia
wjebiemy
wjebiecie
wkurwiać
wkurwiac
wkurwi
wkurwia
wkurwiał
wkurwial
wkurwiający
wkurwiajacy
wkurwiająca
wkurwiajaca
wkurwić
wkurwic
wkurwi
wkurwiacie
wkurwiają
wkurwiali
wkurwią
wkurwia
wkurwimy
wkurwicie
wkurwiacie
wkurwić
wkurwic
wkurwia
wpierdalać
wpierdalac
wpierdalający
wpierdalajacy
wpierdol
wpierdolić
wpierdolic
wpizdu
wyjebać
wyjebac
wyjebali
wyjebał
wyjebac
wyjebała
wyjebały
wyjebie
wyjebią
wyjebia
wyjebiesz
wyjebie
wyjebiecie
wyjebiemy
wypieprzać
wypieprzac
wypieprza
wypieprzał
wypieprzal
wypieprzała
wypieprzala
wypieprzy
wypieprzyła
wypieprzyla
wypieprzył
wypieprzyl
wypierdal
wypierdalać
wypierdalac
wypierdala
wypierdalaj
wypierdalał
wypierdalal
wypierdalała
wypierdalala
wypierdalać
wypierdolić
wypierdolic
wypierdoli
wypierdolimy
wypierdolicie
wypierdolą
wypierdola
wypierdolili
wypierdolił
wypierdolil
wypierdoliła
wypierdolila
zajebać
zajebac
zajebie
zajebią
zajebia
zajebiał
zajebial
zajebała
zajebiala
zajebali
zajebana
zajebani
zajebane
zajebany
zajebanych
zajebanym
zajebanymi
zajebiste
zajebisty
zajebistych
zajebista
zajebistym
zajebistymi
zajebiście
zajebiscie
zapieprzyć
zapieprzyc
zapieprzy
zapieprzył
zapieprzyl
zapieprzyła
zapieprzyla
zapieprzą
zapieprza
zapieprzy
zapieprzymy
zapieprzycie
zapieprzysz
zapierdala
zapierdalać
zapierdalac
zapierdalaja
zapierdalał
zapierdalaj
zapierdalajcie
zapierdalała
zapierdalala
zapierdalali
zapierdalający
zapierdalajacy
zapierdolić
zapierdolic
zapierdoli
zapierdolił
zapierdolil
zapierdoliła
zapierdolila
zapierdolą
zapierdola
zapierniczać
zapierniczający
zasrać
zasranym
zasrywać
zasrywający
zesrywać
zesrywający
zjebać
zjebac
zjebał
zjebal
zjebała
zjebala
zjebana
zjebią
zjebali
zjeby
shit
rozpierdolę
zapierdolona
ryj
morda
pysk
szmato
seks
seksem
seksu
seksie
seksowna
seksowną
seksownym
seksownymi
seksownych
seksownej
seksownemu
dupa
dupą
dupami
dupie
pornos
pornosy
pornosa
pornosem
pornosami
pornole
pornolem
pornolami
pornola
pornol
pornosów
pornoli
pornolom
pornosom
wkurwił
wkurwiła
wkurwiło
wkurwiłaś
wkurwiłeś
wkurwiliśmy
wkurwiliście
wkurwiłem
wkurwiłeś
wkurwiłaś
seksowny
cipkę
cipka
cipce
cipki
cipa
cipek
cipami
cipkami
jebanie
jebaniem
jebania
wyjebania
wyjebanie
wyjebany
wyjebanym
wyjebanymi
wyjebanych
wyjebanego
wyjebanej
wyjebaną
wyjebanemu
murzyn
murzyna
murzynem
murzyni
murzynowi
murzynach
murzynami
murzynów
murzynom
murzynek
murzynka
murzynce
murzynkom
murzynkami
murzynki
murzynek
rozjebany
rozjebana
rozjebaną
rozjebanej
rozjebanym
rozjebanymi
rozjebanych
rozjebanego
rozjebanemu
rozjebanie
dojebane
dojebany
dojebana
dojebanemu
dojebanego
dojebanym
dojebanymi
dojebanych
dojebanej
dojebaną
seksualną
seksualna
seksualne
seksualny
seksualnym
seksualnymi
seksualnych
seksualnemu
seksualnego
seksualnej
czarnuch
czarnucha
czarnuchowi
czarnuchów
czarnuchem
czarnuchami
czarnuchy
czarnuchom
mużyn
mużyna
mużynem
mużyni
mużynowi
mużynach
mużynami
mużynów
mużynom
mużynek
mużynka
mużynce
mużynkom
mużynkami
mużynki
mużynek
seksualnie
pedofil
pedofilem
pedofilami
pedofilia
pedofilom
pedofili
pedofilów
pedofilię
pedofilii
pedofiliom
seksowi
seksualności
seksualność
seksualnością
seksualnościami
seksuolog
seksuologa
seksuologom
seksuologiem
seksuologia
seksuologią
aseksualne
aseksualnie
aseksualny
aseksualna
aseksualność
aseksualnością
aseksualności
aseksualnych
aseksualnego
aseksualnej
aseksualnym
aseksualnymi
aseksualnych
aseksualnemu
penisa
penisem
penisami
penisów
penisom
penisach
penisowi
penisy
rozkurwić`;

wulgaryzmy = wulgaryzmy.split(/\n/g);
let wwwregexp = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;

export const beautifyText = function (txt) {
    // console.log("start");
    //console.log(wulgaryzmy);


    if (txt === undefined) return '';
    
    let parsed = ` ${txt} `;

    for (let vul of wulgaryzmy) {

        if (txt.search(new RegExp(vul, "i")) !== -1) {
            let b = vul.charAt(0);

            for (let i=1; i<vul.length-1; i++) {
                b+= '*';
            }

            b+= vul.charAt(vul.length-1);

            // parsed = parsed.replace(new RegExp(`${vul}`, 'gi'), `${b}` );
		parsed = parsed.replace(new RegExp(`\\b${vul}\\b`, 'gi'), b );   

        }

        
    }

    parsed = parsed.replace(wwwregexp, '');
     parsed = parsed.replace(new RegExp("&nbsp;", "g"), "");

     //make only first letter of every word uppercase
    // parsed = parsed.replace(/(\b\w)/g, function (x) {
    //     return x.toUpperCase();
    // }
    
    // console.log('end');

    return parsed.trim();
}


export const checkText = function (txt) {
    let check = beautifyText(txt);
    if (check == txt) return true; 

    return false;
}

export const formatDate = function (date) {

    let d = new Date(date);

    return `${d.getDate()}.${ ('0'+d.getMonth()).substr(-2)}.${d.getFullYear()}`;
}

// export default beautifyText;


