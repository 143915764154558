import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import FakeA from "./fakeA";
import { Helmet } from 'react-helmet';
import { beautifyText } from "./Misc";
import Paginator from './Paginator';
import Ad from './Ad';
import AlewersyAPI from "../services/AlewersyAPI";

const alewersyAPI = AlewersyAPI.getInstance();

function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}

function Search(props) {
    let navigate = useNavigate();
    let params = useParams();
    let [searchResults, setSearchResults] = useState(null);
    let [recentAnnotations, setRecentAnnotations] = useState([]);
    let [recentSorted, setRecentSorted] = useState([]);

    let [currentPage, setCurrentPage] = useState(1); // useState( (params.page!== undefined && !isNaN(parseInt(params.page)) )? parseInt(params.page):1);
    let [pageCount, setPageCount] = useState(0);
    let perPage = 50;

    async function downloadRecentAnnotations() {
        let annotations = await alewersyAPI.getRecentAnnotations();
        console.log(annotations);
        setRecentAnnotations(annotations);
    }

    useEffect(() => {
        downloadRecentAnnotations();
    }, []);

    useEffect(() => {
        //console.log("got recent annotations!" + Object.keys(recentAnnotations));
        if (recentAnnotations.ever === undefined) return;

        let recentSorted = JSON.parse(JSON.stringify(recentAnnotations.last));
        recentSorted.sort((a, b) => {
            if (a.annotation_timestamp > b.annotation_timestamp) return -1;
            if (b.annotation_timestamp > a.annotation_timestamp) return 1;
            return 0;
        });

        // lastWeekS = lastWeekS.slice(0, 8);

        let myRecentSorted = getUniqueAnnotations(recentSorted);
        myRecentSorted = myRecentSorted.slice(0, 12);

        setRecentSorted(myRecentSorted);
    }, [recentAnnotations]);


    function onPageSelect(page) {
        console.log("selecting page " + page);
        setCurrentPage(page);

        if (page == 1) navigate('/szukaj/' + encodeURIComponent(params.term));
        else navigate('/szukaj/' + encodeURIComponent(params.term) + '/' + page);
    }

    useEffect(() => {
        //setPageData({});
        console.log('searching for ...');
        console.log(params.term);

        searchTerm();


    }, [params.term]);

    async function searchTerm() {
        setSearchResults(null);
        let result = await alewersyAPI.search(params.term);
        setSearchResults(result);



        setPageCount(Math.ceil(result.result.length / perPage));

        if (params.page_num) setCurrentPage(params.page_num);
        else setCurrentPage(1);
    }

    let searchDiv;
    if (!searchResults) searchDiv = <><center><h4 style={{ display: "inline-block" }}>Szukanie...  </h4><Loader /></center></>;
    else {

        searchResults.result.forEach(song => {
            //    console.log( song.youtube === undefined);
            let elems = song.song_lyrics.split(/\s+/g);
            song.song_lyrics_short = elems.slice(0, 25).join(' ') + '...';
        });

        searchDiv = <>


            <img align="left" style={{ marginRight: "10px" }} src="/img/szukaj.png" />
            <h4 className="title">Szukana fraza: <strong>{params.term}</strong> (wyników: {searchResults.result.length})</h4>

            {searchResults.result.length > perPage
                && <Paginator prelink={"/search/" + encodeURIComponent(params.term) + "/"} pageCount={pageCount} currentPage={currentPage} onPageSelect={onPageSelect} />}


            <Ad adtype="1"></Ad>


            <br />


            {searchResults.result.slice((currentPage - 1) * perPage, (currentPage - 1) * perPage + perPage).map(song => <>
                <div style={{ backgroundColor: "#fff2cc", padding: "20px" }} className="team-box">
                    <Link to={'/' + song.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}>

                        {song.youtube &&
                            <img align="left" style={{ width: "170px", marginRight: "10px" }} src={song.youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} />}

                        {!song.youtube && <img align="left" style={{ width: "170px", marginRight: "10px" }} src="/img/placeholder.jpg" />}

                    </Link>
                    <div className="roles aligncenter">

                        <Link to={'/' + song.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}><h6><b>{beautifyText(song.song_search)}</b></h6>
                            {song.youtube && <p>
                                <span dangerouslySetInnerHTML={{ __html: beautifyText(song.youtube.title) }} />
                            </p>}


                            <small>
                                {song.song_lyrics_short}
                            </small>
                        </Link>
                    </div>
                </div>

                <br /></>)}

            <Ad adtype="2"></Ad>
        </>;
    }

    return (<>

        <Helmet>

            <title>{params.term} wyniki wyszukiwania | tekst-piosenki.pl</title>
            <meta name="description" content={'Wyniki wyszukiwania dla frazy ' + params.term + '. Zobacz teksty piosenek i ich śmieszne interpretacje, tłumaczenia, komentarze, remixy, instrumentale.'} />


        </Helmet>

        <section id="content">
            <div className="container">
                <div className="row">

                    <div className="span9">

                        {searchDiv}



                    </div>

                    <div className="span3">

                        <aside className="right-sidebar">



                            <div className="widget">

                                <h6 className="widgetheading">Nowe Interpretacje</h6>
                                <br />

                                {recentSorted.map((elem) => {
                                    return (
                                        <center>
                                            <FakeA href={'/' + recentAnnotations.songs[elem.annotation_song_id].song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}>

                                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails === undefined && <img style={{ width: '155px' }} src='/img/placeholder.png' alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}
                                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails && <img style={{ width: '155px' }} src={recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}

                                                <br />
                                                <p style={{ color: "black", fontSize: "14px", fontFamily: "Arial" }}>{recentAnnotations.songs[elem.annotation_song_id].song_search}</p>
                                            </FakeA>
                                        </center>);
                                })}



                            </div>








                        </aside>
                    </div>

                </div>
            </div>
        </section>
    </>);
}

export default Search;
