import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import AlewersyAPI from '../services/AlewersyAPI';
import FakeA from './fakeA';
import { Helmet } from 'react-helmet';
import Paginator from './Paginator';
import { beautifyText } from "./Misc";
import Ad from './Ad';
import NoweInterpretacje from "./NoweInterpretacje";

const alewersyAPI = AlewersyAPI.getInstance();

function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}

function Artist(props) {
    let [userData, setUserdata] = useState(
        localStorage.userdata !== undefined ? JSON.parse(localStorage.userdata) : null,
    );
    let navigate = useNavigate();
    let params = useParams();
    let [recentSongs, setRecentSongs] = useState([]);

    let [recentAnnotations, setRecentAnnotations] = useState([]);
    let [recentSorted, setRecentSorted] = useState([]);

    let [currentPage, setCurrentPage] = useState(1); // useState( (params.page!== undefined && !isNaN(parseInt(params.page)) )? parseInt(params.page):1);
    let [pageCount, setPageCount] = useState(0);
    let perPage = 50;

    async function downloadRecentAnnotations() {
        let annotations = await alewersyAPI.getRecentAnnotations();
        console.log(annotations);
        setRecentAnnotations(annotations);
    }

    async function downloadRecentSongs() {
        let songs = await alewersyAPI.getRecentSongs();
        setRecentSongs(songs.songs);
    }

    useEffect(() => {
        downloadRecentSongs();
    }, []);


    function onPageSelect(page) {
        console.log("selecting page " + page);

        setCurrentPage(page);
        // downloadArtistInfo(page);

        if (page == 1) navigate('/' + props.artist.artist_urlname);
        else navigate('/' + props.artist.artist_urlname + '/' + page);
    }

    useEffect(() => {
        downloadRecentAnnotations();
    }, []);

    useEffect(() => {
        //console.log("got recent annotations!" + Object.keys(recentAnnotations));
        if (recentAnnotations.ever === undefined) return;

        let recentSorted = JSON.parse(JSON.stringify(recentAnnotations.last));
        recentSorted.sort((a, b) => {
            if (a.annotation_timestamp > b.annotation_timestamp) return -1;
            if (b.annotation_timestamp > a.annotation_timestamp) return 1;
            return 0;
        });

        // lastWeekS = lastWeekS.slice(0, 8);

        let myRecentSorted = getUniqueAnnotations(recentSorted);
        myRecentSorted = myRecentSorted.slice(0, 12);

        setRecentSorted(myRecentSorted);
    }, [recentAnnotations])


    useEffect(() => {
        //console.log(props.artist.artist_urlname);
        setPageCount(Math.ceil(props.artist.songs.length / perPage));

        console.log('params: ');
        console.log(params);
        if (params.second_arg) {
            setCurrentPage(parseInt(params.second_arg));
        }
    }, [props.artist]);

    return (<>
        <Helmet>

            <title>{beautifyText(props.artist.artist_name)} Teksty Piosenek i Zabawne Interpretacje</title>
            <meta name="description" content={beautifyText(props.artist.artist_name) + ' teksty piosenek i ich zabawne interpretacje. Lista wszystkich utworów artysty, tłumaczenia, komentarze, remixy, covery.'} />


        </Helmet>

        <section id="content">
            <div className="container">

            {userData && (userData.username==="reinhold" || userData.username==="admin") && (
                <button
                    onClick={async () => {
                        if (window.confirm("Na pewno usunąć artystę?")) {
                            let res = await alewersyAPI.deleteArtist(props.artist.artist_id);
                            if (res.status === "error") {
                                alert(res.error);
                            } else {
                                window.location.href = "/";                                
                            }

                        }
                    }}
                    className="btn btn-danger" 

                >USUŃ ARTYSTĘ</button>
            )}

                <div className="row">
                    <div className="span9">
                        <br />
                        <center><h3 className="title"><strong>{beautifyText(props.artist.artist_name)}</strong></h3>
                            <h5>Wszystkie <u><font color="#f0b400">piosenki</font></u>, <u><font color="#1ac6ff">albumy</font></u> i <u><font color="#00cc00">klipy</font></u> artysty posortowane alfabetycznie:</h5>
                        </center>
                        <br />

                        <div className="solidline" />
                        <br />
                        <div className="clearfix"> </div>

                        <Ad adtype="1"/>

                        <center>


                            {props.artist.songs.map((elem) => <Link to={'/'+elem.song_urlname}
                                key={elem.song_id} ><h6><b>{elem.song_name}</b></h6></Link>)}

                        </center>
                        <br />

                        <Ad adtype="2"/>


                        <div className="row team">
                            <div className="span12">
                                <h4><strong><span className="colored">Nowe Piosenki</span></strong></h4>
                            </div>

                            {recentSongs.slice(0, 8).map((elem, idx) => {

                                let shortName = elem.song_name;
                                if (shortName.length > 14) shortName = shortName.substr(0, 11) + '...';

                                let shortArtist = elem.song_search.replace(elem.song_name, '').trim();
                                if (shortArtist.length > 14) shortArtist = shortArtist.substr(0, 11) + '...';

                                let s = {};
                                if (idx <= 4) s = { marginBottom: '30px' };

                                return (
                                    <div className="span2" style={s} key={elem.song_id}>
                                        <div style={{ backgroundColor: "#fff2cc" }} className="team-box">
                                            <FakeA style={{ backgroundColor: '#f0b400' }} href={'/' + elem.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')} className="thumbnail">

                                                {(elem.song_youtube.id !== undefined && elem.song_youtube.id !== "") && <img style={{ maxHeight: '62px' }} src={elem.song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={beautifyText(elem.song_search) + " tekst"} />}
                                                {(elem.song_youtube.id === undefined || elem.song_youtube.id === "") && <img style={{ maxHeight: '62px' }} src="/img/placeholder.jpg" alt={beautifyText(elem.song_search) + " tekst"} />}


                                            </FakeA>
                                            <div className="roles aligncenter">
                                                <FakeA href={'/' + elem.song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}><p><strong>{beautifyText(shortName)}</strong></p>
                                                    <p>
                                                        {beautifyText(shortArtist)}
                                                    </p>
                                                </FakeA>

                                            </div>
                                        </div>
                                    </div>);
                            })}

                        </div>


                    </div>
                    <div className="span3">
                        <aside className="right-sidebar">
                            <div className="widget">
                                <i>Artysta</i>
                                <center>
                                    <img src="img/artysta.png" />
                                    <h6><b><font color="#f0b400">{beautifyText(props.artist.artist_name)}</font></b></h6>
                                </center>
                                <br />
                                <NoweInterpretacje />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>

    </>);
}

export default Artist;
