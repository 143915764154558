import './Loader.css';

function Loader(props) {
    return (<>

        {props.small === "true" &&  <div style={{width: '120px',height: '67px'}} className="lds-dual-ring"></div>  }
        {props.small === undefined && <div className="lds-dual-ring"></div> }

    </>);
}

export default Loader;